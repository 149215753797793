import { NetworkSignalData } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal.types.ts";
import { useNetworkSignalsByCompanyQuery } from "@/app/service/network-signal.gql.ts";

export const useNetworkSignals = (companyId?: string) => {
  const { data, loading } = useNetworkSignalsByCompanyQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const signals: NetworkSignalData[] =
    data?.networkSignals?.nodes.map((signal) => ({
      id: signal.id,
      sourceName: signal.sourceEnriched?.name ?? signal.sourceName,
      sourceType: signal.sourceType,
      note: signal.note,
      // this should match data from db since we store it as jsonb
      attributes: {
        canConnect: signal.attributes.can_intro,
        isActivelyFundraising: signal.attributes.is_actively_fundraising,
        isHighPriority: signal.attributes.high_priority,
        isDeepTech: signal.attributes.is_deep_tech,
        alreadyMet: signal.attributes.already_met,
      },
      createdBy: signal.lgMemberByCreatedBy!,
      createdUtc: signal.createdUtc,
      lastUpdatedUtc: signal.lastUpdatedUtc,
    })) ?? [];

  return {
    signals,
    loading,
  };
};
