import React from "react";

import { cn } from "@/lib/utils";

import { AttachmentsCommentsData } from "@/app/screens/opportunities/opportunity/components/highlights/attachments-comments/use-attachments-comments.ts";
import {
  HighlightsCard,
  HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { Summary } from "@/app/screens/opportunities/opportunity/components/summary.tsx";

const DateBadge = ({ date }: { date?: string }) => {
  return date ? (
    <HighlightsCardLastUpdated date={date} />
  ) : (
    // need this one to align labels
    <div className="h-7"></div>
  );
};

export const AttachmentsCommentsCard = ({ data: attachmentsComments }: { data: AttachmentsCommentsData | null }) => {
  return (
    <HighlightsCard className={cn(!!attachmentsComments && "flex")}>
      {attachmentsComments && (
        <>
          <Summary summaryType={"comments"} />

          <HighlightsCardBody className="w-1/2">
            <HighlightsCardTitle text={String(attachmentsComments.title[0])} />
            <a href={"#attachments"}>
              <HighlightsCardNumber value={attachmentsComments.attachmentsNo} className={"cursor-pointer"} />
            </a>
            <DateBadge date={attachmentsComments.lastAttachmentsDate} />
          </HighlightsCardBody>
          <HighlightsCardBody className="w-1/2">
            <HighlightsCardTitle text={String(attachmentsComments.title[1])} />
            <a href={"#comments"}>
              <HighlightsCardNumber value={attachmentsComments.commentNo} className={"cursor-pointer"} />
            </a>
            <DateBadge date={attachmentsComments.lastCommentDate} />
          </HighlightsCardBody>
        </>
      )}
      {!attachmentsComments && <HighlightsCardEmptyState text={"No Comments and Attachments"} />}
    </HighlightsCard>
  );
};
