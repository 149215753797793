import { Key } from "react";

import { AreaChart, BarList } from "@tremor/react";

import { useBreakpoints } from "@/lib/hooks";

import { normaliseDate } from "@/app/misc/helpers.ts";

export interface BarListProps {
  name: string;
  value: string;
}

export interface BarListHeroProps {
  data: BarListProps[];
}

export function AreaChartUsageExampleWithCustomTooltip({ evolutionOfExcitement }) {
  const { isSmallLaptop } = useBreakpoints();
  const customTooltip = (props) => {
    const { payload, active } = props;
    if (!active || !payload) return null;
    return (
      <div className="w-56 bg-gradient-to-r p-2">
        {payload.map(
          (
            category: {
              value: number;
              payload: { date: string | Date | undefined };
            },
            idx: Key | null | undefined,
          ) => (
            <div key={idx} className="flex flex-1 space-x-2.5">
              <div className={`flex w-1 flex-col rounded bg-gradient-to-t from-primary-200 to-transparent`} />
              <div className="space-y-1">
                <p className="text-lg font-bold text-primary">{String(Number(category.value.toFixed(1)))}</p>
                <p className="text-sm font-medium text-gray-700">{normaliseDate(category.payload.date)!}</p>
              </div>
            </div>
          ),
        )}
      </div>
    );
  };
  return (
    <>
      <AreaChart
        index="date"
        className="h-24 w-full pt-1"
        maxValue={10}
        curveType={"natural"}
        showAnimation={true}
        showGridLines={!isSmallLaptop}
        showXAxis={!isSmallLaptop}
        showYAxis={!isSmallLaptop}
        showLegend={false}
        categories={["Excitement"]}
        data={evolutionOfExcitement.reverse()}
        colors={["primary"]}
        customTooltip={customTooltip}
      />
    </>
  );
}

export const BarListHero = ({ data }) => (
  <div className="w-full gap-5 rounded-md bg-gray-200 pr-4">
    <BarList data={data} showAnimation={true} />
  </div>
);
