import React from "react";

import {
  HighlightsCard,
  HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";

export const CapitalConcentration = ({ company }) => {
  const opsCompany = company?.companiesSources?.items?.find(
    (item) => item.sourceType === "OpsCompany",
  )?.opsCompanyAsSource;
  const capitalConcentration =
    opsCompany?.initialInvestmentRatio >= 1 ? Math.round(opsCompany?.initialInvestmentRatio).toString() + ":1" : "";

  return (
    <HighlightsCard className={"group"}>
      {capitalConcentration ? (
        <HighlightsCardBody>
          <HighlightsCardTitle text={"Capital Concentration"} />
          <div className={"flex h-28 items-center justify-center"}>
            <HighlightsCardNumber value={capitalConcentration} animate={false} />
          </div>
          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={new Date().toISOString()} />
          </HighlightsCardFooter>
        </HighlightsCardBody>
      ) : (
        <HighlightsCardEmptyState text={"No Concentration Available"} />
      )}
    </HighlightsCard>
  );
};
