import { CalendarProps } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { FlowRouteType } from "@/app/screens/modal/global-native-modal.tsx";

export interface NavigateToModalParams {
  fieldId: string;
  configId: FlowRouteType;
  flowId: string;
  label?: string;
  value?: unknown;
  rangeSelect?: boolean;
  multiSelect?: boolean;
  calendarProps?: Partial<CalendarProps>;
}

export function navigateToModal({
  label,
  fieldId,
  flowId,
  configId,
  value,
  rangeSelect,
  multiSelect = true,
  calendarProps = {},
}: NavigateToModalParams) {
  globalModalStore.progress(
    {
      label,
      fieldId,
      multiSelect,
      value,
      rangeSelect,
      calendarProps,
      withButtons: {
        buttonText: `${fieldId}`,
        onClick: () => globalModalStore.goBack(),
      },
    },
    { flowId, configId }
  );
}
