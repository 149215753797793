export enum NetworkSignalType {
  Accelerator = "accelerator",
  Angel = "angel",
  Basecamp = "basecamp",
  NonBasecampVC = "vc",
  NonPortfolioFounder = "non_portfolio_founder",
  PortfolioFounder = "portfolio_founder",
  Operator = "operator",
  Outbound = "outbound",
  Event = "event",
  Other = "other",
}

export const sourceOptions = [
  // new
  { value: NetworkSignalType.Accelerator, label: "Accelerator", subLabel: "Who at Accelerator?" },
  { value: NetworkSignalType.Angel, label: "Angel" },
  { value: NetworkSignalType.Basecamp, label: "Basecamp", subLabel: "Who at Basecamp?" },
  { value: NetworkSignalType.NonBasecampVC, label: "VC" },
  { value: NetworkSignalType.NonPortfolioFounder, label: "Non-Portfolio Founder" },
  { value: NetworkSignalType.PortfolioFounder, label: "Portfolio Founder" },
  { value: NetworkSignalType.Operator, label: "Operator" },
  { value: NetworkSignalType.Outbound, label: "Outbound", subLabel: "Enter source?" },
  { value: NetworkSignalType.Event, label: "Event", subLabel: "Enter event name or details" },
  { value: NetworkSignalType.Other, label: "Other", subLabel: "Enter source?" },
];

const all_sources = [
  ...sourceOptions,

  // need these for backwards compatibility with old sources
  { value: "angel", label: "Angel" },
  { value: "investor", label: "Investor" },
  { value: "operator", label: "Operator" },
  { value: "portfolio_founder", label: "Portfolio Founder" },
  {
    value: "none_portfolio_founder",
    label: "Non Portfolio Founder",
  },
  { value: "service_provider", label: "Service Provider" },
];

export const getNetworkSignalSourceLabel = (typeValue: string) => {
  return all_sources.find((option) => option.value === typeValue)?.label ?? typeValue;
};
