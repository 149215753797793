import { GetCompaniesQueryVariables } from "@/app/service/opportunities.gql.ts";

import { LgCompanyStageName } from "../../gql/graphql.ts";

type Filters = GetCompaniesQueryVariables["filter"];

export const portfolioDefaultFilters = {
  and: {
    isOpCompany: {
      equalTo: true,
    },
  } satisfies Filters,
};

export const prospectDefaultFilters = {
  and: {
    // todo check if not archived
    // status: { notEqualTo: "archived" },
    companyStage: { stage: { equalTo: LgCompanyStageName.Prospect } },
  } satisfies Filters,
};

export const pipelineDefaultFilters = {
  and: {
    isOpCompany: { equalTo: false },
    or: [
      { companyStageExists: false },
      {
        or: [
          { companyStage: { stage: { isNull: true } } },
          { companyStage: { stage: { notEqualTo: LgCompanyStageName.Prospect } } },
        ],
      },
    ],
  } satisfies Filters,
};
