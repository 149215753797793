import { UserTag } from "@/gql/graphql";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils";

import { Button } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { ActionNames, useOpportunityActions } from "@/app/hooks";
import { useTagMutation } from "@/app/hooks/use-tag-mutation";
import { getCompanyUserTags } from "@/app/misc/parse-company-helpers";
import { OpportunityActionsModal } from "@/app/screens/opportunities/opportunity/components/actions/actions.tsx";
import { authStore } from "@/app/stores/auth.store";
import modalStore from "@/app/stores/modal.store.tsx";
import { Company } from "@/app/types";

import { getAvailableOpportunityActionNames } from "../../utils";

import { ActionsMenu } from "./card-menu";

export interface CardActionProps {
  containerClassName?: string;
  company: Company;
}

const actionWrapper = async (e, action: () => void) => {
  e.stopPropagation();
  e.preventDefault();
  return action();
};

export const CardActions = ({ containerClassName, company }: CardActionProps) => {
  const actions = useOpportunityActions({ company });

  const { isLaptop, isSmallLaptop } = useBreakpoints();
  const defaultActions: ActionNames[] = ["hide"];

  if (isSmallLaptop) {
    defaultActions.push("follow");
  }

  const availableActionNames = getAvailableOpportunityActionNames({ company, defaultActions });
  const { userId } = authStore;
  const { onSubmit: submitTag, loading: loadingFollow } = useTagMutation({});
  const tags = getCompanyUserTags(company);
  const { following = false } = tags[userId] || {};

  const toggleFollow = (e) => {
    actionWrapper(e, () =>
      submitTag({
        companyId: company.id,
        userId,
        tag: UserTag.Follow,
        value: following ? 0 : 1,
        successMessage: following
          ? `You are no longer tracking ${company.name}`
          : `You are now tracking ${company.name}`,
      }),
    );
  };

  const handleClick = (e) => {
    e.stopPropagation();

    modalStore.open(OpportunityActionsModal, {
      props: {
        actions: actions
          ? availableActionNames
              .slice(0)
              .reverse()
              .map((name) => ({
                ...actions[name],
                action: actions[name].action,
              }))
          : [],
      },
    });
  };

  return (
    <div className={cn("flex items-center", containerClassName)}>
      <Button
        text={following ? "Untrack" : "Track"}
        data-cy="action-follow"
        size="xs"
        variant="outline"
        className={cn("static hidden md:flex")}
        loading={loadingFollow}
        onClick={toggleFollow}
      />
      {isLaptop ? (
        <ActionsMenu
          options={
            actions
              ? availableActionNames
                  .slice(0)
                  .reverse()
                  .map((name) => ({
                    ...actions[name],
                    action: (e) => actionWrapper(e, actions[name].action),
                  }))
              : []
          }
        />
      ) : (
        <IconButtonV2 icon={"MoreVertical"} className={"size-4"} onClick={handleClick} />
      )}
    </div>
  );
};
