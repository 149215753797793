import React, { useState } from "react";

import { useParams } from "react-router";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { useBreakpoints } from "@/lib/hooks";

import { Button, Modal, NativeModal } from "@/app/components";
import { Company } from "@/app/hooks";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { useSetOpportunityEmailMutation } from "@/app/service/dashboard.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

export const CompanySelect = ({ emailId }: { emailId: string }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [acceptOpportunityEmail, { loading }] = useSetOpportunityEmailMutation();
  const { id } = useParams();

  const handleApply = () => {
    if (!selectedCompany || !emailId || !id) {
      return;
    }

    if (metricsStore.state.selectedCompanyId === "new") {
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      acceptOpportunityEmail({
        variables: {
          input: {
            action: EmailOpportunityAction.Accept,
            emailOpportunityId: emailId,
            companyId: selectedCompany?.id,
            notificationId: id,
          },
        },
      }).then(() => {
        modalStore.close();
      });
    } else {
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      modalStore.close();
    }
  };

  return (
    <div className="flex items-center gap-4">
      <SearchCompany
        companySearchType="lite"
        additionalSearchFilter={{ and: { isOpCompany: { equalTo: true } } }}
        selectCompany={(comp) => setSelectedCompany(comp)}
        onReset={() => setSelectedCompany(null)}
      />
      <Button
        variant={"secondary"}
        text={"Apply"}
        onClick={handleApply}
        disabled={!selectedCompany || loading}
        loading={loading}
      />
    </div>
  );
};

export const CompanySelectModal = ({ emailId }: { emailId: string }) => {
  const { isBigTablet } = useBreakpoints();

  if (isBigTablet) {
    return (
      <Modal isOpen showX={false} handleClose={modalStore.close} bodyContentClassName="p-0">
        <CompanySelect emailId={emailId} />
      </Modal>
    );
  }

  return (
    <NativeModal isOpen showX={false} handleClose={modalStore.close}>
      <CompanySelect emailId={emailId} />
    </NativeModal>
  );
};
