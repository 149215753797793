import { Loader } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { Icon, DropdownV2 } from "@/app/components";
import { useActionWrapper } from "@/app/hooks";

import { ActionsMenuProps } from "./types";

export function ActionsMenu({
  className,
  iconClassName,
  btnClassName,
  options = [],
  asyncActionLabels = ["Hide", "Unhide", "Track", "Untrack"],
  title,
  icon = "MoreVertical",
}: ActionsMenuProps) {
  const { loading, wrapAction } = useActionWrapper();

  return (
    <DropdownV2 className={cn("size-8", className)}>
      <DropdownV2.Button
        className={cn("flex justify-center", btnClassName)}
        onClick={(e) => e.stopPropagation()}
        size={"sm"}
      >
        {title} <Icon type={icon} className={cn("size-3.5 2xl:size-4", iconClassName)} />
      </DropdownV2.Button>
      <DropdownV2.Items className={"z-30 max-h-40"}>
        {options.map(({ label, action }) => (
          <DropdownV2.Item
            className="cursor-pointer"
            key={label}
            onClick={(e) => wrapAction({ label, event: e, action, isAsync: asyncActionLabels.includes(label) })}
          >
            <span className={cn("truncate text-black")}>{label}</span>
            {loading[label] && <Loader size={12} className="ml-1 animate-spin" />}
          </DropdownV2.Item>
        ))}
      </DropdownV2.Items>
    </DropdownV2>
  );
}
