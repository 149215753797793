import React, { useState } from "react";

import { Button, Popover } from "@/app/components";
import {
  CompanyChangeData,
  CompanySearchAutocomplete,
} from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";
import { DealroomCompanySource } from "@/app/screens/opportunities/utils/company-source.utils.ts";
import { DealroomCompanyQuery, useDealroomCompanyLazyQuery } from "@/app/service/dealroom-company.gql.ts";

type DealroomCompanyResult = NonNullable<DealroomCompanyQuery["dealroomCompany"]>;

const getLocation = (company: DealroomCompanyResult) => {
  const country = company?.hqLocations?.[0]?.country?.[0]?.name;
  const city = company?.hqLocations?.[0]?.city?.[0]?.name;

  return {
    country,
    city,
  };
};

export type SourceCompanyChangeData = {
  id: string;
  name?: string;
  websiteUrl?: string;
  description?: string;
  location?: {
    country: string;
    city: string;
  };
  sectors: string[];
  lastRound?: string;
};

type SourceCompanyFieldProps = {
  value?: string | null; // dealroom company id
  existingSource?: DealroomCompanySource;
  onChange: (data: SourceCompanyChangeData | null) => void;
  onUnlink: () => void;
};

export const SourceCompanyField = ({ value, onChange, onUnlink, existingSource }: SourceCompanyFieldProps) => {
  const [getDealroomCompany] = useDealroomCompanyLazyQuery();

  const [selectedDealroomCompany, setSelectedDealroomCompany] = useState<Pick<
    DealroomCompanyResult,
    "id" | "name" | "image"
  > | null>(existingSource ?? null);

  const handleSelectDealroomCompany = (company: CompanyChangeData) => {
    // we do not need custom string values here
    if (typeof company === "string") {
      return;
    }

    // on clear we want to reset to a previously selected dealroom company
    if (!company) {
      setSelectedDealroomCompany(existingSource ?? null);
      onChange(null);

      return;
    }

    getDealroomCompany({
      variables: {
        companyId: company.id,
        includeFounders: true,
        includeFunding: true,
        includeLocations: true,
        includeIndustries: true,
      },
    }).then(({ data }) => {
      const dealroomCompany = data?.dealroomCompany;
      if (!dealroomCompany) {
        return;
      }

      setSelectedDealroomCompany(dealroomCompany);

      const latestRound = data?.dealroomCompany?.fundingRounds?.nodes[0];
      const { country, city } = getLocation(dealroomCompany);

      onChange({
        id: dealroomCompany.id,
        name: dealroomCompany.name ?? undefined,
        websiteUrl: dealroomCompany.websiteUrl ?? undefined,
        description: dealroomCompany.tagline ?? undefined,
        location:
          country && city
            ? {
                country,
                city,
              }
            : undefined,
        sectors: dealroomCompany.industries?.map((i) => i.name).filter(Boolean),
        lastRound: latestRound?.round ?? undefined,
      });
    });
  };

  const handleUnlink = () => {
    setSelectedDealroomCompany(null);
    onUnlink();
  };

  const showUnlink = existingSource?.id === value;

  return (
    <div className="flex w-full gap-2">
      <CompanySearchAutocomplete
        className="flex-1"
        value={selectedDealroomCompany?.name}
        selectedCompany={selectedDealroomCompany}
        onChange={handleSelectDealroomCompany}
        placeholder="Search Dealroom company"
        disableCustomValue
        disableClear={showUnlink}
      />
      {showUnlink && (
        <Popover childrenContent={<span>Unlink {existingSource?.name}</span>}>
          <Button iconLeft="Unlink" onClick={handleUnlink} variant="secondary" type="button">
            Unlink
          </Button>
        </Popover>
      )}
    </div>
  );
};
