import { memo } from "react";

import { AssigneeStatus } from "@/gql/graphql";

import { DropdownV2, Icon, MemberCard, Popover } from "@/app/components";
import { LGMember } from "@/app/stores/members.store";

import { AddAssignees } from "./assign-dropdown";
import { useUpdateStatusMutation } from "./use-assign-status";
import { useCompanyAssignees } from "./use-company-assignees";
import { useRemoveAssignee } from "./use-remove-assignee";

export interface AssigneesViewProps {
  assignees: Array<LGMember & { assigneeId: string; status?: string }>;
  companyId: string;
}

// todo ResponsiveHorizontalList ?
export const Assignees = memo(function Assignees({ companyId }: AssigneesViewProps) {
  const { onRemoveAssignee } = useRemoveAssignee();
  const { onAssignStatus } = useUpdateStatusMutation();
  const { assignees } = useCompanyAssignees(companyId);

  const handleRemoveClick = ({ id }) => {
    onRemoveAssignee(id, companyId);
  };

  return (
    <div data-cy="assignees">
      <div className="flex items-center gap-1">
        <span className="font-medium text-gray-900">Small group</span>
      </div>
      <div className="mt-2 flex items-center gap-1" data-cy="assigneed-users">
        <div className="flex flex-wrap items-center gap-1">
          {assignees.map(({ id, firstName, lastName, avatar, status }) => {
            return (
              <DropdownV2 key={id} className="group relative">
                <DropdownV2.Button className={"h-6 p-0 hover:bg-transparent"}>
                  <Popover
                    closeOnClick={true}
                    placement="top-start"
                    childrenClassName="mt-2 p-2.5"
                    childrenContent={
                      <span className="text-sm font-medium">
                        {firstName} {lastName} {status ? <span className="capitalize">({status})</span> : ""}
                      </span>
                    }
                  >
                    <MemberCard
                      key={id}
                      firstname={firstName}
                      lastname={lastName}
                      image={avatar}
                      includeName={false}
                      imgClassName={
                        "size-6 rounded-xxs border border-white hover:border-gray-800 filter-none grayscale hover:opacity-80"
                      }
                      className={"flex cursor-pointer justify-start"}
                    />
                  </Popover>
                  {status === "lead" && (
                    <div className="absolute -bottom-0.5 -left-0.5 rounded-full bg-white p-px">
                      <Icon type={"Lead"} className="size-2" />
                    </div>
                  )}
                  <DropdownV2.Items className="rounded-xxs border" anchor={"bottom start"}>
                    <DropdownV2.Item className={"py-2"} onClick={() => handleRemoveClick({ id })}>
                      Remove
                    </DropdownV2.Item>
                    {status !== "lead" && (
                      <DropdownV2.Item
                        className={"py-2"}
                        onClick={() => onAssignStatus({ companyId, memberId: id, status: AssigneeStatus.Lead })}
                      >
                        Make lead
                      </DropdownV2.Item>
                    )}
                  </DropdownV2.Items>
                </DropdownV2.Button>
              </DropdownV2>
            );
          })}
        </div>
        <AddAssignees alreadyAssigned={assignees.map(({ id }) => id)} companyId={companyId} />
      </div>
    </div>
  );
});
