import { useMemo } from "react";

import { Button, LoadingOverLay } from "@/app/components";
import { Pill } from "@/app/components";
import { useCompanyDetails } from "@/app/hooks/use-company-details";

import { Access } from "./access";
import { Insight } from "./insight";

interface SearchDetailProps {
  id: string;
  handleNavigation: (route: string) => void;
  className?: string;
}

export const SearchDetails = ({ id, className = "", handleNavigation }: SearchDetailProps) => {
  const { company, badges, portCo, trelloSource, assignees, loading } = useCompanyDetails(id);

  const details = useMemo(() => {
    if (!company) {
      return <LoadingOverLay className={`${loading ? "z-10" : "hidden"}`} />;
    }

    return (
      <section className="h-[400px] transform-gpu scroll-py-3 overflow-y-auto p-4">
        <h1 className="text-left text-title font-bold">
          <div className={"flex flex-col items-start lg:flex-col"}>
            <div className={"flex"}>
              <span className="text-sm font-semibold">{portCo ? "Portfolio Company" : "Company"}</span>
              <span className={"mx-1.5 text-sm font-semibold text-primary"}>•</span>
              <span className="text-sm font-semibold">{trelloSource?.list?.name || "Not Yet Reviewed or Met"}</span>
            </div>
          </div>
        </h1>
        <p className="my-4 text-left text-base">{company.tagline || "..."}</p>
        <div className="text-left">
          {badges &&
            badges
              .filter(({ value, group }) => value !== "N/A" && group !== "signal")
              .map(({ value, id }) => <Pill label={value} key={id} className="mb-2 mr-2" />)}
        </div>
        <Access assignees={assignees} badges={badges} className="my-8" />
        <Insight sentiments={company.sentiment.nodes || []} container="border-none px-0 mb-8" />
      </section>
    );
  }, [company, loading, portCo, trelloSource, badges, assignees]);

  return (
    <section className={`relative h-full  max-h-[500px] ${className}`}>
      {details}
      {company && (
        <div className="flex h-[100px] items-center px-4 py-2">
          <Button
            variant="gradient"
            text="Go to Opportunity"
            iconLeft="Navigate"
            className="w-full"
            onClick={() => handleNavigation(company.id)}
            data-cy="global-search-company-link"
          />
        </div>
      )}
    </section>
  );
};
