import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import { Routes } from "@/app/constants";
import { EmptySection, OpportunityTable } from "@/app/screens/opportunities/opportunity/components";
import { OpportunityMaximise } from "@/app/screens/opportunities/opportunity/components/maximise/maximise.tsx";
import { MetricsXYChart } from "@/app/screens/opportunities/opportunity/components/metrics/metrics-xychart.tsx";
import { useMetrics } from "@/app/screens/opportunities/opportunity/components/metrics/use-metrics.tsx";

export const MetricsSection = ({ company }) => {
  const navigate = useNavigate();
  const {
    columns,
    tabularData,
    graphData,
    maximised,
    showEmpty,
    setMaximised,
    setSelectedMetric,
    metricsSelectList,
    selectedMetric,
    legendScaleOptions,
    navigateToMetricsExtraction,
  } = useMetrics(company);

  if (showEmpty) {
    return (
      <EmptySection
        text={"No metrics recorded"}
        Action={
          <Button
            text={"Add Metrics"}
            size="sm"
            variant="outline"
            className="mt-4"
            onClick={() => navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: company.id } })}
          />
        }
      />
    );
  }
  return (
    <section className={"rounded-md bg-white pb-3"}>
      <OpportunityMaximise setMaximised={setMaximised} maximised={maximised}>
        <MetricsXYChart
          data1={graphData}
          companyId={company.id}
          maximised={[maximised, setMaximised]}
          selectedMetric={[selectedMetric, setSelectedMetric]}
          metricsSelectList={metricsSelectList}
          legendScaleOptions={legendScaleOptions}
          navigateToMetricsExtraction={navigateToMetricsExtraction}
        />
      </OpportunityMaximise>
      <MetricsXYChart
        data1={graphData}
        companyId={company.id}
        maximised={[false, setMaximised]}
        selectedMetric={[selectedMetric, setSelectedMetric]}
        metricsSelectList={metricsSelectList}
        legendScaleOptions={legendScaleOptions}
        navigateToMetricsExtraction={navigateToMetricsExtraction}
      />
      {tabularData.length ? (
        <OpportunityTable<(typeof tabularData)[0]>
          columns={columns}
          enableSorting={false}
          tableOptions={{
            density: "extraDense",
            elementOptions: {
              row: {
                className: "hover:!bg-gray-50 group",
              },
            },
          }}
          data={tabularData}
          scrollXToLastItem={true}
          fnOnRowClick={(row) => {
            setSelectedMetric(row.Type);
          }}
          containerClassName={cn(
            "mt-8 border-t border-gray-200 transition-all duration-500 ease-in-out lg:mt-4",
            maximised ? "hidden" : "",
          )}
          pageSize={7}
          headerClassName={"w-full"}
          enableColumnPinning={true}
        />
      ) : null}
    </section>
  );
};
