import { LgCompanyStageName } from "@/gql/graphql";

import { useAssigneeCompanies } from "./use-assignee-companies";

export const useReviewingCompanies = () => {
  return useAssigneeCompanies({
    // todo check if not archived
    companyStage: { stage: { equalTo: LgCompanyStageName.Prospect } },
  });
};
