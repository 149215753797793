import React, { useState } from "react";

import { InitialTableState } from "@tanstack/table-core";

import { cn } from "@/lib/utils.ts";

import { FileUpload } from "@/app/components/file-upload/file-upload.tsx";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay.tsx";
import { OpportunityTable } from "@/app/screens/opportunities/opportunity/components/table.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { useAttachmentUpload } from "./use-attachment-upload.ts";
import { useAttachmentsColumns } from "./use-attachments-columns.tsx";

const tableInitialState: InitialTableState = {
  sorting: [
    {
      id: "date",
      desc: true,
    },
  ],
};

export const Attachments = () => {
  const [pendingRefetch, setPendingRefetch] = useState(false);
  const { attachments: nazareAttachments } = useOpportunityContext();
  const { attachments = [], refetch } = nazareAttachments ?? {};

  const { options: fileUploadOptions, uploadFn } = useAttachmentUpload();

  const columns = useAttachmentsColumns();

  // refetch attachments with a delay since it takes couple seconds for lambda to update file status
  const handleRefetchAttachments = () => {
    setPendingRefetch(true);
    setTimeout(() => {
      refetch?.().finally(() => {
        setPendingRefetch(false);
      });
    }, 5000);
  };

  const UploadComponent = (
    <FileUpload options={fileUploadOptions} uploadFn={uploadFn} onSuccess={handleRefetchAttachments} size="md" />
  );

  if (!attachments.length) {
    return (
      <div className="relative flex h-[200px] w-full flex-col items-center justify-center gap-5 rounded-lg bg-white">
        {pendingRefetch && <LoadingOverLay />}
        <div className={cn("flex flex-col items-center justify-center gap-5", pendingRefetch && "hidden")}>
          <span className="text-xl font-semibold text-gray-300">No Attachments Added</span>
          <FileUpload options={fileUploadOptions} uploadFn={uploadFn} onSuccess={handleRefetchAttachments} />
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      {pendingRefetch && <LoadingOverLay />}
      <OpportunityTable
        data={attachments}
        ActionButton={UploadComponent}
        columns={columns}
        enableGlobalFilter={true}
        headerClassName="flex-wrap"
        initialState={tableInitialState}
      />
    </div>
  );
};
