import { X } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

export const ModalTitle = ({
  title,
  className,
  secondaryAction = { text: "", onClick: () => null },
}: {
  title: string;
  className?: string;
  secondaryAction?: { text: string; onClick: () => void };
}) => {
  return (
    <div className={cn("flex w-full items-center justify-between", className)}>
      <X
        className={"rounded-full text-neutral-500"}
        width={24}
        height={24}
        onClick={() => {
          globalModalStore.toggleModal();
        }}
      />
      <span className={"ml-10 self-center text-lg font-semibold"}>{title}</span>
      <Button text={secondaryAction.text} className={"-mr-10"} variant={"text"} onClick={secondaryAction.onClick} />
    </div>
  );
};
