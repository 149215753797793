import { useEffect, useState } from "react";

import { motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { IconButton } from "@/app/components";
import { usePageNav } from "@/app/components/page-nav";

type JumpToTopProps = {
  hideOn: string[];
};

export const JumpToTop = ({ hideOn = [] }: JumpToTopProps) => {
  const { activeElements } = usePageNav();
  const [initialRender, setInitalRender] = useState(false);
  const isOpen = activeElements.length && (hideOn.some((sec) => !activeElements.includes(sec)) || hideOn.length === 0);

  useEffect(() => setInitalRender(true), []);

  if (initialRender) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        className={cn("fixed bottom-7 right-7 z-30 md:hidden")}
        animate={{ opacity: isOpen ? 1 : 0 }}
      >
        <IconButton
          type={"button"}
          className={"size-12"}
          icon={"ArrowUpCircle"}
          onClick={() => document.querySelector("body")?.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </motion.div>
    );
  }
};
