import { useMemo } from "react";

import { getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { TableNew, TableProps } from "@/app/components/table-new";
import { defaultColumnProps } from "@/app/components/table-new/table-new.config.ts";
import { Fund } from "@/app/hooks";
import { FundsTableHeader } from "@/app/screens/investments/components/funds-table/funds-table-header.tsx";

import { WithSubRows } from "../types.ts";

import { useFundsTableColumns } from "./funds-table-columns.tsx";
import { sortFunds } from "./funds-table-utils.ts";

interface FundsTableProps {
  funds: WithSubRows<Fund>[];
  loading?: boolean;
}

const tableOptions: TableProps<any>["options"] = { density: "dense" };

export const FundsTableNew = ({ funds, loading }: FundsTableProps) => {
  const data = useMemo(() => funds.slice().sort(sortFunds), [funds]);

  const aggregated = useMemo(() => {
    return {
      totalInvestedCapital: data.reduce(
        (accumulator, { totalInvestedCapital }) => accumulator + (totalInvestedCapital || 0),
        0
      ),
      unrealisedValue: data.reduce((accumulator, { unrealisedValue }) => accumulator + (unrealisedValue || 0), 0),
      currentEv: data.reduce((accumulator, { currentEv }) => accumulator + (currentEv || 0), 0),
      currentNav: data.reduce((accumulator, { currentNav }) => accumulator + (currentNav || 0), 0),
      goingInOwnership: data.reduce((accumulator, { goingInOwnership }) => accumulator + (goingInOwnership || 0), 0),
      currentOwnership: data.reduce((accumulator, { currentOwned }) => accumulator + (currentOwned || 0), 0),
      goingInPostMoney: data.reduce((accumulator, { initialEv }) => accumulator + (initialEv || 0), 0),
    };
  }, [data]);

  const columns = useFundsTableColumns(aggregated);

  const table = useReactTable<WithSubRows<Fund>>({
    columns,
    data,
    getRowCanExpand: (props) => (props.original.subRows ?? []).length > 1,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    defaultColumn: defaultColumnProps,
  });

  return (
    <div className="px-3 lg:px-5">
      <div className="size-full rounded-sm">
        <TableNew table={table} options={tableOptions} className="size-full divide-y">
          <TableNew.Header className="sticky top-0 z-10 bg-white">
            <FundsTableHeader aggregated={aggregated} data={data} />
          </TableNew.Header>
          <TableNew.Body className="size-full divide-y rounded-sm bg-white" loading={loading} />
        </TableNew>
      </div>
    </div>
  );
};
