import React, { ComponentProps, PropsWithChildren, ReactNode, SyntheticEvent } from "react";

import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuItems,
  MenuItemsProps,
} from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Button as GenericButton } from "../button/button.tsx";
import { IconButtonV2 as IconButton } from "../button/icon-button-v2.tsx";

type DefaultDropdownProps<T> = {
  options: T[];
  title: string;
  onChange: (value: T) => void;
  getKey: T extends object ? (value: T) => string : never;
  renderItem: (value: T) => React.ReactNode;
};

type DropdownProps<T> =
  | PropsWithChildren<{
      className?: string;
      options?: null;
    }>
  | DefaultDropdownProps<T>;

type DropdownItemsProps = PropsWithChildren<
  ComponentProps<typeof MenuItems> & {
    anchor?: MenuItemsProps["anchor"];
    className?: string;
    title?: ReactNode;
  }
>;

type DropdownOptionProps = {
  disabled?: boolean;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
} & MenuItemProps;

type ButtonVariants = {
  IconButton: typeof IconButton;
  GenericButton: typeof GenericButton;
};

type DropdownButtonProps<TVariant extends keyof ButtonVariants> = {
  buttonVariant?: TVariant;
} & Omit<MenuButtonProps<ButtonVariants[TVariant]>, "as">;

function Button<TVariant extends keyof ButtonVariants = "GenericButton">({
  buttonVariant,
  ...props
}: DropdownButtonProps<TVariant>) {
  const as = buttonVariant === "IconButton" ? IconButton : GenericButton;

  return <MenuButton {...props} as={as} data-cy="expand" />;
}

function Items({ children, className, anchor = "bottom end", ...props }: DropdownItemsProps) {
  return (
    <MenuItems
      {...props}
      anchor={anchor}
      className={cn(
        `mt-1 overflow-y-auto rounded-sm shadow-dropdown transition duration-100 ease-out`,
        className,
        "max-md:!max-h-1/2 max-md:z-50 max-md:!fixed max-md:!inset-0 max-md:m-0 max-md:flex max-md:!size-full max-md:!max-h-none max-md:!max-w-none max-md:items-end max-md:!rounded-none max-md:!border-none max-md:!p-0",
      )}
    >
      <Item className="absolute inset-0 grow bg-black/50 p-0 hover:bg-black/50 md:hidden">
        {({ close }) => <div onClick={close} className="size-full" />}
      </Item>
      <div className="z-10 w-full overflow-hidden rounded-t-xs bg-white">
        <div className="flex w-full justify-end md:hidden">
          <Item className="justify-between hover:bg-transparent">
            {({ close }) => (
              <>
                {!!props.title && <div className="text-lg">{props.title}</div>}
                <IconButton size="xs" icon="X" onClick={close} className="ml-auto" />
              </>
            )}
          </Item>
        </div>
        {children}
      </div>
    </MenuItems>
  );
}

function Item({ children, className, disabled, onClick, ...props }: DropdownOptionProps) {
  return (
    <MenuItem
      as={props.as ?? "div"}
      className={cn(
        "flex w-full items-center bg-white p-4 text-xs font-semibold text-black hover:bg-neutral-200 disabled:bg-neutral-50 md:py-3",
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      data-cy="menu-item"
    >
      {children}
    </MenuItem>
  );
}

function DropdownRoot<T>(props: DropdownProps<T>) {
  if (!props.options) {
    const { className, children } = props;
    return (
      <Menu {...props} className={className} as="div">
        {children}
      </Menu>
    );
  }

  const { options, title, getKey, onChange, renderItem, ...rest } = props;
  return (
    <Menu {...rest} as="div">
      <Button>{title}</Button>
      <Items>
        {options.map((op) => (
          <Item key={getKey(op)} onClick={() => onChange(op)}>
            {renderItem(op)}
          </Item>
        ))}
      </Items>
    </Menu>
  );
}

export const DropdownV2 = Object.assign(DropdownRoot, { Button, Items, Item });
