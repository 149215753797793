import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import { NetworkContactT, NetworkContactTsConnection } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  Autocomplete,
  AutocompleteButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
} from "@/app/components/forms/autocomplete.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { contactsLookupQuery } from "@/app/screens/network/contactsLookupQuery.gql.ts";

const useSearchResults = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { data, loading } = useQuery<{ contacts: NetworkContactTsConnection }>(contactsLookupQuery, {
    variables: {
      q: searchTerm,
      sort: "connectivity_desc",
      filters: "engaged_only",
    },
    skip: searchTerm.length < 2,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = data?.contacts.nodes ?? [];

  return { results, search, loading, searchTerm };
};

const SearchInput = ({ handleSearch, loading, onClear, placeholder, value }) => {
  const displayValue = (value: NetworkContactT | string | null): string => {
    return typeof value === "string"
      ? value
      : [value?.name?.join(", "), value?.email ? `(${value.email})` : null].filter(Boolean).join(" ") ?? "";
  };

  return (
    <div className="relative">
      <AutocompleteInput
        as={InputV2}
        className={cn(
          "pr-20 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <ProgressSpinner className="size-4 text-inherit" />}
        {!!value && <IconButtonV2 icon="X" size="xs" onClick={onClear} />}
        <AutocompleteButton as="div" className={"transition data-[open]:-scale-100"}>
          <IconButtonV2 className="bg-transparent" icon="Chevron Down" size="xs" />
        </AutocompleteButton>
      </div>
    </div>
  );
};

type NetworkAutocompleteChangeData = string | NetworkContactT;
type NetworkSearchAutocompleteProps = {
  onChange: (val: NetworkAutocompleteChangeData) => void;
  value?: NetworkAutocompleteChangeData;
  placeholder?: string;
};

export const NetworkSearchAutocomplete = ({ onChange, value, placeholder }: NetworkSearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults();

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (val: NetworkContactT | string) => {
    onChange(val);
  };

  const handleClear = () => {
    onChange("");
  };

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <SearchInput
            loading={loading}
            handleSearch={handleSearch}
            onClear={handleClear}
            placeholder={placeholder}
            value={value}
          />
        }
        options={
          <AutocompleteOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--input-width)] rounded-xxs border bg-white [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "[--anchor-max-height:50vh]",
              "z-10 shadow-sm", // needed because of broken location input
            )}
          >
            <div className="sticky top-0 z-[1] bg-white p-1">
              {!searchTerm && (
                <AutocompleteOption
                  value={null}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                  )}
                  disabled={true}
                >
                  Start typing to see the results...
                </AutocompleteOption>
              )}
              {searchTerm && (
                <AutocompleteOption
                  value={searchTerm}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                    "sticky top-0 z-[1]",
                  )}
                >
                  {`Create "${searchTerm}"`}
                </AutocompleteOption>
              )}
              {loading && (
                <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
              )}
            </div>
            {results.map((resultItem) => (
              <AutocompleteOption
                key={resultItem.email}
                value={resultItem}
                className={cn(
                  "flex select-none items-center gap-2 px-3 py-1.5 text-sm overflow-hidden",
                  "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                )}
              >
                <div className="w-full">
                  <div className="truncate text-sm">{resultItem.name?.join(", ")}</div>
                  <div className="text-xs text-gray-600">{resultItem.email}</div>
                </div>
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
