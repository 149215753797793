import { cn, parseCompanyDetails } from "@/lib/utils";

import { AvatarV2, Card } from "@/app/components";
import { GetCompaniesQuery } from "@/app/service/opportunities.gql.ts";

import { TitleWithSignal } from "../title.tsx";

import { CardAccess } from "./card-access";
import { CardActions } from "./card-actions";
import { CardEngagement } from "./card-engagement";
import { CardExcitement } from "./card-excitement";
import { Tags } from "./tags";

type CompanyData = NonNullable<GetCompaniesQuery["lgCompanies"]>["edges"][number]["node"];

export interface CompanyCardProps {
  company: CompanyData;
  index: number;
  onClick?: () => void;
}

export const CompanyCard = ({ company, index: idx, onClick }: CompanyCardProps) => {
  const {
    id,
    name,
    image,
    city,
    country,
    badges,
    industries,
    tagline,
    assignees,
    round,
    portCo,
    badgeSignals,
    trelloSource,
    sentiments,
    previouslyMet,
    basecamp,
  } = parseCompanyDetails(company);

  return (
    <Card
      key={`${id}_${idx}`}
      onClick={onClick}
      data-cy="opportunity-card"
      className={cn(
        "group relative min-h-[15dvh] cursor-pointer overflow-hidden bg-white !p-0.5 shadow-surface transition-shadow",
      )}
    >
      {/* gradient border */}
      <div className="absolute left-0 top-0 size-full bg-gradient-to-r from-primary-400 to-secondary-400 opacity-0 transition-all ease-in group-active:opacity-100 lg:group-hover:opacity-100" />

      <div
        className={cn(
          "relative h-full rounded-xs bg-white p-5 hover:transition-all",
          // need to reduce bottom padding because PCG Leads are shifted 4px to the bottom for the alignment
          "pb-4",
        )}
      >
        <div className={"relative flex w-full items-center"}>
          <AvatarV2 type="company" size="sm" src={image} />
          <div className={"ml-4 text-left"}>
            <TitleWithSignal
              title={name}
              signals={badgeSignals}
              showSignals={true}
              isPortco={!!portCo}
              name={trelloSource?.list?.name}
            />
          </div>
          <CardActions containerClassName="absolute right-0 top-0 transition-all flex" company={company} />
        </div>
        <p className="mt-3 line-clamp-2 text-sm font-medium text-neutral-700 md:text-sm lg:line-clamp-1 lg:text-xs 2xl:text-sm">
          {tagline || "..."}
        </p>
        <Tags
          city={city}
          country={country}
          badges={badges}
          round={round}
          portCo={portCo}
          industries={industries}
          className="my-3 lg:mb-6 lg:mt-3"
        />
        <div className="flex gap-0 md:grid md:grid-cols-5">
          <CardExcitement sentiments={sentiments} previouslyMet={previouslyMet} isPortco={!!portCo} />

          <CardEngagement
            companyId={company.id}
            engagement={company.engagementSummary}
            previouslyMet={previouslyMet}
            isPortco={!!portCo}
          />

          <CardAccess assignees={assignees} basecamp={basecamp} previouslyMet={previouslyMet} isPortco={!!portCo} />
        </div>
      </div>
    </Card>
  );
};
