import { AvatarV2, Card } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { AgendaReminder } from "./agenda-reminder";

export const DashboardHeader = () => {
  const currentUser = authStore.user;

  if (!currentUser) {
    return null;
  }

  return (
    <Card data-cy="dashboard_header">
      <div className="flex items-center gap-3 md:gap-4">
        <AvatarV2
          className="hidden md:flex"
          src={currentUser.avatar}
          type="user"
          size="lg"
          initials={currentUser.initials}
        />
        <AvatarV2
          className="md:hidden"
          src={currentUser.avatar}
          type="user"
          size="md"
          initials={currentUser.initials}
        />
        <div>
          <h1 className="text-xl font-semibold text-black md:text-3xl">
            Welcome, {currentUser?.firstName}!<span className="ml-1">👋</span>
          </h1>
          <h3 className="text-xs font-medium text-gray-600 md:text-base">Here are your latest tasks</h3>
        </div>
      </div>
      <AgendaReminder className="mt-4" />
    </Card>
  );
};
