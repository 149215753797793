import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertFounderMutationVariables = Types.Exact<{
  upsertFounderDataInput: Types.UpsertFounderDataInput;
}>;

export type UpsertFounderMutation = { __typename?: "Mutation"; founderUpsert?: any | null };

export type UnlinkFounderMutationVariables = Types.Exact<{
  unlinkFounderDataInput: Types.UnlinkFounderDataInput;
}>;

export type UnlinkFounderMutation = { __typename?: "Mutation"; founderUnlink?: any | null };

export type SearchFoundersGlobalQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  dealroomFilter?: Types.InputMaybe<Types.DealroomFounderFilter>;
  lgFilter?: Types.InputMaybe<Types.LgFounderFilter>;
}>;

export type SearchFoundersGlobalQuery = {
  __typename?: "Query";
  dealroomFounders?: {
    __typename?: "DealroomFoundersConnection";
    nodes: Array<{
      __typename?: "DealroomFounder";
      id: any;
      image?: string | null;
      path?: string | null;
      name?: string | null;
      linkedinUrl?: string | null;
      url?: string | null;
      srcFounder?: { __typename?: "SrcFounder"; entityId: any } | null;
    }>;
  } | null;
  lgFounders?: {
    __typename?: "LgFoundersConnection";
    nodes: Array<{
      __typename?: "LgFounder";
      id: any;
      image?: string | null;
      path?: string | null;
      name?: string | null;
      linkedinUrl?: string | null;
      srcFoundersByEntityId: {
        __typename?: "SrcFoundersConnection";
        nodes: Array<{ __typename?: "SrcFounder"; sourceType: string; sourceId: string }>;
      };
    }>;
  } | null;
};

export type FounderSearchQueryVariables = Types.Exact<{
  q: Types.Scalars["String"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type FounderSearchQuery = {
  __typename?: "Query";
  founderSearchV1?: {
    __typename?: "FounderSearchResultV1sConnection";
    nodes: Array<{
      __typename?: "FounderSearchResultV1";
      id?: string | null;
      name?: string | null;
      linkedinUrl?: string | null;
      image?: string | null;
      source?: string | null;
    }>;
  } | null;
};

export const UpsertFounderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsertFounder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "upsertFounderDataInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpsertFounderDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "founderUpsert" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "upsertFounderDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "upsertFounderDataInput" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpsertFounderMutationFn = Apollo.MutationFunction<UpsertFounderMutation, UpsertFounderMutationVariables>;

/**
 * __useUpsertFounderMutation__
 *
 * To run a mutation, you first call `useUpsertFounderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFounderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFounderMutation, { data, loading, error }] = useUpsertFounderMutation({
 *   variables: {
 *      upsertFounderDataInput: // value for 'upsertFounderDataInput'
 *   },
 * });
 */
export function useUpsertFounderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertFounderMutation, UpsertFounderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertFounderMutation, UpsertFounderMutationVariables>(UpsertFounderDocument, options);
}
export type UpsertFounderMutationHookResult = ReturnType<typeof useUpsertFounderMutation>;
export type UpsertFounderMutationResult = Apollo.MutationResult<UpsertFounderMutation>;
export type UpsertFounderMutationOptions = Apollo.BaseMutationOptions<
  UpsertFounderMutation,
  UpsertFounderMutationVariables
>;
export const UnlinkFounderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "unlinkFounder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unlinkFounderDataInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UnlinkFounderDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "founderUnlink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "unlinkFounderDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "unlinkFounderDataInput" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UnlinkFounderMutationFn = Apollo.MutationFunction<UnlinkFounderMutation, UnlinkFounderMutationVariables>;

/**
 * __useUnlinkFounderMutation__
 *
 * To run a mutation, you first call `useUnlinkFounderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkFounderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkFounderMutation, { data, loading, error }] = useUnlinkFounderMutation({
 *   variables: {
 *      unlinkFounderDataInput: // value for 'unlinkFounderDataInput'
 *   },
 * });
 */
export function useUnlinkFounderMutation(
  baseOptions?: Apollo.MutationHookOptions<UnlinkFounderMutation, UnlinkFounderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlinkFounderMutation, UnlinkFounderMutationVariables>(UnlinkFounderDocument, options);
}
export type UnlinkFounderMutationHookResult = ReturnType<typeof useUnlinkFounderMutation>;
export type UnlinkFounderMutationResult = Apollo.MutationResult<UnlinkFounderMutation>;
export type UnlinkFounderMutationOptions = Apollo.BaseMutationOptions<
  UnlinkFounderMutation,
  UnlinkFounderMutationVariables
>;
export const SearchFoundersGlobalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchFoundersGlobal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DealroomFounderFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgFounderFilter" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomFounders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "srcFounder" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "entityId" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lgFounders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "srcFoundersByEntityId" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                                  { kind: "Field", name: { kind: "Name", value: "sourceId" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSearchFoundersGlobalQuery__
 *
 * To run a query within a React component, call `useSearchFoundersGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFoundersGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFoundersGlobalQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      dealroomFilter: // value for 'dealroomFilter'
 *      lgFilter: // value for 'lgFilter'
 *   },
 * });
 */
export function useSearchFoundersGlobalQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>(
    SearchFoundersGlobalDocument,
    options,
  );
}
export function useSearchFoundersGlobalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>(
    SearchFoundersGlobalDocument,
    options,
  );
}
export function useSearchFoundersGlobalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchFoundersGlobalQuery, SearchFoundersGlobalQueryVariables>(
    SearchFoundersGlobalDocument,
    options,
  );
}
export type SearchFoundersGlobalQueryHookResult = ReturnType<typeof useSearchFoundersGlobalQuery>;
export type SearchFoundersGlobalLazyQueryHookResult = ReturnType<typeof useSearchFoundersGlobalLazyQuery>;
export type SearchFoundersGlobalSuspenseQueryHookResult = ReturnType<typeof useSearchFoundersGlobalSuspenseQuery>;
export type SearchFoundersGlobalQueryResult = Apollo.QueryResult<
  SearchFoundersGlobalQuery,
  SearchFoundersGlobalQueryVariables
>;
export const FounderSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FounderSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "q" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "founderSearchV1" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "q" },
                value: { kind: "Variable", name: { kind: "Name", value: "q" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "max" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "source" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFounderSearchQuery__
 *
 * To run a query within a React component, call `useFounderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFounderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFounderSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFounderSearchQuery(
  baseOptions: Apollo.QueryHookOptions<FounderSearchQuery, FounderSearchQueryVariables> &
    ({ variables: FounderSearchQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FounderSearchQuery, FounderSearchQueryVariables>(FounderSearchDocument, options);
}
export function useFounderSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FounderSearchQuery, FounderSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FounderSearchQuery, FounderSearchQueryVariables>(FounderSearchDocument, options);
}
export function useFounderSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FounderSearchQuery, FounderSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FounderSearchQuery, FounderSearchQueryVariables>(FounderSearchDocument, options);
}
export type FounderSearchQueryHookResult = ReturnType<typeof useFounderSearchQuery>;
export type FounderSearchLazyQueryHookResult = ReturnType<typeof useFounderSearchLazyQuery>;
export type FounderSearchSuspenseQueryHookResult = ReturnType<typeof useFounderSearchSuspenseQuery>;
export type FounderSearchQueryResult = Apollo.QueryResult<FounderSearchQuery, FounderSearchQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
