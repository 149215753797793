import React from "react";

import { Controller } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Input } from "@/app/components";
import { WebSelect } from "@/app/components/input/web-select.tsx";
import { FormField } from "@/app/screens/opportunities/add-new/shared/components/form-field.tsx";

import { sourceOptions } from "../../../opportunity/components/network-signals/network-signal-utils";

// todo generic type
export const CompanySourceField = ({ control, name, required = true }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <div className="flex flex-col gap-2 lg:flex-row">
          <FormField
            className="grow"
            label="Where did we hear about this company from?"
            required={required}
            errorMessage={fieldState.error?.message}
          >
            <WebSelect
              styles={{ containerClassName: "max-w-[500px]" }}
              options={sourceOptions}
              defaultOptions={sourceOptions.filter((option) => option.value === field.value.type)}
              multiSelect={false}
              onChange={(value) => field.onChange({ ...field.value, type: value?.[0]?.value })}
              placeholder="Select source type"
            />
          </FormField>
          <FormField
            className={cn("invisible grow opacity-0 transition-opacity", !!field.value.type && "visible opacity-100")}
            label={`Which ${sourceOptions.find((option) => option.value === field.value.type)?.label}`}
            required={required}
            errorMessage={fieldState.error?.message}
          >
            <Input
              placeholder={"Name"}
              containerClassName="w-full"
              value={field.value.name}
              onChange={(e) => field.onChange({ ...field.value, name: e.target.value })}
            />
          </FormField>
        </div>
      )}
    ></Controller>
  );
};
