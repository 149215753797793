import React from "react";

import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { AddCommentInput } from "./add-comment-input.tsx";
import { CommentItem } from "./comment/comment-item.tsx";

export const Comments = () => {
  const { comments: nazareComments } = useOpportunityContext();
  const comments = nazareComments?.comments ?? [];

  return (
    <section id="comments" className="relative">
      <AddCommentInput />
      {comments.length > 0 && (
        <div className="mt-2 max-h-[80dvh] space-y-2 overflow-auto border-none bg-transparent p-0 lg:space-y-4">
          {comments.map((item) => (
            <CommentItem comment={item} key={item.id} />
          ))}
        </div>
      )}
    </section>
  );
};
