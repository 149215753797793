import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

export type AttachmentsCommentsData = {
  title: [string, string];
  lastCommentDate?: string;
  commentNo: number;
  lastAttachmentsDate?: string;
  attachmentsNo: number;
};

export const useAttachmentsComments = (): AttachmentsCommentsData | null => {
  const { attachments: nazareAttachments, comments: nazareComments } = useOpportunityContext();
  const attachments = nazareAttachments ? nazareAttachments.attachments : [];
  const comments = nazareComments ? nazareComments.comments : [];

  return attachments?.length || comments?.length
    ? {
        title: ["Attachments", "Comments"],
        attachmentsNo: attachments?.length,
        lastAttachmentsDate: attachments?.at(-1)?.date,
        commentNo: comments?.length,
        lastCommentDate: comments?.at(-1)?.date,
      }
    : null;
};
