import React from "react";

import { cn, getInitials } from "@/lib/utils.ts";

import { AvatarV2, Popover, ResponsiveHorizontalList } from "@/app/components";
import { getNetworkSignalSourceLabel } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal-utils.ts";

import {
  HighlightsCard,
  HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "../highlights-card.tsx";

import { InvestmentBasecampFundsData } from "./use-invested-basecamp-funds.ts";
import { useTimesFlagged } from "./use-times-flagged.ts";

const BasecampFundsData = ({ investedBasecampFunds }) => {
  return (
    <>
      <HighlightsCardTitle text={String(investedBasecampFunds.title)} className="text-center" />
      <HighlightsCardNumber value={investedBasecampFunds.fundsTotal} />
      <Popover
        containerClassName={"w-full flex justify-center"}
        placement={"bottom"}
        childrenContent={
          <div className="flex flex-col gap-y-2">
            {investedBasecampFunds.funds.map(({ name, image }) => (
              <div key={`${name}_basecamp_item`} className="flex items-center gap-1">
                <AvatarV2
                  type={"user"}
                  size="xs"
                  fallbackIcon="Users"
                  key={image}
                  src={`https://${image}`}
                  initials={getInitials(name)}
                />
                <span className="text-xs font-semibold text-black">{name}</span>
              </div>
            ))}
          </div>
        }
      >
        <ResponsiveHorizontalList
          className={cn("justify-center gap-x-2 overflow-hidden")}
          popoverProps={{
            disabled: true,
          }}
          itemProps={{
            className: "shrink-0",
          }}
        >
          {investedBasecampFunds.funds.map(({ name, image }) => (
            <AvatarV2
              type={"user"}
              size="xs"
              fallbackIcon="Users"
              key={image}
              src={`https://${image}`}
              initials={getInitials(name)}
            />
          ))}
        </ResponsiveHorizontalList>
      </Popover>
    </>
  );
};

const TimesFlagged = () => {
  const { data: networkSignalsData } = useTimesFlagged();
  const isFlagged = !!networkSignalsData?.numTimesFlagged;
  const numOfFlagged = networkSignalsData?.numTimesFlagged;

  return (
    <>
      {isFlagged ? (
        <Popover
          containerClassName="h-full"
          placement={"bottom"}
          childrenContent={
            <div className="space-y-2">
              {networkSignalsData?.signals.map((signal) => (
                <div key={signal.id} className="flex items-center gap-1 text-xs font-semibold">
                  <span className="text-black">{signal.sourceName}</span>
                  <span className="text-gray-600">({getNetworkSignalSourceLabel(signal.sourceType)})</span>
                </div>
              ))}
            </div>
          }
        >
          <HighlightsCardBody>
            <HighlightsCardTitle text={"# Times Flagged"} />
            <HighlightsCardNumber value={numOfFlagged ?? 0} />
            <HighlightsCardLastUpdated date={networkSignalsData?.signals[0]?.createdUtc} />
          </HighlightsCardBody>
        </Popover>
      ) : (
        <HighlightsCardEmptyState text={"Not Previously Flagged"} textClassName="text-lg" />
      )}
    </>
  );
};

export const InvestedBasecampFundsCard = ({
  investedBasecampFunds: investedBasecampFunds,
}: {
  investedBasecampFunds: InvestmentBasecampFundsData | null;
}) => {
  return (
    <HighlightsCard className="flex">
      <HighlightsCardBody className="w-1/2">
        {investedBasecampFunds ? (
          <BasecampFundsData investedBasecampFunds={investedBasecampFunds} />
        ) : (
          <HighlightsCardEmptyState text={"No Basecamp Funds Invested"} textClassName="text-lg" />
        )}
      </HighlightsCardBody>
      <div className="w-1/2">
        <TimesFlagged />
      </div>
    </HighlightsCard>
  );
};
