import { endOfDay, format, nextMonday } from "date-fns";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";

export const AgendaReminder = ({ className }: { className?: string }) => {
  return (
    <div className={cn("flex w-full flex-row flex-wrap items-center", className)}>
      <p className="items-center text-sm font-normal md:flex">
        Agenda submissions window is open until{" "}
        <span className="md:ml-1">Monday, {format(endOfDay(nextMonday(new Date())), "LLLL dd")}</span>
      </p>
      <Button
        variant="secondary"
        size="sm"
        className="mt-3 w-full md:ml-2 md:mt-0 md:w-auto"
        onClick={() =>
          window.open(
            "https://localglobe.retool.com/apps/2514fd4a-b637-11ee-921f-e347d5f4f273/Agenda%20Curator",
            "_blank",
          )
        }
      >
        Submit now
      </Button>
    </div>
  );
};
