import { useMemo } from "react";

import { useGetCompanyQuery } from "@/app/service/opportunities.gql.ts";
import membersStore, { LGMember } from "@/app/stores/members.store.tsx";

import { getCompanyUserTags } from "../misc/parse-company-helpers";

export const useCompanyDetails = (id: string) => {
  const { data, loading, error, refetch } = useGetCompanyQuery({
    variables: { id },
    skip: id === "new",
    fetchPolicy: "network-only",
  });

  const company = useMemo(() => {
    if (data?.lgCompany) {
      return data.lgCompany;
    }
  }, [data]);

  const signal = company?.badges?.[0]?.group === "signal" ? company.badges[0] : null;

  const badges = company?.badges;

  const portCo = company?.badges?.find((badge) => badge.id === "portfolio_company");

  const assigneIds: string[] =
    company?.companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")?.trelloCardAsSource?.memberIds ||
    [];

  const assignees = assigneIds.reduce<LGMember[]>((accumulator, id) => {
    const member = membersStore.getByTrelloId(id);
    if (member) {
      return [...accumulator, member];
    }
    return accumulator;
  }, []);

  let signalAssignees: LGMember[] | undefined = [];

  if (company?.signals?.items[0]?.nzrOpportunities?.nodes?.length) {
    signalAssignees = company?.signals?.items[0].nzrOpportunities?.nodes.reduce<LGMember[]>(
      (accumulator, { status, lgMemberByAssignee }) => {
        if (status === "SNOOZED" || !lgMemberByAssignee) {
          return accumulator;
        }

        return [
          ...accumulator,
          { ...lgMemberByAssignee, initials: `${lgMemberByAssignee.firstName[0]}${lgMemberByAssignee.lastName[0]}` },
        ];
      },
      [],
    );
  }

  const trelloSource = company?.companiesSources?.items?.find(
    (item) => item.sourceType === "TrelloCard",
  )?.trelloCardAsSource;

  const socialMediaIcons = [
    { name: "Continent", url: company?.websiteUrl },
    { name: "Twitter", url: company?.twitterUrl },
    { name: "Linkedin", url: company?.linkedinUrl },
    { name: "Trello", url: trelloSource?.url },
    {
      name: "Dealroom",
      url: company?.companiesSources?.items.reduce((webLink, source) => {
        if (source.dealroomCompanyAsSource?.url) {
          return webLink + source.dealroomCompanyAsSource?.url;
        }
        return webLink;
      }, ""),
    },
  ];

  const activeSectionsList = [
    "about",
    "highlights",
    "founders",
    "funding",
    "performance",
    "sentiment",
    "basecamp",
    "attachments",
    "comments",
  ].filter((section) => {
    if (section === "about" || section === "highlights") {
      return true;
    }
    if (section === "founders") {
      return true;
    }
    if (section === "funding") {
      return company?.fundingRounds?.items?.length;
    }
    if (section === "performance") {
      return (
        company?.fundingRounds?.items?.length || company?.employeesChart?.length || company?.websiteTrafficChart?.length
      );
    }
    if (section === "sentiment") {
      return company?.sentiment?.nodes.length;
    }
    if (section === "basecamp") {
      return company?.investors?.items.length;
    }
    if (section === "attachments") {
      return trelloSource?.attachments?.length;
    }
    if (section === "comments") {
      return trelloSource?.comments?.length;
    }

    return false;
  });

  const userTags = getCompanyUserTags(company);

  return {
    name: company?.name,
    image: company?.image,
    tagline: company?.tagline,
    activeSectionsList,
    company,
    portCo,
    signal,
    badges,
    assignees,
    websiteUrl: company?.websiteUrl,
    signalAssignees,
    socialMediaIcons,
    trelloSource,
    loading,
    error,
    userTags,
    refetch,
  };
};
