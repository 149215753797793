import { Fragment } from "react";

import { motion } from "framer-motion";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";

import { UserRoleType } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { AvatarV2, Button } from "@/app/components";
import { tabsMobile } from "@/app/components/navbar/header.store";
import { Routes } from "@/app/constants";
import { AppVersion } from "@/app/router/app-version";
import { AddNewResponsiveModal } from "@/app/screens/modal/add-new";
import { SearchMobile } from "@/app/screens/modal/search";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { OpportunitiesTab, OpportunitiesTabType } from "@/app/screens/opportunities/opportunities.store.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

import globalModalStore from "./global-modal.store";

export const MobileDrawerNavigation = observer(function MobileDrawerNavigation() {
  const { user, userRoles: myRoles } = authStore;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="flex size-full flex-col items-center bg-white px-1 shadow-[12px_0px_24px_0px_rgba(0,0,0,0.01)]">
      {/*ADD & SEARCH*/}
      <div className={"w-full space-y-3"}>
        <Button
          iconLeft="Add"
          variant="gradient"
          size={"lg"}
          className={cn("w-full text-white")}
          onClick={() => {
            modalStore.open(AddNewResponsiveModal);
            globalModalStore.toggleSlideOver({
              isOpen: false,
            });
          }}
          data-cy="add-new"
        >
          <span className="mr-auto flex text-[16px] font-semibold">Add New...</span>
        </Button>
        <Button
          size={"lg"}
          className={cn("w-full text-title font-semibold")}
          onClick={() => {
            modalStore.open(SearchMobile);
            globalModalStore.toggleSlideOver({
              isOpen: false,
            });
          }}
          variant="secondary"
          data-cy="navbar-search-call"
          iconLeft="Search"
        >
          <div className={"flex grow items-center justify-between"}>
            <span className="text-[16px] font-semibold text-gray-900">Search</span>
          </div>
        </Button>
      </div>
      {/*NAV*/}
      <nav className="mt-10 flex w-full flex-col justify-between ">
        <ul role="list" className={cn("flex w-full flex-col space-y-3")}>
          {tabsMobile.map((group, index) => (
            <Fragment key={index}>
              {group.map(({ name, Icon, path, disabled }) => (
                <Fragment key={name}>
                  <motion.li
                    data-cy={`navbar-link-${path}`}
                    transition={{ duration: 0.05, easeInOut: true }}
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      if (path in OpportunitiesTab) {
                        opportunitiesStore.setState({
                          currentTab: {
                            path: path as OpportunitiesTabType,
                            quickView: "all",
                          },
                        });
                      }
                      navigate(path);
                      globalModalStore.toggleSlideOver({
                        isOpen: false,
                      });
                    }}
                    className={cn(
                      `flex h-10 w-full select-none items-center rounded-xs px-3 hover:bg-gray-400`,
                      pathname === `/${path}` ? "bg-gray" : "",
                    )}
                  >
                    <Link
                      to={path}
                      className={cn(
                        pathname === `/${path}` ? "font-bold" : "font-semibold",
                        "flex w-full items-center justify-start leading-6 text-white",
                      )}
                    >
                      <Icon className={cn("text-gray-900", "size-5")} />
                      <span className={cn("ml-3 flex text-[16px] font-semibold text-gray-900")}>{name}</span>
                      {myRoles?.includes(UserRoleType.UserRoleNetwork) && path === "network" ? (
                        <div
                          className={
                            "opacity-0 transition-opacity duration-300 ease-in group-hover:mouse-hover:opacity-100"
                          }
                        >
                          <div className="ml-auto hidden -translate-x-1 -translate-y-2.5 text-[12px] font-bold text-[#fe625b] group-hover:block">
                            Alpha
                          </div>
                        </div>
                      ) : null}
                    </Link>
                  </motion.li>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </ul>
      </nav>

      {/*USER & VERSION*/}
      <div className={cn(`flex w-full flex-1 flex-col items-center justify-end pb-12`)}>
        <AvatarV2
          src={user?.avatar}
          initials={user?.initials}
          type={"user"}
          size={"lg"}
          onClick={() => {
            navigate(`${Routes.settings}`);
            globalModalStore.toggleSlideOver({
              isOpen: false,
            });
            navigate("/settings");
          }}
        />
        <div className="mt-3 flex w-full flex-col items-center">
          <span className="flex w-full justify-center  text-[16px] font-semibold text-black">
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </span>
          <AppVersion className={"mt-5 text-xs text-gray-600"} />
        </div>
      </div>
    </div>
  );
});
