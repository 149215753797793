import { Button } from "@/app/components";

export const HistoryToggle = ({ value, setValue, activeLabel = "New", inActiveLabel = "Archived" }) => {
  return (
    <div className="ml-auto flex select-none items-center gap-1">
      <Button size="xs" variant="primary" className={value ? "bg-gray-400" : ""} onClick={() => setValue(false)}>
        {activeLabel}
      </Button>
      <Button size="xs" variant="primary" className={!value ? "bg-gray-400" : ""} onClick={() => setValue(true)}>
        {inActiveLabel}
      </Button>
    </div>
  );
};
