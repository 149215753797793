import { ColumnDef } from "@tanstack/react-table";

import {
  RowWithCurrency,
  RowWithMultiplier,
  NumberRowWithFormat,
  TableLabel,
} from "@/app/screens/opportunities/opportunity/components";

import { HeaderWithTotals } from "./pcg-overview-header";

type PCGOverviewColumn = {
  fund: string;
  type: string;
  initialInvestment: string;
  totalInvested: string;
  unrealisedNav: string;
  vc: string;
  currentOwned: string;
  currentEv: string;
  mtpEv: string;
  currentNavPercentage: string;
  revenue: string;
  margin: string;
};

export const pcgOverviewColumns: ColumnDef<PCGOverviewColumn>[] = [
  {
    accessorKey: "fund",
    header: "Fund",
    cell: (info) => (
      <TableLabel
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-center text-lg"}
        navigateTo={`/fund-overview/${info.row.original.fund.toLowerCase()}`}
      />
    ),
  },
  {
    accessorKey: "currentNavPercentage",
    header: "NAV as % of Fund",
    cell: (info) => (
      <NumberRowWithFormat
        decimals={2}
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "type",
    header: "Type",
    cell: (info) => (
      <span
        className="w-full flex justify-center items-center text-sm"
      >{info.renderValue() as string}</span>
    ),
  },
  {
    accessorKey: "initialInvestment",
    header: ({ header, table }) => <HeaderWithTotals header={header} table={table} text="Initial Investment" />,
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "goingInOwnership",
    header: "Going in Ownership",
    cell: (info) => (
      <NumberRowWithFormat
        decimals={2}
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "totalInvestedCapital",
    header: "Total Invested",
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "investedCapitalPercentage",
    header: "Invested Capital as % of Fund",
    cell: (info) => (
      <NumberRowWithFormat
        decimals={2}
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "currentOwned",
    header: "Current Ownership",
    cell: (info) => (
      <NumberRowWithFormat
        decimals={2}
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "currentEv",
    header: "Current EV",
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "evAsFundReturner",
    header: "EV Fund Returner",
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "unrealisedNav",
    header: ({ header, table }) => <HeaderWithTotals header={header} table={table} text="Unrealised NAV" />,
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "realisedNav",
    header: "Realised NAV",
    cell: (info) => (
      <RowWithCurrency
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
  {
    accessorKey: "vc",
    header: "V/C",
    cell: (info) => (
      <RowWithMultiplier
        label={info.renderValue()}
        containerClassName={"w-full flex justify-center items-center text-lg"}
      />
    ),
  },
];
