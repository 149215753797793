import React from "react";

import { differenceInDays, format, isValid } from "date-fns";

import { Popover } from "@/app/components";
import { normaliseDate, timeAgo } from "@/app/misc/helpers.ts";

export const CommentDate = ({ date }: { date: string }) => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }

  return (
    <Popover containerClassName={"outline-none"} childrenContent={format(new Date(date), "dd LLL YYY HH:mm")}>
      <span className="ml-2 text-xs font-semibold text-gray-500 sm:text-xxs sm:font-light md:text-xs md:font-semibold">
        {differenceInDays(new Date(), new Date(date)) < 1 ? timeAgo(date) : normaliseDate(date)}
      </span>
    </Popover>
  );
};

export const EditedDate = ({ date }: { date?: string }) => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }

  return (
    <Popover
      containerClassName={"outline-none"}
      childrenContent={
        differenceInDays(new Date(), new Date(date)) < 1 ? timeAgo(date) : format(new Date(date), "dd LLL YYY HH:mm")
      }
    >
      <span className="ml-1 text-xs font-semibold text-gray-500 sm:text-xxs">(edited)</span>
    </Popover>
  );
};
