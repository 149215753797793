import React, { memo, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { Button, Icon, Toast } from "@/app/components";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { ConfirmModal } from "@/app/screens/modal";
import { EmptySection, SectionTitle } from "@/app/screens/opportunities/opportunity/components";
import {
  CompanySignalSchema,
  NetworkSignalEdit,
} from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal-edit.tsx";
import { NetworkSignalData } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal.types.ts";
import { Section } from "@/app/screens/opportunities/opportunity/components/section.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import {
  NetworkSignalsByCompanyDocument,
  useCreateNetworkSignalMutation,
  useDeleteNetworkSignalMutation,
  useNetworkSignalsByCompanyQuery,
  useUpdateNetworkSignalMutation,
} from "@/app/service/network-signal.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { NetworkSignal } from "./network-signal.tsx";

export const NetworkSignals = memo(function NetworkSignals() {
  const { company, networkSignals } = useOpportunityContext();
  const { signals, loading } = networkSignals ?? { signals: [], loading: false };

  const [createNetworkSignal, { loading: isBeingCreated }] = useCreateNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });
  const [updateNetworkSignal, { loading: isBeingUpdated }] = useUpdateNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });
  const [deleteNetworkSignal, { loading: isBeingDeleted }] = useDeleteNetworkSignalMutation({
    refetchQueries: [NetworkSignalsByCompanyDocument],
  });

  const [mode, setMode] = useState<"view" | "edit" | "create">("view");
  const [signal, setSignal] = useState<NetworkSignalData | null>(null);

  const handleEditMode = (signal: NetworkSignalData) => {
    setMode("edit");
    setSignal(signal);
  };

  const handleCreate = async (data: CompanySignalSchema) => {
    if (!company) {
      return;
    }

    await createNetworkSignal({
      variables: {
        signal: {
          companyId: company.id,
          sourceType: data.sourceType,
          // todo save whole object?
          sourceName: typeof data.sourceName === "string" ? data.sourceName : data.sourceName.email,
          attributes: {
            canIntro: data.introAvailable,
            highPriority: data.highPriority,
            isActivelyFundraising: data.activelyRaising,
            isDeepTech: data.isDeepTech,
          },
          note: data.note,
        },
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle="Source has been created"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));

    setMode("view");
  };

  const handleEdit = async (data: CompanySignalSchema) => {
    if (!signal) {
      return;
    }

    await updateNetworkSignal({
      variables: {
        signalId: signal.id,
        data: {
          sourceType: data.sourceType,
          // todo save whole object?
          sourceName: typeof data.sourceName === "string" ? data.sourceName : data.sourceName.email,
          attributes: {
            canIntro: data.introAvailable,
            highPriority: data.highPriority,
            isActivelyFundraising: data.activelyRaising,
            isDeepTech: data.isDeepTech,
          },
          note: data.note,
        },
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle="Source has been updated"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));
    setMode("view");
  };

  const handleRemove = (signal: NetworkSignalData) => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will remove this source`,
        handleConfirm: async () => {
          await deleteNetworkSignal({
            variables: {
              signalId: signal.id,
            },
          });

          toast.custom((t) => (
            <Toast
              title="Success!"
              visible={t.visible}
              icon={<Icon type={"FillCheck"} className="text-green-500" />}
              subTitle="Source has been deleted"
              handleClose={() => toast.dismiss(t.id)}
            />
          ));
          modalStore.close();
        },
      },
    });
  };

  const isIncomplete = company?.status === "LEGACY" ? false : !loading && signals.length === 0;

  return (
    <Section id="signals" className="mt-6">
      <SectionTitle
        title="Sources"
        actions={
          mode === "view" && (
            <div className="flex items-center gap-2">
              {isIncomplete && <ChipV2 label="incomplete" color="orange" />}
              {!!signals.length && (
                <Button
                  size="sm"
                  variant="outline"
                  text="Add source"
                  onClick={() => {
                    setMode("create");
                  }}
                />
              )}
            </div>
          )
        }
      />
      {mode === "create" && (
        <NetworkSignalEdit onSubmit={handleCreate} onClose={() => setMode("view")} loading={isBeingCreated} />
      )}
      {mode === "edit" && !!signal && (
        <NetworkSignalEdit
          signal={signal}
          onSubmit={handleEdit}
          onClose={() => setMode("view")}
          loading={isBeingUpdated}
        />
      )}
      <ul className={cn("grid grid-cols-1 gap-4", ["edit", "create"].includes(mode) && "hidden")}>
        <AnimatePresence>
          {!signals.length && (
            <EmptySection
              className="col-span-2"
              text={"No source"}
              Action={
                <Button
                  size="sm"
                  className="mt-4"
                  variant="outline"
                  text="Add source"
                  onClick={() => {
                    setMode("create");
                  }}
                />
              }
            />
          )}
          {signals.map((signal) => (
            <motion.li
              key={signal.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <NetworkSignal key={signal.id} signal={signal} onEdit={handleEditMode} onRemove={handleRemove} />
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </Section>
  );
});
