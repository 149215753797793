import { PropsWithChildren } from "react";

import { flexRender } from "@tanstack/react-table";

import { cn } from "@/lib/utils.ts";

import { getColumnStyles, useTable, useTableDensity } from "@/app/components/table-new/table.hooks.ts";

import { ProgressSpinner } from "../../loading/progress-spinner.tsx";

import { Cell } from "./table-cell.tsx";
import { Row } from "./table-row.tsx";

export const Body = (props: PropsWithChildren<{ className?: string; loading?: boolean }>) => {
  const tableProps = useTable();
  const { config } = useTableDensity();

  if ("children" in props) {
    return <tbody className={props.className}>{props.children}</tbody>;
  }

  const { table, options } = tableProps;
  const { className, loading } = props;
  const noResults = table?.getRowModel().rows.length === 0;
  if (loading) {
    return <ProgressSpinner className="absolute right-1/2 top-1/2 ml-1" />;
  }

  if (noResults)
    return (
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-neutral-300">
        No results found
      </span>
    );

  return (
    <tbody className={cn("relative w-full", noResults && "h-20", className, config.body)}>
      {table?.getRowModel().rows.map((row, index, array) => {
        return (
          <Row
            key={row.id}
            className={cn(
              index === array.length - 1 ? "rounded-b-sm" : "",
              options?.onRowClick && "cursor-pointer",
              options?.elementOptions?.row?.className,
              options?.elementOptions?.row?.selectedIndex === index && options?.elementOptions.row.selectedClassName,
            )}
            onClick={() => {
              options?.onRowClick?.(row.original);
            }}
          >
            {row.getVisibleCells().map((cell, idx, arr) => (
              <Cell
                key={cell.id}
                className={cn(
                  cell.column.columnDef.meta?.className,
                  cell.column.columnDef.meta?.className,
                  index === array.length - 1 && idx === 0 ? "rounded-bl-sm" : "",
                  index === array.length - 1 && idx === arr.length - 1 ? "rounded-br-sm" : "",
                )}
                extraStyles={getColumnStyles(cell)}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Cell>
            ))}
          </Row>
        );
      })}
    </tbody>
  );
};
