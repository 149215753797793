import React from "react";

import { Editor } from "@tiptap/react";
import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { Button, Card } from "@/app/components";
import { RichTextEditor } from "@/app/components/rich-text-editor";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import {
  AddCompanyCommentMutationVariables,
  GetCompanyCommentsDocument,
  useAddCompanyCommentMutation,
} from "@/app/service/company-comments.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

const useAddComment = () => {
  const { company } = useOpportunityContext();

  const [submit, { loading }] = useAddCompanyCommentMutation({
    refetchQueries: [GetCompanyCommentsDocument],
  });

  const handleSubmit = (data: Omit<AddCompanyCommentMutationVariables, "companyId">) => {
    if (!company?.id) {
      throw new Error("No company provided instance");
    }

    return submit({
      variables: {
        companyId: company.id,
        ...data,
      },
    });
  };

  return { handleSubmit, loading };
};

export const CommentInputActions = ({ editor }: { editor?: Editor }) => {
  const { handleSubmit: submit, loading } = useAddComment();

  const handleSubmit = () => {
    if (!editor) {
      throw new Error("No editor instance");
    }

    submit({
      text: editor.getText(),
      metadata: {
        source: "tiptap",
        data: editor.getJSON(),
        html: editor.getHTML(),
      },
    }).then(() => {
      toast.success("Comment added successfully.");
      editor.commands.clearContent();
    });
  };

  const disableSendButton = loading || editor?.isEmpty;

  return (
    <Button
      className={cn(
        "absolute bottom-0 right-0",
        "min-w-fit",
        "transition-all duration-200 ease-in-out hover:scale-[1.02]",
      )}
      size={"sm"}
      variant={"secondary"}
      text={"Post"}
      iconLeft={disableSendButton ? "PaperPlane" : "PaperPlaneGradient"}
      disabled={disableSendButton}
      onClick={handleSubmit}
      loading={loading}
    />
  );
};

export const AddCommentInput = () => {
  const currentUser = authStore.user?.firstName;

  const editorProps = {
    attributes: {
      // padding-bottom is needed since we have a send button inside the text area
      class: "p-0 text-sm font-medium min-h-[100px] pb-14",
    },
  };

  return (
    <Card className="relative p-0">
      <RichTextEditor
        editorProps={editorProps}
        placeholder={currentUser ? `What's on your mind, ${currentUser}?` : "Write a comment..."}
      >
        <CommentInputActions />
      </RichTextEditor>
    </Card>
  );
};
