import { useEffect, useState } from "react";

import {
  CompanyFoundersSchema,
  useCompanyDataForm,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import { CompanyChangeData } from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";
import { DealroomCompanyQuery, useDealroomCompanyLazyQuery } from "@/app/service/dealroom-company.gql.ts";
import { useScrapeRequestMutation } from "@/app/service/opportunities.gql.ts";
import * as Sentry from "@sentry/react";

export type ScrapeDataType = {
  name?: string | null;
  websiteUrl?: string | null;
  image?: string | null;
};

function parseScrapeData(
  scrapeData?: {
    scrapeRequest?: {
      metadata?: {
        name?: string;
        ogName?: string;
        siteName?: string;
        ogSiteName?: string;
        title?: string;
        ogTitle?: string;
        favicon?: string;
        ogUrl?: string;
        ogImage?: string;
        url?: string;
      };
    };
  } | null,
): ScrapeDataType | null {
  if (!scrapeData) {
    return null;
  }

  const { metadata } = scrapeData.scrapeRequest ?? {};

  const name =
    metadata?.name ??
    metadata?.ogName ??
    metadata?.siteName ??
    metadata?.ogSiteName ??
    metadata?.title ??
    metadata?.ogTitle ??
    undefined;
  const image = metadata?.favicon ?? metadata?.ogImage ?? undefined;
  const websiteUrl = metadata?.ogUrl ?? metadata?.url ?? undefined;

  return {
    name,
    image,
    websiteUrl,
  };
}

const useScrapeCompany = () => {
  const [scrape] = useScrapeRequestMutation();
  const [scrapeData, setScrapeData] = useState<ScrapeDataType | null>(null);

  const scrapeWebsite = (url: string) => {
    scrape({
      variables: {
        scrapeInput: {
          url,
        },
      },
    })
      .then(({ data }) => {
        const parsed = parseScrapeData(data);
        setScrapeData(parsed);
      })
      .catch((e) => {
        // silently capture exception by sentry without showing an error toast
        Sentry.captureException(e);
      });
  };

  return {
    scrapeWebsite,
    scrapeData,
  };
};

export function useCompanyData() {
  const { form, founders } = useCompanyDataForm();

  const { setValue, reset } = form;

  const [getDealroomCompany, { loading: dealroomDataLoading }] = useDealroomCompanyLazyQuery();
  const [selectedDealroomCompany, setSelectedDealroomCompany] = useState<DealroomCompanyQuery["dealroomCompany"]>();

  const { scrapeWebsite, scrapeData } = useScrapeCompany();

  useEffect(() => {
    if (!scrapeData) {
      return;
    } else if (selectedDealroomCompany) {
      return;
    } else {
      const { name, websiteUrl, image } = scrapeData;

      if (!form.getValues("name")) {
        setValue("name", name, { shouldValidate: true, shouldDirty: true });
      }
      setValue("websiteUrl", websiteUrl, { shouldValidate: true, shouldDirty: true });
      setValue("image", image, { shouldValidate: true, shouldDirty: true });
      setValue("scrapeData", { name, image, websiteUrl }, { shouldValidate: true, shouldDirty: true });
    }
  }, [scrapeData]);

  const handleSelectCompanyByName = (data?: CompanyChangeData) => {
    if (typeof data === "string") {
      setValue("name", data, { shouldValidate: true, shouldDirty: true });
      setSelectedDealroomCompany(null);
    } else if (data && data.source === "dealroom") {
      setValue("name", data.name ?? undefined, { shouldValidate: true, shouldDirty: true });
      setValue("websiteUrl", data.website, { shouldValidate: true, shouldDirty: true });
      setValue("dealroomCompany", data.id, { shouldValidate: true, shouldDirty: true });

      getDealroomCompany({
        variables: {
          companyId: data.id,
          includeFounders: true,
          includeFunding: true,
        },
      }).then(({ data: res }) => {
        const dealroomCompany = res?.dealroomCompany;
        if (!dealroomCompany) {
          return;
        }

        setSelectedDealroomCompany(dealroomCompany);

        const founders = dealroomCompany?.founders?.nodes ?? [];
        setValue(
          "founders",
          founders.length
            ? founders
                ?.filter((founder) => !!founder.member)
                .map<NonNullable<CompanyFoundersSchema>[number]>((founder) => ({
                  dealroomFounder: {
                    id: founder.member!.id,
                    name: founder.member!.name ?? "",
                    image: founder.member?.image,
                    linkedinUrl: founder.member!.linkedinUrl,
                    titles: founder.titles,
                  },
                }))
            : [{}],
        );
      });
    } else {
      setSelectedDealroomCompany(null);
      reset({ name: null, websiteUrl: null, founders: [{}], dealroomCompany: null });
    }
  };

  return {
    form,
    founders,
    scrapeWebsite,
    scrapeData,
    selectedDealroomCompany,
    dealroomDataLoading,
    handleSelectCompanyByName,
  };
}

export function isDealroomResult(
  paramCompany: DealroomCompanyQuery["dealroomCompany"] | ScrapeDataType,
): paramCompany is DealroomCompanyQuery["dealroomCompany"] {
  return paramCompany?.["__typeName"] === "DealroomCompany";
}
