import Sheet from "react-modal-sheet";

import { Button, IosTransitionEnter } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

export const OpportunityActionListDialog = ({ company }) => {
  const inTrello = company?.is_tr_company;

  return (
    <IosTransitionEnter type="static">
      <div className={"flex h-full grow flex-col items-center rounded-t-md"}>
        <div className="p-4">
          <div className="h-[3px] w-6 rounded-lg bg-gray-500" />
        </div>
        <Sheet.Content className={"mx-5 flex size-full justify-between rounded-t-md"}>
          <div className={"mb-2 ml-5 flex w-full flex-col"}>
            <div className="flex flex-col justify-start">
              {!inTrello && (
                <>
                  <Button
                    text={"Add to Trello"}
                    variant="secondary"
                    iconLeft={"Trello"}
                    className="!justify-start"
                    size={"lg"}
                    onClick={() => {
                      globalModalStore.navigate({
                        modalType: "OpportunityAddTrello",
                        navState: {
                          label: "Add Trello",
                        },
                      });
                    }}
                  />
                  <Button
                    text={"Share"}
                    size={"lg"}
                    variant="secondary"
                    className="!justify-start"
                    iconLeft={"Share"}
                    onClick={() =>
                      globalModalStore.navigate({
                        modalType: "OpportunityShare",
                        navState: {
                          label: "Share with",
                        },
                      })
                    }
                  />
                  <Button
                    text={"Move to Pipeline"}
                    size={"lg"}
                    variant="secondary"
                    className="!justify-start"
                    iconLeft={"Snooze"}
                    onClick={() =>
                      globalModalStore.navigate({
                        modalType: "OpportunitySnooze",
                        navState: {
                          label: "Snooze",
                        },
                      })
                    }
                  />
                </>
              )}
              <Button
                text={"Sentiment"}
                size={"lg"}
                variant="secondary"
                className="!justify-start"
                iconLeft={"Add"}
                onClick={() =>
                  globalModalStore.navigate({
                    modalType: "AddNew",
                    navState: { useCustomLoader: true, sentimentOnly: true },
                  })
                }
              />
            </div>
          </div>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
};
