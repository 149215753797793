import { cn } from "@/lib/utils.ts";

import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";

export const EmptySection = ({
  text,
  Action,
  className,
}: {
  text: string;
  className?: string;
  Action?: React.ReactNode;
}) => {
  return (
    <section
      className={cn(
        "flex h-[200px] w-full flex-col items-center justify-center bg-white",
        sectionBorderRadiusClassName,
        className,
      )}
    >
      <p className="text-xl font-semibold text-gray-300">{text}</p>
      {Action ? Action : null}
    </section>
  );
};
