import React from "react";

import {
  HighlightsCard,
  HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { CompanyDataForHighlights } from "@/app/screens/opportunities/opportunity/components/highlights/highlights.types.ts";

export const getCashoutGroup = (val: number, breakeven: boolean) => {
  if (breakeven) {
    return "Breakeven";
  }

  if (val <= 12) {
    return "< 12 months";
  }
  if (val > 12 && val <= 18) {
    return "12 - 18 months";
  }
  if (val > 18 && val < 36) {
    return "18 - 36 months";
  }
  if (val >= 36) {
    return "> 36 months";
  }
};

export const CashProfile = ({ company }: { company: CompanyDataForHighlights }) => {
  const { relativeRunway, lastCalculatedUtc, employeesLatest, lastMetric } = company;
  const cashoutGroup = getCashoutGroup(
    lastMetric?.data?.runwayInMonths?.value,
    lastMetric?.data?.fullyFundedToBreakeven?.value,
  );

  const runway =
    cashoutGroup || relativeRunway
      ? String(cashoutGroup || relativeRunway)
          .replace("months", "m")
          .replace(/\s+/g, "")
      : undefined;
  const runwayDate = cashoutGroup ? lastMetric?.data?.runwayInMonths?.date : lastCalculatedUtc;

  const FTEMetric = lastMetric?.data?.FTE;
  const FTE = FTEMetric?.value || employeesLatest;
  const FTEDate = FTEMetric?.date || lastCalculatedUtc;

  return (
    <HighlightsCard className={"group flex"}>
      <HighlightsCardBody className={"w-1/2"}>
        {runway != null ? (
          <>
            <HighlightsCardTitle text={"Runway"} />
            <div className={"flex h-28 items-center justify-center"}>
              <HighlightsCardNumber value={runway} animate={false} />
            </div>
            <HighlightsCardFooter>
              <HighlightsCardLastUpdated date={runwayDate} />
            </HighlightsCardFooter>
          </>
        ) : (
          <HighlightsCardEmptyState text={"No Runway Available"} />
        )}
      </HighlightsCardBody>
      <HighlightsCardBody className={"w-1/2"}>
        {FTE != null ? (
          <>
            <HighlightsCardTitle text={"FTEs"} />
            <div className={"flex h-28 items-center justify-center"}>
              <HighlightsCardNumber value={FTE} animate={false} />
            </div>
            <HighlightsCardFooter>
              <HighlightsCardLastUpdated date={FTEDate} />
            </HighlightsCardFooter>
          </>
        ) : (
          <HighlightsCardEmptyState text={"No FTE Available"} />
        )}
      </HighlightsCardBody>
    </HighlightsCard>
  );
};
