import React from "react";

import {
  HighlightsCard, HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle
} from "./highlights-card.tsx";
import { CompanyDataForHighlights } from "./highlights.types.ts";

type EvReturnCardProps = {
  company: CompanyDataForHighlights;
  className?: string;
};

const useEvReturn = (company: CompanyDataForHighlights) => {
  const data = company?.mtpCompanyfundsByMtpCompanyId
    ? company.mtpCompanyfundsByMtpCompanyId.nodes[company?.mtpCompanyfundsByMtpCompanyId.nodes.length - 1]
    : null;
  const evReturn = data?.evReturnFund && !isNaN(+data?.evReturnFund) ? +data?.evReturnFund / 1e6 : null;

  return {
    evReturn,
    lastUpdated: "2024-06-05", // todo change me
  };
};

export const EvReturnCard = ({ company, className }: EvReturnCardProps) => {
  const { evReturn, lastUpdated } = useEvReturn(company);

  if (!company.isOpCompany) {
    return null;
  }

  return (
    <HighlightsCard className={className}>
      {evReturn !== null ? (
        <HighlightsCardBody>
          <HighlightsCardTitle text={"EV to return LG"} />

          <div className="flex items-center justify-center">
            <HighlightsCardNumber
              value={evReturn}
              prefix={<span className="text-currency mr-2">£</span>}
              suffix="m"
              options={{ decimals: 2 }}
            />
          </div>

          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={lastUpdated} />
          </HighlightsCardFooter>
        </HighlightsCardBody>
      ) : (
        <HighlightsCardEmptyState text={"No EV Data"} />
      )}
    </HighlightsCard>
  );
};
