import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCompanyFoundersQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"]["input"];
}>;

export type GetCompanyFoundersQuery = {
  __typename?: "Query";
  lgCompanyTeams?: {
    __typename?: "LgCompanyTeamsConnection";
    nodes: Array<{
      __typename?: "LgCompanyTeam";
      id: number;
      titles?: any | null;
      member?: {
        __typename?: "LgFounder";
        id: any;
        name?: string | null;
        image?: string | null;
        linkedinUrl?: string | null;
        forms: {
          __typename?: "NzrSentimentFormsConnection";
          nodes: Array<{
            __typename?: "NzrSentimentForm";
            id: string;
            analytics?: any | null;
            category?: string | null;
            companyId?: any | null;
            companyName?: string | null;
            createdAt?: any | null;
            createdById?: any | null;
            expiresAt?: any | null;
            fields?: any | null;
            formStatus?: string | null;
            responses?: any | null;
            sentimentSummary?: string | null;
          }>;
        };
        founderSources: {
          __typename?: "SrcFoundersConnection";
          nodes: Array<{ __typename?: "SrcFounder"; sourceType: string; sourceId: string; lastUpdatedUtc: any }>;
        };
      } | null;
    }>;
  } | null;
};

export const GetCompanyFoundersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyFounders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanyTeams" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isFounder" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "titles" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "member" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "forms" },
                              name: { kind: "Name", value: "nzrSentimentFormsByFounderId" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "NzrSentimentFormFrag" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "founderSources" },
                              name: { kind: "Name", value: "srcFoundersByEntityId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                                        { kind: "Field", name: { kind: "Name", value: "sourceId" } },
                                        { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyFoundersQuery__
 *
 * To run a query within a React component, call `useGetCompanyFoundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyFoundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyFoundersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyFoundersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables> &
    ({ variables: GetCompanyFoundersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>(
    GetCompanyFoundersDocument,
    options,
  );
}
export function useGetCompanyFoundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>(
    GetCompanyFoundersDocument,
    options,
  );
}
export function useGetCompanyFoundersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>(
    GetCompanyFoundersDocument,
    options,
  );
}
export type GetCompanyFoundersQueryHookResult = ReturnType<typeof useGetCompanyFoundersQuery>;
export type GetCompanyFoundersLazyQueryHookResult = ReturnType<typeof useGetCompanyFoundersLazyQuery>;
export type GetCompanyFoundersSuspenseQueryHookResult = ReturnType<typeof useGetCompanyFoundersSuspenseQuery>;
export type GetCompanyFoundersQueryResult = Apollo.QueryResult<
  GetCompanyFoundersQuery,
  GetCompanyFoundersQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
