import { forwardRef } from "react";

import { cn } from "@/lib/utils.ts";

import { colors } from "@/app/constants";

type LabelColor = "neutral" | "success" | "warning" | "error";

const labelStyleMap: (outline: boolean) => Record<
  LabelColor,
  {
    color: `text-${keyof typeof colors}` | `text-${keyof typeof colors}-${number}`;
    bgColor: `${"bg" | "border"}-${keyof typeof colors}` | `${"bg" | "border"}-${keyof typeof colors}-${number}`;
  }
> = (outline) => ({
  neutral: {
    color: "text-neutral-500",
    bgColor: outline ? "border-gray-300" : "bg-gray-100",
  },
  success: {
    color: "text-green-700",
    bgColor: outline ? "border-green-200" : "bg-green-200",
  },
  warning: {
    color: "text-orange-700",
    bgColor: outline ? "border-orange-200" : "bg-orange-200",
  },
  error: {
    color: "text-red-600",
    bgColor: outline ? "border-red-200" : "bg-red-200",
  },
});

interface LabelProps {
  description: string;
  outline?: boolean;
  type?: LabelColor;
  variant?: "sm" | "lg";
  onClick?: () => void;
  containerClassName?: string;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(function Label(
  { description, type = "neutral", outline = false, onClick, containerClassName, variant }: LabelProps,
  ref,
) {
  const { bgColor, color } = labelStyleMap(Boolean(outline))[type];

  return (
    <label
      className={cn(
        "flex size-fit items-center justify-center rounded-[2px]",
        bgColor,
        color,
        variant === "sm" ? "px-2 py-1" : "rounded-xxs px-3 py-2",
        containerClassName,
      )}
      onClick={onClick}
      ref={ref}
    >
      <span className={cn("whitespace-nowrap text-xs font-medium lg:text-sm")}>{description}</span>
    </label>
  );
});
