import { forwardRef, useEffect, useMemo, useRef } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType, Label } from "@/app/components";
import { TW_INPUT_CORE, TW_INPUT_CORE_FOCUS } from "@/app/components/input";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";

type InputWithPillsProps = {
  children?: React.ReactNode;
  inputPosition?: "right" | "left";
  inputFocus?: boolean;
  iconLeft?: IconType;
  containerClassName?: string;
  placeholder?: string;
  selectedOption: OptionsType | Array<OptionsType>;
  disableFocus?: boolean;
  canDelete?: boolean;
  setSelectedOption?: (args?: OptionsType | Array<OptionsType>) => void;
  disabled?: boolean;
  onClick?: () => void;
};

export const InputWithPills = forwardRef<React.ReactNode, InputWithPillsProps>(
  (
    {
      children,
      iconLeft,
      containerClassName = "",
      selectedOption,
      setSelectedOption,
      inputPosition = "right",
      disableFocus = false,
      disabled = false,
      canDelete = true,
      placeholder,
      onClick,
    }: InputWithPillsProps,
    ref,
  ) => {
    const scrollIntoViewLastElement = useRef<HTMLLabelElement>(null);

    useEffect(() => {
      if (scrollIntoViewLastElement?.current && !disableFocus) {
        scrollIntoViewLastElement?.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, [selectedOption, disableFocus]);

    const values = useMemo(() => {
      if (Array.isArray(selectedOption)) {
        return selectedOption.filter(Boolean).map((e, i) => (
          <Label
            key={e?.label + i}
            description={e?.label}
            onClick={() => {
              const filteredOptions = selectedOption.filter((option) => option.value !== e.value);
              setSelectedOption?.(filteredOptions);
            }}
            iconRight={canDelete ? "X" : undefined}
            containerClassName={"select-none cursor-pointer"}
            ref={selectedOption.length - 1 === i ? scrollIntoViewLastElement : null}
          />
        ));
      }

      if (selectedOption?.label) {
        return (
          <Label
            description={selectedOption?.label}
            containerClassName={"select-none cursor-pointer"}
            onClick={() => setSelectedOption?.(undefined)}
            iconRight={canDelete ? "X" : undefined}
          />
        );
      }

      if (placeholder)
        return (
          <span
            className={"select-none text-sm font-light text-neutral-400"}
            onClick={() => setSelectedOption?.(undefined)}
          >
            {placeholder}
          </span>
        );
    }, [canDelete, placeholder, selectedOption, setSelectedOption]);

    return (
      <button
        disabled={disabled}
        className={cn(
          `${TW_INPUT_CORE} ${TW_INPUT_CORE_FOCUS} h-[47px] cursor-pointer items-center px-4 ${containerClassName}`,
          {
            "text-neutral-300": disabled,
          },
        )}
        onClick={() => {
          if ((ref as React.RefObject<HTMLInputElement>)?.current)
            (ref as React.RefObject<HTMLInputElement>)?.current?.focus();

          onClick?.();
        }}
      >
        {iconLeft && (
          <div>
            <Icon type={iconLeft} className={"z-5 mx-2 size-4 text-primary lg:ml-0 lg:size-5"} />
          </div>
        )}
        {inputPosition === "left" && children}
        <div
          className={"no-scrollbar z-10 flex space-x-0.5 overflow-auto lg:space-x-2"}
          onClick={(e) => e.stopPropagation()}
        >
          {values}
        </div>
        {inputPosition === "right" && children}
      </button>
    );
  },
);
