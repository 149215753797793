import { EngagementOverview } from "../engagement-overview";

import { CardBox } from "./card-box";

export const CardEngagement = ({
  engagement,
  previouslyMet,
  isPortco,
  companyId,
}: {
  companyId: string;
  engagement?: {
    totalComments?: number | null;
    totalAttachments?: number | null;
    hasNoRecentComments?: boolean | null;
    hasNoRecentAttachments?: boolean | null;
  } | null;
  previouslyMet?: boolean;
  isPortco?: boolean;
}) => {
  const isEmpty = engagement?.totalAttachments === 0 && engagement?.totalComments === 0;
  const isOld = (engagement?.hasNoRecentComments ?? false) && (engagement?.hasNoRecentAttachments ?? false);

  return (
    <CardBox
      old={isOld}
      title="Engagement"
      isSad={isEmpty && (previouslyMet || isPortco)}
      titleClassName="mb-1 2xl:mb-3 lg:mb-1.5"
      containerClassName="mr-4 ml-2 md:mr-0"
    >
      <EngagementOverview
        companyId={companyId}
        engagement={engagement}
      />
    </CardBox>
  );
};
