import React, { ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "@/app/components";
import { isIconType } from "@/app/components/icon";

const colors = {
  orange: "bg-orange-200 text-orange-700",
  green: "bg-green-200 text-green-700",
  volcano: "bg-volcano-200 text-volcano-600",
  purple: "bg-purple-200 text-purple-500",
  cyan: "bg-cyan-100 text-cyan-600",
  "day-blue": "bg-day-blue-200 text-day-blue-500",
  "geek-blue": "bg-geek-blue-200 text-geek-blue-500",
  magenta: "bg-magenta-300 text-magenta-500",
  gray: "bg-gray-300 text-gray-600",
};

export type ChipV2Props = {
  label: string;
  color: keyof typeof colors;
  className?: string;
  iconLeft?: IconType | ReactNode;
  iconRight?: IconType | ReactNode;
};

export const ChipV2 = ({ label, color, className, iconRight, iconLeft }: ChipV2Props) => {
  return (
    <div
      className={cn(
        "flex h-6 items-center gap-0.5 whitespace-nowrap rounded-[2px] px-2 text-xs",
        colors[color],
        className,
      )}
    >
      {isIconType(iconLeft) ? <Icon type={iconLeft} className="size-3" /> : iconLeft}
      {label}
      {isIconType(iconRight) ? <Icon type={iconRight} className="size-3" /> : iconRight}
    </div>
  );
};
