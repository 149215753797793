import { FunctionComponent, memo } from "react";

import { Database, FolderOpen, HomeIcon, LucideIcon, UserIcon } from "lucide-react";

import { Bell, Investments, MeetThePortfolioIcon, Network, Opportunity } from "@/app/components/icon/icon.tsx";

type Routes =
  | "portfolio"
  | "prospects"
  | "pipeline"
  | "investments"
  | "inbox"
  | "network"
  | "settings"
  | "home"
  | "fund-overview";

type SidebarItem = {
  name: string;
  current: boolean;
  path: Routes;
  Icon: LucideIcon | FunctionComponent;
  disabled?: boolean;
};

// all tabs
const allTabs: Record<string, SidebarItem> = {
  inbox: {
    name: "Inbox",
    current: false,
    Icon: memo(Bell),
    path: "inbox",
  },
  portfolio: {
    name: "Portfolio",
    current: false,
    Icon: memo(FolderOpen),
    path: "portfolio",
  },

  prospects: {
    name: "Prospects",
    current: false,
    Icon: memo(Database),
    path: "prospects",
  },
  pipeline: {
    name: "Pipeline",
    current: false,
    Icon: memo(Opportunity),
    path: "pipeline",
  },
  network: {
    name: "Network",
    current: false,
    Icon: memo(Network),
    path: "network",
  },
  investments: {
    name: "Investments",
    current: false,
    Icon: memo(Investments),
    path: "investments",
  },
  fundOverview: {
    name: "Fund Overview",
    current: false,
    Icon: memo(MeetThePortfolioIcon),
    path: "fund-overview",
    // webOnly: true,
  },
  settings: {
    name: "Settings",
    current: false,
    Icon: UserIcon,
    path: "settings",
  },
  home: {
    name: "Home",
    current: true,
    Icon: HomeIcon,
    path: "home",
  },
};

export const tabsDesktop: Array<SidebarItem[]> = [
  [allTabs.home],
  [allTabs.network, allTabs.portfolio, allTabs.prospects, allTabs.pipeline],
  [allTabs.investments, allTabs.fundOverview],
];

export const tabsMobile: Array<SidebarItem[]> = [
  [allTabs.home],
  [allTabs.network, allTabs.portfolio, allTabs.prospects, allTabs.pipeline],
  [allTabs.fundOverview],
];
