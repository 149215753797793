import React, { createContext, Dispatch, useContext, useState } from "react";

export enum SentimentTab {
  Chart = "chart",
  Table = "table",
  Bar = "bar",
}

export enum SentimentState {
  "initial",
  "loading",
  "has_data",
  "no_data",
}

type SentimentContextState = {
  activeTab: SentimentTab;
  setActiveTab: Dispatch<SentimentTab>;
  maximised: boolean;
  setMaximised: Dispatch<(value: boolean) => void | boolean>;
  state: SentimentState;
  setState: Dispatch<SentimentState>;
  dataAlignment: { value: DataAlignment; label: DataAlignment };
  setDataAlignment: Dispatch<{ value: DataAlignment; label: DataAlignment }>;
  dashboardSelector: { value: DashboardSelector; label: DashboardSelector };
  setDashboardSelector: Dispatch<{ value: DashboardSelector; label: DashboardSelector }>;
  fundSelected: { value: string; label: string };
  setFundSelected: Dispatch<{ value: string; label: string }>;
  benchmarkAttribute: { value: string; label: string };
  setBenchmarkAttribute: Dispatch<{ value: string; label: string }>;
};

type DataAlignment = "Normal" | "Left Align" | "Left-Right Align" | "Right Align" | string;
type DashboardSelector = "Time Series" | "Benchmark" | string;

const SentimentContext = createContext<SentimentContextState>(null as any);

export const SentimentContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState<SentimentTab>(SentimentTab.Chart);
  const [state, setState] = useState<SentimentState>(SentimentState.initial);
  const [maximised, setMaximised] = useState(false);
  const [dataAlignment, setDataAlignment] = useState<SentimentContextState["dataAlignment"]>({
    value: "Normal",
    label: "Normal",
  });
  const [dashboardSelector, setDashboardSelector] = useState<SentimentContextState["dashboardSelector"]>({
    value: "Time Series",
    label: "Time Series",
  });

  const [fundSelected, setFundSelected] = useState<SentimentContextState["fundSelected"]>({
    value: "LGX",
    label: "LocalGlobe 10",
  });

  const [benchmarkAttribute, setBenchmarkAttribute] = useState<SentimentContextState["benchmrkAttribute"]>({
    value: "lastSentiment",
    label: "lastSentiment",
  });

  const value: SentimentContextState = {
    activeTab,
    setActiveTab,
    state,
    setState,
    maximised,
    setMaximised,
    dataAlignment,
    setDataAlignment,
    dashboardSelector,
    setDashboardSelector,
    fundSelected,
    setFundSelected,
    benchmarkAttribute,
    setBenchmarkAttribute,
  };

  return <SentimentContext.Provider value={value}>{children}</SentimentContext.Provider>;
};

export const useSentimentContext = (): SentimentContextState => {
  const sentimentContext = useContext(SentimentContext);

  if (!sentimentContext) {
    throw new Error("Must be used inside SentimentContextProvider");
  }

  return sentimentContext;
};
