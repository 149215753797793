import { memo, useState } from "react";

import { CompanyDetailsEdit } from "./company-details-edit.tsx";
import { CompanyDetailsViewMode } from "./company-details-view.tsx";

export const CompanyDetails = memo(function CompanyDetails() {
  const [mode, setMode] = useState<"view" | "edit">("view");

  return (
    <div className="relative">
      {mode === "edit" ? (
        <CompanyDetailsEdit onClose={() => setMode("view")} />
      ) : (
        <CompanyDetailsViewMode onEdit={() => setMode("edit")} />
      )}
    </div>
  );
});
