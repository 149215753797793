import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NetworkSignalsByCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"]["input"];
}>;

export type NetworkSignalsByCompanyQuery = {
  __typename?: "Query";
  networkSignals?: {
    __typename?: "NetworkSignalsConnection";
    nodes: Array<{
      __typename?: "NetworkSignal";
      id: any;
      sourceType: string;
      sourceName: string;
      attributes: any;
      note?: string | null;
      createdUtc: any;
      lastUpdatedUtc?: any | null;
      lgMemberByCreatedBy?: {
        __typename?: "LgMember";
        id: any;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
      sourceEnriched?: { __typename?: "NetworkContactSimple"; email?: string | null; name?: string | null } | null;
    }>;
  } | null;
};

export type CreateNetworkSignalMutationVariables = Types.Exact<{
  signal: Types.CreateNetworkSignalDataInput;
}>;

export type CreateNetworkSignalMutation = {
  __typename?: "Mutation";
  createNetworkSignal?: { __typename?: "NetworkSignalResponse"; id: string } | null;
};

export type UpdateNetworkSignalMutationVariables = Types.Exact<{
  signalId: Types.Scalars["ID"]["input"];
  data: Types.UpdateNetworkSignalDataInput;
}>;

export type UpdateNetworkSignalMutation = {
  __typename?: "Mutation";
  updateNetworkSignal?: { __typename?: "NetworkSignalResponse"; id: string } | null;
};

export type DeleteNetworkSignalMutationVariables = Types.Exact<{
  signalId: Types.Scalars["ID"]["input"];
}>;

export type DeleteNetworkSignalMutation = { __typename?: "Mutation"; deleteNetworkSignal?: any | null };

export const NetworkSignalsByCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NetworkSignalsByCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "networkSignals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                      { kind: "Field", name: { kind: "Name", value: "sourceName" } },
                      { kind: "Field", name: { kind: "Name", value: "attributes" } },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lgMemberByCreatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                            { kind: "Field", name: { kind: "Name", value: "avatar" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceEnriched" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "email" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useNetworkSignalsByCompanyQuery__
 *
 * To run a query within a React component, call `useNetworkSignalsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkSignalsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkSignalsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useNetworkSignalsByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables> &
    ({ variables: NetworkSignalsByCompanyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables>(
    NetworkSignalsByCompanyDocument,
    options,
  );
}
export function useNetworkSignalsByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables>(
    NetworkSignalsByCompanyDocument,
    options,
  );
}
export function useNetworkSignalsByCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NetworkSignalsByCompanyQuery, NetworkSignalsByCompanyQueryVariables>(
    NetworkSignalsByCompanyDocument,
    options,
  );
}
export type NetworkSignalsByCompanyQueryHookResult = ReturnType<typeof useNetworkSignalsByCompanyQuery>;
export type NetworkSignalsByCompanyLazyQueryHookResult = ReturnType<typeof useNetworkSignalsByCompanyLazyQuery>;
export type NetworkSignalsByCompanySuspenseQueryHookResult = ReturnType<typeof useNetworkSignalsByCompanySuspenseQuery>;
export type NetworkSignalsByCompanyQueryResult = Apollo.QueryResult<
  NetworkSignalsByCompanyQuery,
  NetworkSignalsByCompanyQueryVariables
>;
export const CreateNetworkSignalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateNetworkSignal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "signal" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateNetworkSignalDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createNetworkSignal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "createNetworkSignalDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "signal" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateNetworkSignalMutationFn = Apollo.MutationFunction<
  CreateNetworkSignalMutation,
  CreateNetworkSignalMutationVariables
>;

/**
 * __useCreateNetworkSignalMutation__
 *
 * To run a mutation, you first call `useCreateNetworkSignalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkSignalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkSignalMutation, { data, loading, error }] = useCreateNetworkSignalMutation({
 *   variables: {
 *      signal: // value for 'signal'
 *   },
 * });
 */
export function useCreateNetworkSignalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNetworkSignalMutation, CreateNetworkSignalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNetworkSignalMutation, CreateNetworkSignalMutationVariables>(
    CreateNetworkSignalDocument,
    options,
  );
}
export type CreateNetworkSignalMutationHookResult = ReturnType<typeof useCreateNetworkSignalMutation>;
export type CreateNetworkSignalMutationResult = Apollo.MutationResult<CreateNetworkSignalMutation>;
export type CreateNetworkSignalMutationOptions = Apollo.BaseMutationOptions<
  CreateNetworkSignalMutation,
  CreateNetworkSignalMutationVariables
>;
export const UpdateNetworkSignalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateNetworkSignal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "signalId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateNetworkSignalDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateNetworkSignal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "signalId" },
                value: { kind: "Variable", name: { kind: "Name", value: "signalId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateNetworkSignalDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateNetworkSignalMutationFn = Apollo.MutationFunction<
  UpdateNetworkSignalMutation,
  UpdateNetworkSignalMutationVariables
>;

/**
 * __useUpdateNetworkSignalMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkSignalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkSignalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkSignalMutation, { data, loading, error }] = useUpdateNetworkSignalMutation({
 *   variables: {
 *      signalId: // value for 'signalId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNetworkSignalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNetworkSignalMutation, UpdateNetworkSignalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNetworkSignalMutation, UpdateNetworkSignalMutationVariables>(
    UpdateNetworkSignalDocument,
    options,
  );
}
export type UpdateNetworkSignalMutationHookResult = ReturnType<typeof useUpdateNetworkSignalMutation>;
export type UpdateNetworkSignalMutationResult = Apollo.MutationResult<UpdateNetworkSignalMutation>;
export type UpdateNetworkSignalMutationOptions = Apollo.BaseMutationOptions<
  UpdateNetworkSignalMutation,
  UpdateNetworkSignalMutationVariables
>;
export const DeleteNetworkSignalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteNetworkSignal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "signalId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteNetworkSignal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "signalId" },
                value: { kind: "Variable", name: { kind: "Name", value: "signalId" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteNetworkSignalMutationFn = Apollo.MutationFunction<
  DeleteNetworkSignalMutation,
  DeleteNetworkSignalMutationVariables
>;

/**
 * __useDeleteNetworkSignalMutation__
 *
 * To run a mutation, you first call `useDeleteNetworkSignalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNetworkSignalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNetworkSignalMutation, { data, loading, error }] = useDeleteNetworkSignalMutation({
 *   variables: {
 *      signalId: // value for 'signalId'
 *   },
 * });
 */
export function useDeleteNetworkSignalMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNetworkSignalMutation, DeleteNetworkSignalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNetworkSignalMutation, DeleteNetworkSignalMutationVariables>(
    DeleteNetworkSignalDocument,
    options,
  );
}
export type DeleteNetworkSignalMutationHookResult = ReturnType<typeof useDeleteNetworkSignalMutation>;
export type DeleteNetworkSignalMutationResult = Apollo.MutationResult<DeleteNetworkSignalMutation>;
export type DeleteNetworkSignalMutationOptions = Apollo.BaseMutationOptions<
  DeleteNetworkSignalMutation,
  DeleteNetworkSignalMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
