import { useMemo } from "react";

import { cn } from "@/lib/utils";

interface TooltipInfoProps {
  info?: boolean;
  warning?: boolean;
  alert?: boolean;
  success?: boolean;
  className?: string;
  text?: string;
  renderText?: JSX.Element;
}

export const TooltipInfo = ({ warning, info, alert, success, className, text, renderText }: TooltipInfoProps) => {
  const bgColor = useMemo(() => {
    if (info) {
      return "bg-[#E0DDFF]";
    }

    if (warning) {
      return "bg-[#FFE6B2]";
    }

    if (alert) {
      return "bg-[#FFE0E7]";
    }

    if (success) {
      return "bg-green-100";
    }

    return "";
  }, [info, warning, alert, success]);

  return (
    <div className={cn("max-w-96 rounded-[3px] p-0", bgColor, className)}>
      {text && <p className="text-xs font-light text-black">{text}</p>}
      {renderText}
    </div>
  );
};
