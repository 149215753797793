import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { contactsLookupQuery } from "./contactsLookupQuery.gql";
import { LookupHeader } from "./lookupHeader";
import {
  filtersFromStr,
  filtersToStr,
  filtersToStrGql,
  FilterType,
  FlagFilterKey,
  Column,
  SortDirection,
  sortPairValueFromString,
  sortPairValueToString,
} from "./columns";
import { Table, Contact } from "./lookupTable";

export function NetworkContactLookup() {
  const [params, setParams] = useSearchParams();
  const [debounceQ, setDebounceQ] = useState(params.get("q") || "");
  const selectedFilters = filtersFromStr(params.get("filter"));
  const [filters, setFilters] = useState(selectedFilters);
  const [sortPair, setSortPair] = useState<[Column, SortDirection]>(sortPairValueFromString(params.get("sort")));
  const [lookedUp, setLookedUp] = useState(!!params.size);
  const [lookup, { data, loading, previousData }] = useLazyQuery(contactsLookupQuery, {
    variables: { debounceQ, order: sortPairValueToString(...sortPair), filters: filtersToStrGql(filters) },
  });

  // NOTE: we gonna use baseQueryValue<Record> only
  // to override internal components with state
  // like <input />;
  //
  // This is only for non trivial situation,
  // like when we need to reset value to ""
  // when navigation to home screen
  const [baseQueryValue, setBaseQueryValue] = useState({ q: params.get("q") || "" });

  const navigate = useNavigate();

  useEffect(() => {
    if (!params.size) {
      setDebounceQ("");
      setLookedUp(false);
      setBaseQueryValue({ q: "" });
    }
  }, [params]);

  useEffect(() => {
    const sortPairString = sortPairValueToString(...sortPair);
    const filtersStringGql = filtersToStrGql(filters);

    if (debounceQ) {
      lookup({
        variables: { q: debounceQ, sort: sortPairString, filters: filtersStringGql },
      });
    }

    if (lookedUp) {
      const sortPairString = sortPairValueToString(...sortPair);
      const filtersString = filtersToStr(filters);
      const searchParams = new URLSearchParams(params);
      searchParams.set("q", debounceQ);
      searchParams.set("sort", sortPairString);
      searchParams.set("filter", filtersString);
      setParams(searchParams, { replace: true });
    }
  }, [debounceQ, sortPair, filters, lookedUp]);

  const handleQueryChange = useCallback((value: string) => {
    setLookedUp(true);
    setDebounceQ(value);
  }, []);

  // PERF: have one onClick handler for huge list instead of every element
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      // Perform sorting
      const nextPairString = event.target?.closest("[data-column-next-sort]")?.getAttribute("data-column-next-sort");
      if (nextPairString) {
        const nextPair = sortPairValueFromString(nextPairString);
        event.preventDefault();
        setSortPair(nextPair);
      }

      // Navigate to contact view
      const clickedContact = event.target?.closest("[data-contact-email]")?.getAttribute("data-contact-email");
      if (clickedContact) {
        event.preventDefault();
        const params: string = event.target.closest("[data-contact-view-search-params]")?.getAttribute("data-contact-view-search-params") || "";
        navigate(
          `/network/contact/${clickedContact}?${params}`
        );
      }
    }
  }, []);

  const handleEngagedClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    const val = !!event.currentTarget?.checked;
    const incomingFilters = new Map(filters);
    incomingFilters.set(FlagFilterKey.EngagedOnly, {
      key: FlagFilterKey.EngagedOnly,
      type: FilterType.Flag,
      value: val,
    });
    setFilters(incomingFilters);
  }, []);

  let contactsToDisplay: Contact[] = [];
  if (debounceQ?.length) {
    if (data) contactsToDisplay = data?.contacts?.nodes as Contact[];
    else if (loading && previousData) contactsToDisplay = previousData?.contacts?.nodes as Contact[];
  }
  
  const contactViewSearchParams = `fq=${debounceQ}&fsort=${sortPairValueToString(...sortPair)}&ffilter=${filtersToStr(filters)}`;

  return (
    <div className="@container font-barlow flex w-full max-w-[100%] h-width min-h-[100vh]">
      <div className="flex flex-col py-2 px-10 w-full" data-contact-view-search-params={contactViewSearchParams} onClick={handleClick}>
        <LookupHeader
          lookedUp={lookedUp}
          loading={loading}
          filters={filters}
          onEngagedClick={handleEngagedClick}
          onQueryChange={handleQueryChange}
          sortColumn={sortPair[0]}
          sortDirection={sortPair[1]}
          baseQueryValue={baseQueryValue}
        />
        {lookedUp ? <Table contacts={contactsToDisplay} sortColumn={sortPair[0]} sortDirection={sortPair[1]} loading={loading} /> : null}
      </div>
    </div>
  );
}
