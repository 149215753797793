import React, { useEffect, useState } from "react";

import { DealroomCompanyFilter, LgCompanyFilter } from "@/gql/graphql.ts";

import { cn, parseUrl } from "@/lib/utils.ts";

import { AvatarV2, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  Autocomplete,
  AutocompleteButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
} from "@/app/components/forms/autocomplete.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { useCompanySearchQuery } from "@/app/service/company.gql.ts";

type SearchField = keyof Pick<LgCompanyFilter | DealroomCompanyFilter, "name" | "websiteUrl">;

const useSearchResults = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useCompanySearchQuery({
    variables: {
      limit: 10,
      q: searchTerm,
    },
    skip: !searchTerm,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = data?.companySearchV2?.nodes ?? [];

  return { results, search, loading, searchTerm };
};

const CompanySearchInput = ({
  handleSearch,
  loading,
  selectedCompany,
  onClear,
  placeholder,
  value,
  disableClear,
}: {
  handleSearch: (val: string) => void;
  loading?: boolean;
  selectedCompany?: Pick<CompanySearchResult, "image"> | null;
  onClear?: () => void;
  placeholder?: string;
  value?: string | null;
  disableClear?: boolean;
}) => {
  const displayValue = (company: CompanySearchResult | string | null): string => {
    return typeof company === "string" ? company : company?.name ?? "";
  };

  return (
    <div className="group/select relative">
      {selectedCompany && (
        <AvatarV2
          src={typeof selectedCompany === "object" ? parseUrl(selectedCompany.image) : null}
          type="company"
          size="sm"
          className="absolute left-1.5 top-1/2 z-10 -translate-y-1/2"
        />
      )}
      <AutocompleteInput
        as={InputV2}
        className={cn(
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
          selectedCompany && "pl-11 pr-2",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <ProgressSpinner className="size-4 text-inherit" />}
        {!disableClear && (selectedCompany || !!value) && (
          <IconButtonV2
            icon="X"
            size="xs"
            onClick={onClear}
            className="transition duration-300 ease-in group-hover/select:brightness-90"
          />
        )}
        <AutocompleteButton as="div" className={"transition data-[open]:-scale-100 size-6"}>
          <IconButtonV2
            icon="Chevron Down"
            size="xs"
            className="transition duration-300 ease-in group-hover/select:brightness-90"
          />
        </AutocompleteButton>
      </div>
    </div>
  );
};

export type CompanySearchResult = ReturnType<typeof useSearchResults>["results"][number];

export type CompanyChangeData = CompanySearchResult | string | null;

const isLgCompany = (company: CompanySearchResult): boolean => {
  return company.source === "lg";
};

type CompanySearchAutocompleteProps = {
  onChange: (company: CompanyChangeData) => void;
  value?: string | null;
  selectedCompany?: Pick<CompanySearchResult, "name" | "image"> | null;
  searchFields?: SearchField[];
  placeholder?: string;
  disableCustomValue?: boolean;
  className?: string;
  disableClear?: boolean;
};

export const CompanySearchAutocomplete = ({
  onChange,
  value,
  selectedCompany,
  placeholder,
  disableCustomValue,
  className,
  disableClear,
}: CompanySearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults();

  useEffect(() => {
    // pre-search companies on initial load to open dropdown with results when input is clicked
    if (value) {
      search(value);
    }
  }, []);

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (company: CompanySearchResult | string | null) => {
    onChange(company);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div className={className}>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <CompanySearchInput
            loading={loading}
            handleSearch={handleSearch}
            selectedCompany={selectedCompany}
            onClear={handleClear}
            placeholder={placeholder}
            value={value}
            disableClear={disableClear}
          />
        }
        options={
          <AutocompleteOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--input-width)] rounded-sm border bg-white [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "shadow-sm [--anchor-max-height:50vh]",
              "z-10", // needed because of broken location input
            )}
          >
            <div className="sticky top-0 z-[1] bg-white p-1">
              {!searchTerm && (
                <AutocompleteOption
                  value={null}
                  className={cn(
                    "select-none rounded-xxs bg-gray-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                  )}
                  disabled={true}
                >
                  Start typing to see the results...
                </AutocompleteOption>
              )}
              {searchTerm && !disableCustomValue && (
                <AutocompleteOption
                  value={searchTerm}
                  className={cn(
                    "select-none rounded-xxs bg-gray-100 p-1.5 px-3 text-center text-sm",
                    "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                  )}
                >
                  {`Create "${searchTerm}"`}
                </AutocompleteOption>
              )}
              {loading && (
                <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
              )}
            </div>
            {results.map((company) => (
              <AutocompleteOption
                key={company.id ?? company.name}
                value={company}
                className={cn(
                  "flex select-none items-center gap-2 p-1 text-sm",
                  "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                )}
                disabled={isLgCompany(company)}
              >
                <AvatarV2 src={parseUrl(company.image)} size="sm" type="company" />
                <div>
                  <div className="text-sm">{company.name}</div>
                  <div className="text-xs text-gray-600">{company.website}</div>
                </div>
                {isLgCompany(company) && (
                  <span className="ml-auto rounded-xxs bg-gray-100 px-2 py-1 text-xs text-gray-800">
                    Exists in Nazare
                  </span>
                )}
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
