import { cn } from "@/lib/utils";

import { AvatarV2, MemberCardProps, Popover, Tag } from "@/app/components";
import { useSplitList } from "@/app/hooks/use-split-list";
import { LGMember } from "@/app/stores/members.store";

export interface MemberListProps extends Omit<MemberCardProps, "firstname" | "lastname"> {
  members: Pick<LGMember, "firstName" | "lastName" | "avatar" | "id" | "initials">[];
  containerClassName?: string;
  split?: number;
  withTooltip?: boolean;
  label?: string;
}

export const MemberList = ({
  members,
  containerClassName,
  withTooltip = false,
  imgClassName,
  label,
  split,
  labelClassName,
}: MemberListProps) => {
  const { main, other } = useSplitList(members, split);

  return (
    <Popover
      containerClassName={cn("flex w-auto justify-start gap-0.5", containerClassName)}
      placement="bottom"
      childrenContent={
        withTooltip && (
          <div className="grid grid-cols-1 gap-1">
            {[...main, ...other].map(({ firstName, avatar, initials, id }) => (
              <div className="flex items-center gap-1" key={id}>
                <AvatarV2 type="user" size="xs" src={avatar} initials={initials} />
                <p className="text-sm text-black">{firstName}</p>
              </div>
            ))}
          </div>
        )
      }
    >
      {label && <span className={cn("mr-1 text-sm font-semibold", labelClassName)}>{label}</span>}
      {main.map(({ initials, avatar, id }) => (
        <AvatarV2 key={id} type="user" size="xs" src={avatar} initials={initials} />
      ))}
      {other.length > 0 && (
        <Tag label={`+${other.length}`} className={imgClassName} tagClassName="bg-transparent !px-0" />
      )}
    </Popover>
  );
};
