import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnPinningState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { InitialTableState } from "@tanstack/table-core";

import { cn } from "@/lib/utils.ts";

import { InputV2 } from "@/app/components/forms/input-v2";
import { TableNew, TableProps } from "@/app/components/table-new";
import { defaultColumnProps } from "@/app/components/table-new/table-new.config.ts";

export const OpportunityTable = <TData extends object>({
  data,
  columns,
  pageSize = 7,
  initialState = {},
  fnOnRowClick,
  headerClassName = "",
  containerClassName = "",
  ActionButton = null,
  Header = null,
  enableGlobalFilter = false,
  enableSorting = true,
  enableColumnPinning = false,
  scrollXToLastItem = false,
  tableOptions = {},
}: {
  data: Array<TData>;
  columns: Array<ColumnDef<TData, any>>;
  pageSize?: number;
  fnOnRowClick?: (row: TData) => void | null;
  ActionButton?: ReactNode;
  Header?: ReactNode;
  enableGlobalFilter?: boolean;
  scrollXToLastItem?: boolean;
  enableColumnPinning?: boolean;
  enableSorting?: boolean;
  containerClassName?: string;
  headerClassName?: string;
  initialState?: InitialTableState;
  tableOptions?: Partial<TableProps<TData>["options"]>;
}) => {
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const scrollRoot = useRef<HTMLDivElement>(null);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [],
    right: [],
  });

  const table = useReactTable({
    columns,
    data,
    defaultColumn: defaultColumnProps,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : null,
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    enableSortingRemoval: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: (row) => {
      return JSON.stringify(row).toLowerCase().includes(globalFilter.toLowerCase());
    },
    enableGlobalFilter,
    initialState: {
      ...initialState,
      pagination: {
        pageSize,
      },
    },
    enableColumnPinning,
    state: {
      globalFilter,
      columnPinning,
    },
  });

  const showHeader = enableGlobalFilter || ActionButton || Header;

  const noResults = table.getRowModel().rows.length === 0;

  const checkForHorizontalScroll = useCallback(() => {
    if (scrollRoot.current && enableColumnPinning) {
      const { scrollLeft } = scrollRoot.current;
      const isScrolled = scrollLeft > 0;

      if (isScrolled != hasHorizontalScroll) {
        setHasHorizontalScroll(isScrolled);
        setColumnPinning(isScrolled ? { left: [columns[0]?.id] } : { left: [], right: [] });
      }
    }
  }, [enableColumnPinning, hasHorizontalScroll, columns]);

  useEffect(() => {
    if (scrollXToLastItem) scrollRoot.current?.scrollTo(1_000_000, 0);
  }, [columns, scrollXToLastItem]);

  useEffect(() => {
    if (enableColumnPinning) {
      // Check for horizontal scroll when the component mounts
      checkForHorizontalScroll();

      // Add a resize event listener to check on window resize
      scrollRoot.current?.addEventListener("scroll", checkForHorizontalScroll);

      return () => {
        scrollRoot.current?.removeEventListener("scroll", checkForHorizontalScroll);
      };
    }
  }, [checkForHorizontalScroll, scrollRoot, enableColumnPinning]);

  return (
    <div className={cn("w-full rounded-sm bg-white", containerClassName)}>
      <div className="flow-root">
        <div className="h-full">
          <div className={cn("inline-flex size-full flex-col align-middle")}>
            {showHeader && (
              <div className={cn("mb-2 flex w-full items-center justify-between gap-2", headerClassName)}>
                {Header}
                {enableGlobalFilter && (
                  <InputV2
                    value={globalFilter}
                    size="sm"
                    iconLeft={"Search"}
                    containerClassName={"flex-1"}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder={"Search"}
                  />
                )}
                <div className="flex w-36 justify-center">{ActionButton}</div>
              </div>
            )}

            <div ref={scrollRoot}>
              <TableNew
                className={cn("size-full table-fixed", noResults && "flex-1")}
                table={table}
                options={{
                  onRowClick: fnOnRowClick,
                  ...tableOptions,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
