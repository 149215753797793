import { getFullName } from "@/lib/utils.ts";

import { AvatarV2, Icon, Popover, ResponsiveHorizontalList } from "@/app/components";
import { SentimentFormData } from "@/app/screens/dashboard/sections/sentiment-form-requests/use-sentiment-form-requests.ts";

import { UserDataForImage } from "../../dashboard.types";

enum SentimentFormStatus {
  COMPLETED = "COMPLETED",
  NOT_ANSWERED = "NOT_ANSWERED",
  DECLINED = "DECLINED",
}

const UserImageWithStatus = ({ user, state }: { user: UserDataForImage; state?: string }) => {
  return (
    <div className="relative shrink-0">
      <AvatarV2 type={"user"} size={"xs"} src={user.avatar} initials={user.initials} />
      <div className="absolute -bottom-1 -left-1.5">
        {state === SentimentFormStatus.COMPLETED && (
          <Icon type="Submitted" className="size-3.5 rounded-full border border-white bg-white text-green-400" />
        )}
        {state === SentimentFormStatus.DECLINED && (
          <Icon type="Declined" className="size-3.5 rounded-full border border-white bg-white text-red-400" />
        )}
        {state === SentimentFormStatus.NOT_ANSWERED && (
          <Icon type="Clock" className="size-3.5 rounded-full border border-white bg-neutral-300 text-white" />
        )}
      </div>
    </div>
  );
};

export const SentimentParticipants = ({
  participants,
}: {
  participants: SentimentFormData["nzrSentimentFormAssignees"]["nodes"];
}) => {
  return (
    <Popover
      containerClassName="flex-1 overflow-hidden"
      childrenContent={
        <div className="space-y-1 px-0.5">
          {participants.map((assignee) =>
            assignee.member ? (
              <div key={assignee.id} className="flex items-center p-1">
                <UserImageWithStatus user={assignee.member} state={assignee.state} />
                <span className="mx-1">{getFullName(assignee.member?.firstName, assignee.member?.lastName)}</span>
              </div>
            ) : null,
          )}
        </div>
      }
    >
      <ResponsiveHorizontalList
        className="w-full gap-1.5 pb-1 pl-1.5"
        popoverProps={{
          disabled: true,
        }}
        itemProps={{
          className: "shrink-0",
        }}
      >
        {participants.map((assignee) =>
          assignee.member ? (
            <UserImageWithStatus key={assignee.id} user={assignee.member} state={assignee.state} />
          ) : null,
        )}
      </ResponsiveHorizontalList>
    </Popover>
  );
};
