import { Fragment } from "react";

import { observer } from "mobx-react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button, Icon } from "@/app/components";
import { Routes } from "@/app/constants";
import { NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { CompanySelectModal, MetricValue } from "@/app/screens/metrics-extraction/sections";
import { ConfirmModal } from "@/app/screens/modal";
import { useSetNotificationStatusMutation } from "@/app/service/dashboard.gql.ts";
import { useUpsertMetricsExtractionMutation } from "@/app/service/metrics.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

export const CompanyMetrics = observer(({ containerClassName = "" }) => {
  const { id = "new" } = useParams();
  const [upsertMetrics, { loading }] = useUpsertMetricsExtractionMutation();
  // const [updateCompanyEmail, { loading: setOpportunityEmailLoading }] = useSetOpportunityEmailMutation();
  const [setNotificationStatus] = useSetNotificationStatusMutation();
  const navigate = useNavigate();
  if (!metricsStore.activeMetrics?.manual.length && !metricsStore.activeMetrics?.standard.length)
    return (
      <div className={"flex size-full flex-col items-center justify-center gap-y-4"}>
        <Icon type={"NoResults"} className={"size-12 text-gray-400"} />
        <p className="text-lg font-light text-gray-400">No results to found.</p>
      </div>
    );

  return (
    <div className={cn("flex w-full flex-col items-center justify-center rounded-sm", containerClassName)}>
      <div className={"flex size-full flex-col overflow-auto"}>
        <MetricsList metrics={metricsStore.activeMetrics.standard} label={"Standard"} />
        <MetricsList metrics={metricsStore.activeMetrics.manual} label={"Manually added"} />
      </div>
      <div className={"mb-[3%] h-1 w-full shadow-mobile-sm"} />
      <div className={"mb-4 w-full  px-6"}>
        <Button
          text={"Submit"}
          disabled={!metricsStore.canSubmit}
          variant={"gradient"}
          loading={loading}
          className={"w-full"}
          onClick={() => {
            if (metricsStore.state.selectedCompanyId === "new") {
              toast.custom(() => (
                <div className={"flex h-10 w-fit items-center gap-x-3 rounded-sm bg-white px-3 py-2"}>
                  <Icon type={"X"} width={16} height={16} className="rounded-full bg-red p-0.5 text-white" />
                  <p className="text-sm font-semibold">We weren’t able to find a direct match in our database.</p>
                  <Button
                    text={"Link company"}
                    size={"sm"}
                    onClick={() => {
                      modalStore.open(CompanySelectModal);
                    }}
                  />
                </div>
              ));
              return;
            }
            modalStore.open(ConfirmModal, {
              props: {
                primaryBtnTitle: "Confirm & Submit",
                title: "Submit Company Metrics",
                subTitle:
                  "Please ensure you have reviewed all the details carefully. Once submitted, the metrics cannot be modified.",
                handleConfirm: () =>
                  upsertMetrics({
                    variables: {
                      lgCompanyMetricsInput: {
                        companyId: metricsStore.state.selectedCompanyId,
                        ...(metricsStore.mappedMetrics as any),
                      },
                    },
                  })
                    .then(() => {
                      if (id !== "new") {
                        setNotificationStatus({
                          variables: { id: id, status: NotificationStatus.COMPLETED },
                        }).catch();
                      }

                      toast.success("Metrics submitted successfully.");
                      navigate(`${Routes.company}/${metricsStore.state.selectedCompanyId}#performance`);
                    })
                    .catch(() => {}),
              },
            });
          }}
        />
      </div>
    </div>
  );
});

const MetricsList = ({ metrics, label }) => {
  if (!metrics?.length) return null;
  return (
    <>
      <p className={"mb-4 px-6 text-sm font-semibold text-gray-700"}>{label}</p>
      {metrics.map((metric, index) => (
        <Fragment key={metric.label + index}>
          <MetricValue metric={metric} />
        </Fragment>
      ))}
    </>
  );
};
