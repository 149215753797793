import { DefaultColors } from "tailwindcss/types/generated/colors";

type Color = Record<"DEFAULT" | number, string> | string;
type DefaultColorsOverride = Partial<Record<keyof DefaultColors, Color>>;

export const colors: DefaultColorsOverride = {
  gray: {
    DEFAULT: "#BFBFBF",
    900: "#262626",
    800: "#434343",
    700: "#595959",
    600: "#8C8C8C",
    500: "#BFBFBF",
    400: "#D9D9D9",
    300: "#F0F0F0",
    200: "#F5F5F5",
    100: "#FAFAFA",
  },
  red: {
    DEFAULT: "#FF4D4F",
    900: "#820014",
    800: "#A8071A",
    700: "#CF1322",
    600: "#F5222D",
    500: "#FF4D4F",
    400: "#FF7875",
    300: "#FFA39E",
    200: "#FFCCC7",
    100: "#FFF1F0",
  },
  orange: {
    DEFAULT: "#FFA940",
    900: "#873800",
    800: "#AD4E00",
    700: "#D46B08",
    600: "#FA8C16",
    500: "#FFA940",
    400: "#FFC069",
    300: "#FFD591",
    200: "#FFE7BA",
    100: "#FFF7E6",
  },
  yellow: {
    DEFAULT: "#FFEC3D",
    900: "#876800",
    800: "#AD8B00",
    700: "#D4B106",
    600: "#FADB14",
    500: "#FFEC3D",
    400: "#FFF566",
    300: "#FFFB8F",
    200: "#FFFFB8",
    100: "#FEFFE6",
  },
  lime: {
    DEFAULT: "#BAE637",
    900: "#3F6600",
    800: "#5B8C00",
    700: "#7CB305",
    600: "#A0D911",
    500: "#BAE637",
    400: "#D3F261",
    300: "#EAFF8F",
    200: "#F4FFB8",
    100: "#FCFFE6",
  },
  green: {
    DEFAULT: "#73D13D",
    900: "#135200",
    800: "#237804",
    700: "#389E0D",
    600: "#52C41A",
    500: "#73D13D",
    400: "#95DE64",
    300: "#B7EB8F",
    200: "#D9F7BE",
    100: "#F6FFED",
  },
  cyan: {
    DEFAULT: "#36CFC9",
    900: "#00474F",
    800: "#006D75",
    700: "#08979C",
    600: "#13C2C2",
    500: "#36CFC9",
    400: "#5CDBD3",
    300: "#87E8DE",
    200: "#B5F5EC",
    100: "#E6FFFB",
  },
  purple: {
    DEFAULT: "#9254DE",
    900: "#22075E",
    800: "#391085",
    700: "#531DAB",
    600: "#722ED1",
    500: "#9254DE",
    400: "#B37FEB",
    300: "#D3ADF7",
    200: "#EFDBFF",
    100: "#F9F0FF",
  },

  neutral: {
    DEFAULT: "#7F8084",
    900: "#121315",
    800: "#333436",
    700: "#4C4D50",
    600: "#66676A",
    500: "#7F8084",
    400: "#999A9E",
    300: "#CDCED2",
    200: "#E6E6EA",
    100: "#F6F6FA",
    50: "#FAFAFA",
  },
  white: "#FFF",
  black: "#000",
};

export const customColors = {
  // todo check these
  grey: "#191A1B",
  background: "rgba(240, 240, 240, 0.7)",
  primary: {
    DEFAULT: "#E9366B",
    900: "#75113A",
    800: "#8E1B44",
    700: "#A8244E",
    600: "#BF2D57",
    500: "#E9366B",
    400: "#FF4975",
    300: "#FF5E80",
    200: "#FF738B",
    100: "#FFD5E0",
    50: "#ffeef2",
  },
  secondary: {
    DEFAULT: "#EE712A",
    900: "#A32506",
    800: "#B3380F",
    700: "#C54B18",
    600: "#D55E21",
    500: "#EE712A",
    400: "#FF8534",
    300: "#FF9E5F",
    200: "#FFD1B1",
    100: "#FFE8D4",
  },
  // check these end

  gold: {
    DEFAULT: "#FFC53D",
    900: "#874D00",
    800: "#AD6800",
    700: "#D48806",
    600: "#FAAD14",
    500: "#FFC53D",
    400: "#FFD666",
    300: "#FFE58F",
    200: "#FFF1B8",
    100: "#FFFBE6",
  },
  volcano: {
    DEFAULT: "#FF7A45",
    900: "#871400",
    800: "#AD2102",
    700: "#D4380D",
    600: "#FA541C",
    500: "#FF7A45",
    400: "#FF9C6E",
    300: "#FFBB96",
    200: "#FFD8BF",
    100: "#FFF2E8",
  },
  "day-blue": {
    DEFAULT: "#4096FF",
    900: "#002C8C",
    800: "#003EB3",
    700: "#0958D9",
    600: "#1677FF",
    500: "#4096FF",
    400: "#69B1FF",
    300: "#91CAFF",
    200: "#BAE0FF",
    100: "#E6F4FF",
  },
  "geek-blue": {
    DEFAULT: "#597EF7",
    900: "#061178",
    800: "#10239E",
    700: "#1D39C4",
    600: "#2F54EB",
    500: "#597EF7",
    400: "#85A5FF",
    300: "#ADC6FF",
    200: "#D6E4FF",
    100: "#F0F5FF",
  },
  magenta: {
    DEFAULT: "#F759AB",
    900: "#7806",
    800: "#9E1068",
    700: "#C41D7F",
    600: "#EB2F96",
    500: "#F759AB",
    400: "#FF85C0",
    300: "#FFADD2",
    200: "#FFD6E7",
    100: "#FFF1F0",
  },
};
