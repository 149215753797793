import React, { useEffect, useState } from "react";

import { cn, getInitials, parseUrl } from "@/lib/utils.ts";

import { Icon, ImageWithErrorProfile, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  Autocomplete,
  AutocompleteButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
} from "@/app/components/forms/autocomplete.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { useFounderSearchQuery } from "@/app/service/founders.gql.ts";

const useSearchResults = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useFounderSearchQuery({
    variables: {
      q: searchTerm,
      limit: 20,
    },
    skip: !searchTerm,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = data?.founderSearchV1?.nodes ?? [];

  return { results, search, loading, searchTerm };
};

const FounderSearchInput = ({ handleSearch, loading, selectedFounder, onClear, placeholder }) => {
  const displayValue = (founder: FounderSearchResult | string | null): string => {
    return typeof founder === "string" ? founder : founder?.name ?? "";
  };

  return (
    <div className="group/select relative">
      {selectedFounder && (
        <ImageWithErrorProfile
          src={typeof selectedFounder === "object" ? selectedFounder.image : null}
          className="absolute left-4 top-1/2 !size-7 !min-w-7 basis-7 -translate-y-1/2 rounded-xs"
          alt={selectedFounder.name ? getInitials(selectedFounder.name) : "N/A"}
        />
      )}
      <AutocompleteInput
        as={InputV2}
        className={cn(
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
          selectedFounder && "pl-12",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <ProgressSpinner className="size-4 text-inherit" />}
        {selectedFounder && (
          <IconButtonV2
            icon="X"
            size="xs"
            onClick={onClear}
            className="transition duration-300 ease-in group-hover/select:brightness-90"
          />
        )}
        <AutocompleteButton as="div" className={"data-[open]:-scale-100"}>
          <IconButtonV2
            className="bg-transparent"
            icon="Chevron Down"
            size="xs"
            className="transition duration-300 ease-in group-hover/select:brightness-90"
          />
        </AutocompleteButton>
      </div>
    </div>
  );
};

export type FounderSearchResult = ReturnType<typeof useSearchResults>["results"][number];

export type FounderChangeData = FounderSearchResult | string | null;

export const isDealroomFounder = (founder: FounderChangeData): boolean => {
  return founder !== null && typeof founder === "object" && founder.source === "dealroom";
};

export const isLgFounder = (founder: FounderChangeData): boolean => {
  return founder !== null && typeof founder === "object" && founder.source === "lg";
};

type FounderSearchAutocompleteProps = {
  onChange: (founder: FounderChangeData) => void;
  value?: string;
  selectedFounder?: FounderSearchResult | null;
  placeholder?: string;
};

export const FounderSearchAutocomplete = ({
  onChange,
  value,
  selectedFounder,
  placeholder,
}: FounderSearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults();

  useEffect(() => {
    // pre-search companies on initial load to open dropdown with results when input is clicked
    if (value) {
      search(value);
    }
  }, []);

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (founder: FounderSearchResult | string | null) => {
    // clear founder selection when using custom name
    onChange(founder);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <FounderSearchInput
            loading={loading}
            handleSearch={handleSearch}
            selectedFounder={selectedFounder}
            onClear={handleClear}
            placeholder={placeholder}
          />
        }
        options={
          <AutocompleteOptions
            transition
            className={cn(
              "w-[var(--input-width)] rounded-xs border bg-white [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "z-10", // needed because of broken location input
              "absolute max-h-64 overflow-y-auto shadow-sm [--anchor-max-height:50vh]",
            )}
          >
            <div className="sticky top-0 z-[1] bg-white p-1">
              {!searchTerm && (
                <AutocompleteOption
                  value={null}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                  )}
                  disabled={true}
                >
                  Start typing to see the results...
                </AutocompleteOption>
              )}
              {searchTerm && (
                <AutocompleteOption
                  value={searchTerm}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                    "sticky top-0 z-[1]",
                  )}
                >
                  {`Create "${searchTerm}"`}
                </AutocompleteOption>
              )}
              {loading && (
                <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
              )}
            </div>
            {results.map((founder) => (
              <AutocompleteOption
                key={founder.id ?? founder.name}
                value={founder}
                className={cn(
                  "flex select-none items-center gap-2 p-1 text-sm",
                  "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                )}
              >
                <ImageWithErrorProfile
                  src={parseUrl(founder.image)}
                  className="!size-8 !min-w-8 basis-8 rounded-xxs bg-neutral-100"
                  labelClassName="rounded-xxs"
                  alt={founder.name ? getInitials(founder.name) : "N/A"}
                />
                <div className="w-full overflow-hidden">
                  <div className="flex items-center gap-1 text-sm">
                    {founder.name}
                    {isDealroomFounder(founder) && <Icon type="Dealroom" className="size-[1em] text-gray-500" />}
                  </div>
                  <div className="truncate text-xs text-gray-600">{founder.linkedinUrl}</div>
                </div>
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
