import { cn } from "@/lib/utils.ts";

interface ModalWebFrameProps {
  children: React.ReactNode;
  containerClassName?: string;
}

export const ModalWebFrame = ({ containerClassName, children, ...props }: ModalWebFrameProps) => {
  return (
    <div
      className={cn(`relative flex flex-1 flex-col items-center justify-between space-y-4 lg:p-12`, containerClassName)}
      {...props}
    >
      {children}
    </div>
  );
};
