import { useGetCompanyCommentsQuery } from "@/app/service/company-comments.gql.ts";

import { mapComments } from "./comments.utils.ts";

export const useComments = (companyId?: string, limit?: number) => {
  const { data: commentsData, loading } = useGetCompanyCommentsQuery({
    variables: {
      companyId: companyId,
      limit,
    },
    skip: !companyId,
    fetchPolicy: "cache-and-network",
  });

  const comments = mapComments(commentsData);

  return { comments, loading };
};
