import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

type CompanySource = NonNullable<OpportunityCompany>["companiesSources"]["items"][number];

export type DealroomCompanySource = CompanySource["dealroomCompanyAsSource"];

type SourceTypeMap = {
  MnEntry: CompanySource["mnEntryAsSource"];
  DealroomCompany: DealroomCompanySource;
  TrelloCard: CompanySource["trelloCardAsSource"];
};

type SourceTypeName = keyof SourceTypeMap;

type SourceByType<TSourceType extends SourceTypeName> = SourceTypeMap[TSourceType];

export const getCompanySource = <TSourceType extends SourceTypeName>(
  company: OpportunityCompany,
  type: TSourceType,
): SourceByType<TSourceType> => {
  if (type === "DealroomCompany") {
    return company?.companiesSources.items.find((item) => item.sourceType === "DealroomCompany")
      ?.dealroomCompanyAsSource as SourceByType<TSourceType>;
  }

  if (type === "TrelloCard") {
    return company?.companiesSources.items.find((item) => item.sourceType === "TrelloCard")
      ?.trelloCardAsSource as SourceByType<TSourceType>;
  }
  if (type === "MnEntry") {
    return company?.companiesSources.items.find((item) => item.sourceType === "MnEntry")
      ?.mnEntryAsSource as SourceByType<TSourceType>;
  }
  console.warn("Unknown type:", type);
  return null;
};
