import { Fragment } from "react";

import { Button, ProgressSpinner } from "@/app/components";
import { Form } from "@/app/components/charts/form-field.tsx";
import { SentimentInputFormField } from "@/app/components/charts/sentiment-form.tsx";
import modalStore from "@/app/stores/modal.store";

const shouldShowTextForm = (form, el1): boolean => {
  if (el1?.group_id === "toggle_flag") return false;
  return (
    (el1?.group_id && el1.group_id !== "concerns") ||
    (el1.group_id === "concerns" &&
      !!(form.getValues("concerns_multiple_choice") || []).find((item) => {
        return item === "Other";
      }))
  );
};

const NamePill = ({ name, count }: { name: string; count: number }) => {
  return (
    <div data-cy="sentiment-step" className="flex justify-center">
      <p className="my-4 flex w-auto items-center rounded-sm bg-neutral-100 p-2 text-center text-sm font-medium">
        <span className="flex h-4 items-center justify-center rounded-xxs bg-neutral-400 p-1 text-xxs font-semibold text-white">
          {count}
        </span>
        <span className="ml-1">{name}</span>
      </p>
    </div>
  );
};

export const CompleteSentimentForm = ({
  form,
  sentimentForm,
  gettingForm,
  notificationId,
  sentimentFields,
  formProgress,
}) => {
  if (gettingForm) {
    return (
      <div className="flex size-full items-center justify-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (!sentimentForm)
    return (
      <div className="flex size-full flex-col items-center justify-center p-4">
        <p className="mb-4 text-xl font-semibold">Sentiment form not found</p>
        {notificationId && (
          <Button
            variant="gradient"
            onClick={() => {
              modalStore.close();
            }}
          >
            Mark as read
          </Button>
        )}
      </div>
    );

  return (
    <div data-cy="complete-sentiment-form" className={"flex w-full flex-col"}>
      <Form {...form}>
        <div className="w-full">
          {sentimentFields.map(([el, el1], idx) => {
            return (
              <Fragment key={`${el.field_hash}_${idx}`}>
                {formProgress === idx && <NamePill name={el.name} count={idx + 1} />}
                <div className="space-y-6">
                  <SentimentInputFormField
                    field={el}
                    visible={formProgress === idx}
                    clearError={form.clearErrors}
                    form={form}
                  />
                  {shouldShowTextForm(form, el) && (
                    <SentimentInputFormField
                      key={`${el.field_hash}_${idx + 1}`}
                      field={el1 ?? { field_id: "concerns_short_text", type: "short_text" }}
                      visible={formProgress === idx}
                      clearError={form.clearError}
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </Form>
    </div>
  );
};
