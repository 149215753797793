import { ImageWithErrorFallback } from "..";

import { Avatar } from ".";

export interface ProfileImageProps {
  firstname: string;
  lastname: string;
  image?: string | null;
  containerClass?: string;
  imgClass?: string;
}

/**
 * @deprecated use AvatarV2 instead
 */
export const ProfileImage = ({
  firstname = "",
  lastname = "",
  image,
  containerClass = "",
  imgClass = "",
}: ProfileImageProps) => {
  return (
    <div className={`flex h-full flex-col items-center justify-center overflow-hidden  ${containerClass}`}>
      {image ? (
        <ImageWithErrorFallback className={imgClass} src={image} alt="Avatar" icon="Users" />
      ) : (
        <Avatar firstname={firstname} lastname={lastname} className={imgClass} />
      )}
    </div>
  );
};
