import React, { createContext, SyntheticEvent, useContext } from "react";

import { Menu, MenuItems, MenuButton, MenuItem, MenuItemsProps } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Button as GenericButton, ButtonProps as GenericButtonProps } from "../button/button.tsx";
import { IconType } from "@/app/components";

export interface DropdownOption {
  label: string;
  action?: (e?: SyntheticEvent) => void;
  icon?: IconType;
}

export type DropdownProps = {
  children: React.ReactNode;
  // todo refactor: a bit weird approach since we use compound components and can use options directly in Dropdown.Items when defining a component
  //  if we want to use this approach, need to be able to enhance the option object as sometimes other props are needed and makes it impossible to pass them
  options: DropdownOption[];
  className?: string;
};

export type DropdownButtonProps = GenericButtonProps;

export type DropdownItemsProps = {
  anchor?: MenuItemsProps["anchor"];
  children: (option: DropdownOption) => React.ReactNode;
  className?: string;
};

export type DropdownOptionProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
};

const DropdownContext = createContext<{ options: DropdownOption[] }>({ options: [] });

function Button(props: DropdownButtonProps) {
  return <MenuButton as={GenericButton} {...props} data-cy="expand" />;
}

function Items({ children, className, anchor = "bottom end" }: DropdownItemsProps) {
  const { options } = useContext(DropdownContext);

  return (
    <MenuItems
      anchor={anchor}
      className={cn(
        `mt-1 min-w-[150px] overflow-y-auto rounded-sm bg-white shadow-dropdown transition duration-100 ease-out`,
        className,
      )}
    >
      {options.map((op) => children(op))}
    </MenuItems>
  );
}

function Item({ children, className, onClick }: DropdownOptionProps) {
  return (
    <MenuItem>
      <button
        className={cn(
          "flex w-full items-center bg-white px-4 py-3 text-xs font-semibold text-black hover:bg-neutral-200 disabled:bg-neutral-50",
          className,
        )}
        onClick={onClick}
        data-cy="menu-item"
      >
        {children}
      </button>
    </MenuItem>
  );
}

function DropdownRoot({ className, children, options }: DropdownProps) {
  return (
    <DropdownContext.Provider value={{ options }}>
      <div className={className}>
        <Menu as="div">{children}</Menu>
      </div>
    </DropdownContext.Provider>
  );
}

/**
 * @deprecated use DropdownV2 instead
 */
export const Dropdown = Object.assign(DropdownRoot, { Button, Items, Item });
