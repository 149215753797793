import React from "react";

import { ImageWithErrorFallback, Popover } from "@/app/components";
import { ProgressArcChart } from "@/app/components/charts/progress-arc-chart.tsx";
import {
  HighlightsCard,
  HighlightsCardBody,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import membersStore from "@/app/stores/members.store.tsx";

import { LatestReturnProfileData } from "./use-latest-return-profile.ts";

type LatestReturnProfileProps = {
  data?: LatestReturnProfileData | null;
};

export const LatestReturnProfileCard = ({ data: latestReturnProfile }: LatestReturnProfileProps) => {
  return (
    <HighlightsCard>
      {latestReturnProfile && (
        <HighlightsCardBody>
          <HighlightsCardTitle text={String(latestReturnProfile.title)} />
          {latestReturnProfile?.multipliers && (
            <div className={"flex size-full min-h-[100px] gap-x-1 overflow-x-auto md:min-h-0"}>
              {Object.entries(latestReturnProfile.multipliers).map(([label, value]) => (
                <Popover
                  placement="bottom"
                  key={label}
                  containerClassName={"flex-1"}
                  childrenContent={
                    value?.responses?.length ? (
                      <div className={"space-y-2"}>
                        {value?.responses?.map(({ person }) => (
                          <div className={"flex items-center gap-1"} key={person}>
                            <ImageWithErrorFallback
                              alt={person}
                              src={membersStore.getByName(person)?.avatar}
                              className={"size-6 rounded-xs"}
                            />
                            <span className="text-xs font-semibold">{person}</span>
                          </div>
                        ))}
                      </div>
                    ) : null
                  }
                >
                  <ProgressArcChart
                    labelClassName="text-sm"
                    value={value.totalSentiments}
                    total={latestReturnProfile?.totalSentiments}
                    label={label}
                    options={{ minSize: 54 }}
                  />
                </Popover>
              ))}
            </div>
          )}
          {!!latestReturnProfile.date && (
            <HighlightsCardFooter>
              <HighlightsCardLastUpdated date={latestReturnProfile.date} />
            </HighlightsCardFooter>
          )}
        </HighlightsCardBody>
      )}
      {!latestReturnProfile && <HighlightsCardEmptyState text={"No Available Return Profile"} />}
    </HighlightsCard>
  );
};
