import { useMemo } from "react";

import { getFullName } from "@/lib/utils.ts";

import { AvatarV2, ImageWithErrorFallback } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { Badges, SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components";
import { useSocialMediaIcons } from "@/app/screens/opportunities/opportunity/use-opportunity.tsx";
import { LGMember } from "@/app/stores/members.store.tsx";
import { MnEntry } from "@/app/types";

import { OpportunityCompany, useOpportunityContext } from "../../opportunity-context.tsx";
import { Assignees } from "../assign-users";
import { CompanyFlagToggle } from "../toggle-flags";

const CompanyStatusBadge = ({ company }: { company: OpportunityCompany }) => {
  const mnEntryData = (
    company?.companiesSources.items.find((item) => item.sourceType === "MnEntry")?.mnEntryAsSource as MnEntry
  )?.data;

  const isIncomplete =
    company?.status === "LEGACY"
      ? false
      : !company ||
        !company.name ||
        !company.websiteUrl ||
        !company.tagline ||
        !company.country ||
        !company.industries?.length ||
        !mnEntryData?.round ||
        !company.targetFund ||
        !mnEntryData?.investment_type;

  return isIncomplete && <ChipV2 label="incomplete" color="orange" />;
};

export const CompanyDetailsViewMode = ({ onEdit }: { onEdit: () => void }) => {
  const { image, portCo, company, badges, tagline, name } = useOpportunityContext();
  const companyName = name || "Stealth Company";

  const trelloColumn = company?.companyStage?.name ?? "Not Yet Reviewed or Met";

  const socialMediaIcons = useSocialMediaIcons(company);

  const assignees = useMemo(
    () =>
      company?.assignees
        ? company.assignees?.nodes?.reduce<Array<LGMember & { assigneeId: string }>>(
            (accumulator, { member, id }) => (member ? [...accumulator, { assigneeId: id, ...member }] : accumulator),
            [],
          )
        : [],
    [company?.assignees],
  );

  return (
    <>
      <div className={"flex items-center space-x-3 lg:space-x-4"}>
        <ImageWithErrorFallback
          src={image}
          alt={companyName}
          iconClassName={"rounded-sm p-2 lg:p-5 bg-white"}
          className="flex size-12 rounded-md border border-gray-300 lg:size-16 lg:rounded-sm"
        />
        <div className={"flex flex-1 flex-col justify-center space-y-1 overflow-hidden"}>
          <div className="flex size-full items-center gap-2 md:flex-nowrap md:gap-3">
            <span className="h-full shrink overflow-hidden truncate text-2xl font-semibold leading-8 lg:text-2xl lg:leading-9">
              {companyName}
            </span>
            <SocialMediaIcons icons={socialMediaIcons} className="hidden md:flex" variant="primary" />
            <div className="ml-auto flex items-center gap-2">
              <CompanyStatusBadge company={company} />
              <IconButtonV2 icon="Pencil" onClick={onEdit} />
            </div>
          </div>
          <div className="flex flex-wrap">
            <span className="whitespace-nowrap text-sm font-medium text-gray-600">
              {portCo ? "Portfolio Company" : "Company"}
            </span>
            <span className={"mx-2 text-sm font-semibold text-gray-600"}>•</span>
            <span className="truncate text-sm font-medium text-gray-600">{trelloColumn}</span>
          </div>
        </div>
      </div>

      <SocialMediaIcons icons={socialMediaIcons} className="mt-3 md:hidden" variant="secondary" />

      <div className="mt-3 lg:mt-4">
        {tagline && (
          <div className="mb-3 lg:mb-4">
            <span className="font-semibold text-gray-900">Description</span>
            <p className="mt-2 text-sm">{tagline}</p>
          </div>
        )}
        <div className="flex gap-6 md:items-start md:gap-8">
          {company && (
            <CompanyFlagToggle
              company={company}
              label={company.isOpCompany ? "Raising in <6 Months?" : "Share with Network?"}
              flag={company.isOpCompany ? "6_month_runway" : "sharing_allowed"}
              styles={{ toggleContainer: "flex my-0 mt-2" }}
            />
          )}
          <Assignees assignees={assignees} companyId={company?.id} />
        </div>
      </div>
      <Badges
        className="mt-4"
        badges={
          company?.isDeepTech
            ? [
                ...(badges ?? []),
                {
                  value: "Deep Tech",
                  id: "deepTech",
                  group: "other",
                  title: "Deep tech companies are enabled by scientific or engineering breakthroughs",
                },
              ]
            : badges
        }
      />
    </>
  );
};
