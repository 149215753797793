import { Loader } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { DropdownOption, DropdownV2 } from "@/app/components";
import { useActionWrapper } from "@/app/hooks";

export type ActionsMenuProps = {
  options: DropdownOption[];
  className?: string;
};

const asyncActionLabels = ["Hide", "Unhide"];

export function ActionsMenu({ className, options = [] }: ActionsMenuProps) {
  const { loading, wrapAction } = useActionWrapper();

  return (
    <DropdownV2 className={cn("ml-2", className)}>
      <DropdownV2.Button
        buttonVariant="IconButton"
        icon="MoreVertical"
        onClick={(e) => e.stopPropagation()}
        size="xs"
      />

      <DropdownV2.Items>
        {options.map(({ label, action }) => (
          <DropdownV2.Item
            key={label}
            onClick={(e) =>
              action && wrapAction({ label, event: e, action, isAsync: asyncActionLabels.includes(label) })
            }
          >
            <span className={cn("truncate text-black")}>{label}</span>
            {loading[label] && <Loader size={12} className="ml-1 animate-spin" />}
          </DropdownV2.Item>
        ))}
      </DropdownV2.Items>
    </DropdownV2>
  );
}
