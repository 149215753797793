import { useCallback, useMemo } from "react";

import { UserTag, Action } from "@/gql/graphql";

import { ButtonVariant } from "../components/button";
import { getCompanyUserTags } from "../misc/parse-company-helpers";
import { MoveInTrelloModal, AddNewResponsiveModal, SnoozeResponsiveModal, ConfirmModal } from "../screens/modal";
import { ConvertToPortfolio } from "../screens/modal/actions/convert-to-portfolio";
import { SnoozeActionType } from "../screens/modal/actions/snooze/hooks";
import { useRemoveAssignee } from "../screens/opportunities/opportunity/components/assign-users/use-remove-assignee";
import { authStore } from "../stores/auth.store";
import modalStore from "../stores/modal.store";
import { Company } from "../types";

import { useActionMutation } from "./use-action-mutation";
import { useTagMutation } from "./use-tag-mutation";

export type ActionNames =
  | "hide"
  | "follow"
  | "removeProspect"
  | "convertToPortfolio"
  | "convertToProspect"
  | "addSentiment"
  | "moveToPipeline"
  | "moveStage"
  | "unAssign";

export type OpportunityAction = {
  label: string;
  action: () => void;
  variant?: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
};

export interface OpportunityActionsHookParams {
  company?: Partial<Company> | null;
  refresh?: () => void;
}

export const useOpportunityActions = ({ company, refresh }: OpportunityActionsHookParams) => {
  const userTags = getCompanyUserTags(company || {});

  const { onSubmit } = useActionMutation({});
  const { onRemoveAssignee } = useRemoveAssignee();
  const { onSubmit: submitTag } = useTagMutation({});

  const handleActionSuccess = useCallback(() => {
    if (refresh) refresh();
    modalStore.close();
  }, [refresh]);

  return useMemo(() => {
    if (!company) {
      return;
    }

    const isTracking = userTags[authStore.userId]?.following;
    const isHidden = userTags[authStore.userId]?.hidden;

    const assigneeId: string | undefined = company.assignees?.nodes.find(
      ({ member }) => member?.id === authStore.userId,
    )?.id;

    const actions: Record<ActionNames, OpportunityAction> = {
      follow: {
        label: isTracking ? "Untrack" : "Track",
        action: () =>
          submitTag({
            companyId: company.id,
            userId: authStore.userId,
            tag: UserTag.Follow,
            value: isTracking ? 0 : 1,
            successMessage: isTracking
              ? `You are no longer tracking ${company.name}`
              : `You are now tracking ${company.name}`,
          }),
        variant: "outline",
      },

      hide: {
        label: isHidden ? "Unhide" : "Hide",
        action: () =>
          submitTag({
            companyId: company.id,
            userId: authStore.userId,
            tag: UserTag.Hide,
            value: isHidden ? 0 : 1,
            successMessage: isHidden
              ? `${company.name} is no longer hidden from you`
              : `You will no longer see ${company.name} on your dashboard`,
          }),
        variant: "outline",
      },

      convertToProspect: {
        label: "Convert to Prospect",
        action: () => {
          modalStore.open(MoveInTrelloModal, {
            props: { id: company.id, title: "Convert Company to Prospect", onSuccess: handleActionSuccess },
          });
        },
        variant: "outline",
      },

      addSentiment: {
        label: "Add Sentiment",
        action: () => {
          modalStore.open(AddNewResponsiveModal, {
            props: { company, sentimentOnly: true },
          });
        },
        variant: "secondary",
      },

      moveToPipeline: {
        label: "Move Back to Pipeline",
        action: () => {
          modalStore.open(SnoozeResponsiveModal, {
            props: {
              id: company.id,
              title: "Move Back to Pipeline",
              actionType: SnoozeActionType.Pipeline,
              onSuccess: handleActionSuccess,
            },
          });
        },
        variant: "outline",
      },

      moveStage: {
        label: "Move in Prospects",
        action: () => {
          modalStore.open(MoveInTrelloModal, {
            props: { id: company.id, title: "Move in Prospects", onSuccess: handleActionSuccess },
          });
        },
        variant: "outline",
      },

      removeProspect: {
        label: "Move Back to Portfolio",
        action: () => {
          modalStore.open(ConfirmModal, {
            props: {
              title: "Are you sure you want to move this company back to portfolio view?",
              subTitle: "By confirming you will be removing this company from prospects list",
              handleConfirm: () =>
                onSubmit({
                  action: Action.Snooze,
                  entityId: company.id,
                  resurface: "never",
                  successMessage: "Moved back to Portfolio",
                }).then(handleActionSuccess),
            },
          });
        },
        variant: "outline",
      },

      convertToPortfolio: {
        label: "Convert to Portfolio",
        action: () => {
          modalStore.open(ConvertToPortfolio, {
            props: {
              id: company.id,
              onSuccess: handleActionSuccess,
            },
          });
        },
        variant: "outline",
      },
    };

    if (assigneeId) {
      actions.unAssign = {
        label: "Not Relevant",
        variant: "outline",
        action: () =>
          modalStore.open(ConfirmModal, {
            props: {
              title: "Are you sure this Company is not relevant to you?",
              subTitle: "By confirming, you will be unassigned from the company",
              handleConfirm: () => onRemoveAssignee(authStore.userId, company.id),
            },
          }),
      };
    }

    return actions;
  }, [company, userTags, onRemoveAssignee, submitTag, handleActionSuccess, onSubmit]);
};
