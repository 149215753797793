import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";

import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { getCompanySource } from "../../../utils/company-source.utils";

export const CompanyDetailsEditSchema = z.object({
  name: z.string().optional(),
  websiteUrl: z
    .union([
      z.literal(""),
      z.string().regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/, {
        message: "Please enter a valid url",
      }),
    ])
    .optional()
    .nullable(),
  description: z.string().optional(),

  location: z
    .object({
      country: z.string(),
      city: z.string(),
    })
    .optional(),
  sectors: z.array(z.string()).optional(),
  lastRound: z.string().optional().nullable(),
  // targetFund: z.string().optional().nullable(),
  investmentType: z.enum(["core", "strategic"]).optional().nullable(),
  isDeepTech: z.boolean().optional().nullable(),
  dealroomCompany: z.string().optional().nullable(),
});

export type CompanyDetailsEditFormData = z.infer<typeof CompanyDetailsEditSchema>;

export const useCompanyDetailsEditForm = (company: OpportunityCompany) => {
  const mnEntryData = getCompanySource(company, "MnEntry")?.data;
  const dealroomSource = getCompanySource(company, "DealroomCompany");

  const latestRound = company?.fundingRounds.items[0]?.round;

  return useForm<CompanyDetailsEditFormData>({
    resolver: zodResolver(CompanyDetailsEditSchema),
    mode: "all",
    defaultValues: {
      name: company?.name ?? "",
      websiteUrl: company?.websiteUrl ?? "",
      description: company?.tagline ?? "",

      location: {
        country: company?.country ?? "",
        city: company?.city ?? "",
      },
      isDeepTech: mnEntryData?.is_deep_tech,
      sectors: company?.industries ?? [],
      lastRound: mnEntryData?.round ?? latestRound ?? "",
      targetFund: company?.targetFund ?? mnEntryData?.target_fund ?? "",
      investmentType: (mnEntryData?.investment_type as "core" | "strategic") ?? undefined,
      dealroomCompany: dealroomSource?.id ?? mnEntryData?.dealroomCompany,
    },
  });
};
