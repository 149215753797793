import { useNavigate } from "react-router";

import { cn } from "@/lib/utils";

import { CompanyImg, Pill, Icon, AvatarV2, Label } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { TableCellTotals } from "@/app/components/table-new/components/table-cell-total.tsx";

import { Column } from "./types";

export interface CellWrapperProps {
  className?: string;
  scalarValue?: string | number;
  children?: React.ReactNode;
  id: Column;
  isFooter?: boolean;
  depth?: number;
  footerLabel?: string;
}

export const CellWrapper = ({ scalarValue = "", children, id, className, isFooter }: CellWrapperProps) => {
  if (id === "fundName" || id === "investmentType" || id === "mostRecentRound") {
    return (
      <div className="flex justify-center">
        <Pill label={scalarValue.toString()} pillClassName="" />
      </div>
    );
  }

  if (isFooter) {
    return <TableCellTotals label={"TOTAL"} value={<span className={className}>{children || scalarValue}</span>} />;
  }

  return (
    <div className="space-y-1.5">
      <span className={className}>{children || scalarValue}</span>
    </div>
  );
};

export const ExpandCellWrapper = ({ row }) => {
  return (
    <div className={cn("mx-1 inline-block", row.getCanExpand() ? "visible" : "invisible")}>
      <IconButtonV2 icon={row.getIsExpanded() ? "Sub" : "Add"} className="size-6 text-gray-500" />
    </div>
  );
};

export const CompanyCellWrapper = ({ company, row }) => {
  const navigate = useNavigate();
  return (
    <button
      className={cn("flex w-full cursor-pointer items-center justify-start overflow-x-hidden text-ellipsis", {
        invisible: row.depth > 0,
      })}
      onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : undefined}
    >
      {row && <ExpandCellWrapper row={row} />}
      <AvatarV2 type={"company"} src={company?.srcCompany?.company?.image} className="mr-2" />
      <span
        className="select-none overflow-hidden text-ellipsis text-left text-sm font-semibold hover:underline"
        onClick={() => company?.srcCompany?.company?.id && navigate(`/company/${company?.srcCompany?.company?.id}`)}
      >
        {company.name}
      </span>
    </button>
  );
};

export const getRunwayColors = (value?: string) => {
  switch (value?.trim()) {
    case ">24 months":
    case "24+ months":
    case "> 36 months":
    case "36+ months":
      return {
        bg: "!bg-green-200",
        text: "!text-green-700",
      };
    case "18 - 36 months":
    case "18-24 months":
      return {
        bg: "!bg-green-100",
        text: "!text-green-700",
      };
    case "12-18 months":
      return {
        bg: "!bg-orange-200",
        text: "!text-orange-700",
      };
    case "6-12 months":
    case "< 12 months":
      return {
        bg: "!bg-red-100",
        text: "!text-red-600",
      };
    case "<6 months":
      return {
        bg: "!bg-red-200",
        text: "!text-red-600",
      };
    default:
      return {
        bg: "",
        text: "!text-neutral-600",
      };
  }
};

export const RunwayCellWrapper = ({ value }) => {
  const { bg: containerStyle, text: textStyle } = getRunwayColors(value);

  return <Label description={value} containerClassName={cn(containerStyle, textStyle)} />;
};
