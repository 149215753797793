import { NetworkSignalType } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal-utils.ts";
import { NetworkSignalData } from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal.types.ts";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

export type TimesFlaggedData = {
  loading: boolean;
  data?: {
    numTimesFlagged: number;
    signals: NetworkSignalData[];
  };
};

const exclusionList = [NetworkSignalType.Outbound];

export const useTimesFlagged = (): TimesFlaggedData => {
  const { networkSignals } = useOpportunityContext();
  const { signals, loading } = networkSignals ?? { signals: [], loading: false };

  if (loading) {
    return {
      loading,
    };
  }

  const filteredSignals = signals.filter((signal) => !exclusionList.includes(signal.sourceType as NetworkSignalType));
  const numTimesFlagged = filteredSignals.length;

  return {
    loading,
    data: {
      numTimesFlagged,
      signals: filteredSignals,
    },
  };
};
