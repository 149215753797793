import { cn } from "@/lib/utils";

import { Popover } from "@/app/components";
import { ChipV2, ChipV2Props } from "@/app/components/v2/chip-v2.tsx";

interface TagsProps {
  badges?: BadgeType[];
  className?: string;
}

type BadgeType = {
  id: string;
  title?: string;
  value: string;
  group: BadgeStyleType;
};

type BadgeStyleType = "signal" | "fund" | "location" | "sectors" | "team" | "investors" | "other";

// quick hack for country badge
const getValue = (value: string, id: string): string => {
  if (id === "country") {
    return value
      .split(",")
      .map((v) => v.trim())
      .filter(Boolean)
      .join(", ");
  }
  return value;
};

export const Badges = ({ badges, className }: TagsProps) => {
  return (
    <div className={cn(`flex flex-wrap gap-x-1 gap-y-1.5 lg:gap-y-1.5`, className)}>
      {badges
        ?.filter(
          ({ value, group, id }) =>
            group !== "signal" && id !== "basecamp_investment" && id !== "portfolio_company" && value !== "N/A",
        )
        .map(({ value, id, title, group }, index) => {
          return (
            <Popover
              key={id + index}
              placement={"bottom"}
              childrenContent={
                title ? (
                  <p
                    className="text-sm font-medium"
                    dangerouslySetInnerHTML={{
                      __html:
                        String(title)
                          ?.replaceAll("<b>", '<b class="font-semibold">')
                          ?.replaceAll("<br/>", ' <b class="font-semibold">•</b> ') || "",
                    }}
                  />
                ) : null
              }
            >
              <ChipV2
                className={cn(title ? "cursor-pointer" : "cursor-default")}
                label={getValue(value, id)}
                color={badgeColors[group]}
              />
            </Popover>
          );
        })}
    </div>
  );
};

const badgeColors: Record<BadgeStyleType, ChipV2Props["color"]> = {
  signal: "magenta",
  fund: "green",
  location: "volcano",
  sectors: "day-blue",
  team: "orange",
  investors: "purple",
  other: "gray",
};

/*

MOBILE VERSION

<>
  <div
    onClick={() => {
      globalModalStore.openModal(
        {
          platform: "mobile",
          modalType: "OpportunityInfoMobile",
          navState: {
            mobileInfo: { title: title, info: value },
          },
        },
        2
      );
    }}
    className={cn(
      `my-1 mr-2 flex w-fit cursor-pointer items-center justify-center rounded-md border p-1.5 hover:bg-gray-100`,
      className
    )}
  >
    <Text
      text={value}
      weight={"light"}
      type={isBigTablet ? "body" : "message"}
    />
  </div>
</>
*/
