import { useState } from "react";

import toast from "react-hot-toast";

import {
  Action,
  Entity,
  GetCompaniesDocument,
  GetCompanyDocument,
  GetAddedByMeCompaniesDocument,
  GetAssignedCompaniesDocument,
  GetTrackingCompaniesDocument,
  GetUserHighlightsDocument,
} from "@/gql/graphql.ts";

import { Toast, Icon } from "@/app/components";
import { useOpportunityActionMutation } from "@/app/service/opportunities.gql.ts";

import refetchStore from "../stores/refetch-store";

export interface ActionMutationParams {
  entity?: Entity;
  defaultSuccessMessage?: string;
  onSuccess?: () => void;
}

export interface ActionPayload {
  entityId: string;
  reason?: string[];
  other?: string;
  resurface?: string;
  trelloColumn?: string;
  assignees?: string[];
  feedbackEmail?: string;
  successMessage?: string;
  fund?: string;
  action: Action;
}

export const useActionMutation = ({
  entity = Entity.Company,
  defaultSuccessMessage = "Action Completed",
  onSuccess,
}: ActionMutationParams) => {
  const [loading, setLoading] = useState(false);
  const [actionMutation, { error }] = useOpportunityActionMutation();

  const onSubmit = async ({
    entityId,
    reason,
    other,
    resurface,
    action,
    assignees,
    trelloColumn,
    feedbackEmail,
    successMessage,
    fund,
  }: ActionPayload) => {
    try {
      setLoading(true);
      const refetchQueries = refetchStore.getRefetchQueries([
        GetCompanyDocument,
        GetCompaniesDocument,
        GetAddedByMeCompaniesDocument,
        GetAssignedCompaniesDocument,
        GetTrackingCompaniesDocument,
        GetUserHighlightsDocument,
      ]);

      const response = await actionMutation({
        variables: {
          action,
          actionPayloadInput: {
            entityId,
            other,
            resurface,
            reason,
            trelloColumn,
            assignees,
            fund,
            feedbackEmail,
          },
          entity,
        },
        refetchQueries,
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
      });

      if (onSuccess) await onSuccess();

      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          subTitle={successMessage || defaultSuccessMessage}
          handleClose={() => toast.dismiss(t.id)}
        />
      ));

      return response;
    } finally {
      setLoading(false);
    }
  };
  return { onSubmit, loading, error };
};
