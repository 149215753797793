import React from "react";

import { cn } from "@/lib/utils.ts";

import { Popover } from "@/app/components";

export const CompanyLabelBadge = ({
  visible = false,
  className,
  text = "identified",
  infoText,
}: {
  visible?: boolean;
  text?: string;
  className?: string;
  infoText?: string;
}) => {
  return (
    <Popover
      childrenContent={infoText}
      placement="right"
      containerClassName={cn("pr-2", visible ? "pointer-events-auto" : "pointer-events-none")}
    >
      <div
        className={cn(
          "select-none rounded-[2px] bg-green-200 px-2 py-1 text-xs font-normal text-gray-900 opacity-0 transition-all duration-300 ease-in-out",
          visible && "opacity-100",
          className,
        )}
      >
        {text}
      </div>
    </Popover>
  );
};
