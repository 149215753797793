import { useState } from "react";

import { cn } from "@/lib/utils";

import { Icon, IconType } from "../icon/icon";

type EntityType = "company" | "user";
type AvatarProps<Entity extends EntityType> = {
  type: Entity;
  size?: "lg" | "md" | "sm" | "xs";
  src?: string | null;
  className?: string;
  initials?: Entity extends "user" ? string | null : never;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  fallbackIcon?: IconType;
};

const FallbackAvatar = <Entity extends EntityType>(
  props: Pick<AvatarProps<Entity>, "type" | "initials" | "size" | "fallbackIcon">,
) => {
  const { size } = props;
  if (props.type === "user" && props.initials) {
    return (
      <div
        className={cn(
          "font-semibold leading-none",
          size === "lg" && "text-2xl",
          size === "md" && "text-lg",
          size === "sm" && "text-sm",
          size === "xs" && "text-xs",
        )}
      >
        <span>{props.initials}</span>
      </div>
    );
  }

  return (
    <Icon
      type={props.fallbackIcon ?? (props.type === "user" ? "User" : "Company")}
      className={cn("text-gray-900", size === "lg" && "size-6", size === "md" && "size-4", size === "sm" && "size-3.5")}
    />
  );
};

export const AvatarV2 = <Entity extends EntityType>({
  size = "md",
  src,
  className,
  onClick,
  ...props
}: AvatarProps<Entity>) => {
  const [failedToLoad, setFailed] = useState(false);

  return (
    <div
      className={cn(
        "flex shrink-0 items-center justify-center overflow-hidden border border-gray-300 bg-white",
        size === "lg" && "size-16 rounded-sm",
        size === "md" && "size-10 rounded-xs",
        size === "sm" && "size-8 rounded-xxs",
        size === "xs" && "size-6 rounded-[2px]",
        onClick && "cursor-pointer hover:bg-gray-100 hover:opacity-70",
        className,
      )}
      onClick={onClick}
    >
      {src && !failedToLoad ? (
        <img alt={`${props.type} image`} className={"size-full"} src={src} onError={() => setFailed(true)} />
      ) : (
        <FallbackAvatar type={props.type} initials={props.initials} size={size} fallbackIcon={props.fallbackIcon} />
      )}
    </div>
  );
};
