import React, { PropsWithChildren } from "react";

import { cn } from "@/lib/utils.ts";

import { DatePicker, Input, Icon, Popover, DropdownV2 } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { TextAreaV2 } from "@/app/components/forms/textarea-v2.tsx";
import { FormSelect } from "@/app/components/input";
import { Metric, MetricDuplicate, metricsStore } from "@/app/screens/metrics-extraction";
import { parseMetricValue } from "@/app/screens/metrics-extraction/utils.ts";

const updateMetric = (label, metricUpdate: Partial<Metric>) => {
  if (metricUpdate.type && metricUpdate.value) {
    metricsStore.editMetric(label, metricUpdate);
    return;
  }
  metricsStore.editMetric(label, metricUpdate);
};

export const MetricValue = ({ metric }: { metric: Metric; focus?: boolean }) => {
  const duplicateExists = Boolean((metric?.duplicate as MetricDuplicate[])?.filter((m) => m.active).length);
  const manuallyDate = metric.source === "Manually Added";

  if (metric.type === "date") {
    return (
      <MetricSkeleton metric={metric} containerClassname={"px-6"}>
        <div className={"flex items-center justify-center space-x-3"}>
          <DatePicker
            className={"outline-none"}
            iconLeftClassName={"text-gray size-4"}
            maxDate={new Date()}
            selected={metric?.value ? new Date(metric.value) : new Date()}
            onChange={(date) => {
              if (date) updateMetric(metric.label, { value: date.toISOString() });
            }}
          />
          <DeactivateMetric label={metric.label} required={metric.required} duplicateIndex={undefined} />
        </div>
      </MetricSkeleton>
    );
  }

  const metricIconSymbol = metric.format === "currency" ? metric.formatInfo.currencySymbol : undefined;
  const value = parseMetricValue(metric.value, metric);

  if (metric.type === "boolean") {
    const metricValue = metric.value === "Yes";
    return (
      <MetricSkeleton
        metric={metric}
        containerClassname={cn(duplicateExists ? "m-3 rounded-sm bg-red-100 px-3 pt-3" : "px-6")}
      >
        <div className={"flex items-center gap-x-3"}>
          <FormSelect
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            disableInputFocus={true}
            canDelete={false}
            onChange={(value) => {
              updateMetric(metric.label, { value: value[0].value, type: metric.type });
            }}
            placeholder={"Select an option..."}
            styles={{ labelClassName: "bg-white px-0", inputClassName: "caret-transparent" }}
            defaultOptions={[{ value: metricValue, label: metric.value }]}
          />
          {typeof metric?.quotation === "string" && !manuallyDate && (
            <Popover
              childrenContent={
                <QuotationPopover quotation={metric.quotation} quotationSource={metric.quotationSource} />
              }
              placement={"right-end"}
            >
              <Icon
                type={"Eye"}
                onClick={() => {
                  metricsStore.selectQuotation(metric.quotationSource);
                  updateMetric(metric.label, { highlight: !metric.highlight });
                }}
                className={cn(
                  "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                  metric.highlight
                    ? "bg-gradient-to-r from-primary to-secondary text-white"
                    : "bg-gray-100 text-gray-600",
                )}
              />
            </Popover>
          )}
          <DeactivateMetric
            label={metric.label}
            required={metric.required}
            duplicateIndex={duplicateExists ? -1 : undefined}
          />
        </div>
        {duplicateExists
          ? (metric?.duplicate as MetricDuplicate[])
              ?.filter((m) => m.active)
              .map((duplicate, index) => {
                return (
                  <div key={index} className={"my-2 flex items-center gap-x-3"}>
                    <FormSelect
                      disableInputFocus={true}
                      canDelete={false}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      placeholder={"Select an option..."}
                      styles={{ labelClassName: "bg-white px-0", inputClassName: "caret-transparent" }}
                      defaultOptions={[{ value: duplicate.value === "Yes", label: duplicate.value }]}
                    />
                    {typeof duplicate?.quotation === "string" && duplicate.source !== "Manually Added" && (
                      <Popover
                        childrenContent={
                          <QuotationPopover
                            quotation={duplicate.quotation}
                            quotationSource={duplicate.quotationSource}
                          />
                        }
                        placement={"right-end"}
                      >
                        <Icon
                          type={"Eye"}
                          onClick={() => {
                            metricsStore.selectQuotation(duplicate.quotationSource);
                            updateMetric(metric.label, {
                              duplicate: metric.duplicate?.map((d, i) => ({ ...d, highlight: i === index })) || [],
                            });
                          }}
                          className={cn(
                            "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                            duplicate.highlight
                              ? "bg-gradient-to-r from-primary to-secondary text-white"
                              : "bg-gray-100 text-gray-600",
                          )}
                        />
                      </Popover>
                    )}
                    <DeactivateMetric label={metric.label} required={metric.required} duplicateIndex={index} />
                  </div>
                );
              })
          : null}
      </MetricSkeleton>
    );
  }

  return (
    <MetricSkeleton
      metric={metric}
      containerClassname={cn(duplicateExists ? "m-3 rounded-sm bg-red-100 px-3 pt-3" : "px-6")}
    >
      <div className={"flex items-center justify-center space-x-3"}>
        <Input
          value={value}
          placeholder={`Add a metric...`}
          className={duplicateExists ? "border-red" : ""}
          iconRight={typeof metric?.quotation === "string" && !manuallyDate ? "Eye" : undefined}
          iconRightOnClick={() => {
            metricsStore.selectQuotation(metric.quotationSource);
            updateMetric(metric.label, { highlight: !metric.highlight });
          }}
          iconRightPopoverLocation={"right-end"}
          iconRightPopover={<QuotationPopover quotation={metric.quotation} quotationSource={metric.quotationSource} />}
          iconRightClassName={cn(
            "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
            metric.highlight ? "bg-gradient-to-r from-primary to-secondary text-white" : "bg-gray-100 text-gray-600",
          )}
          iconLeft={metricIconSymbol}
          iconLeftComponent={
            metric.format === "currency" ? (
              <CurrencyDropDown
                icon={metricIconSymbol}
                setCurrency={(currencySymbol) => {
                  metricsStore.editMetric(metric.label, { formatInfo: { ...metric.formatInfo, currencySymbol } });
                }}
              />
            ) : null
          }
          iconLeftClassName={cn("!size-4", metric.format === "percentage" ? "text-gray" : "text-currency")}
          containerClassName={"w-full"}
          onChange={({ target: { value } }, ref) => {
            const parsedValue = parseMetricValue(value, metric);
            const cursorPositionFromEnd = value.length - ref.selectionStart;
            const newCursorPosition = parsedValue.length - cursorPositionFromEnd;

            updateMetric(metric.label, { value: parsedValue, type: metric.type });
            setTimeout(() => ref.setSelectionRange(newCursorPosition, newCursorPosition), 0);
          }}
        />
        <DeactivateMetric
          label={metric.label}
          required={metric.required}
          duplicateIndex={duplicateExists ? -1 : undefined}
        />
      </div>
      {duplicateExists
        ? (metric?.duplicate as MetricDuplicate[])
            ?.filter((m) => m.active)
            .map((duplicate, index) => {
              const duplicateValue = parseMetricValue(duplicate.value, metric);
              return (
                <div key={index} className={"my-2 flex items-center justify-center gap-y-2 space-x-3"}>
                  <Input
                    className={"border-red"}
                    value={duplicateValue}
                    iconRight={
                      typeof duplicate?.quotation === "string" && duplicate.source !== "Manually Added"
                        ? "Eye"
                        : undefined
                    }
                    iconRightOnClick={() => {
                      metricsStore.selectQuotation(duplicate.quotationSource);
                      updateMetric(metric.label, {
                        duplicate: metric.duplicate?.map((d, i) => ({ ...d, highlight: i === index })) || [],
                      });
                    }}
                    iconLeft={metricIconSymbol}
                    iconLeftClassName={cn(" !size-4", metric.format === "percentage" ? "text-gray" : "text-currency")}
                    iconRightPopoverLocation={"right-end"}
                    iconRightPopover={
                      <QuotationPopover quotation={duplicate.quotation} quotationSource={duplicate.quotationSource} />
                    }
                    iconRightClassName={cn(
                      "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                      duplicate.highlight
                        ? "bg-gradient-to-r from-primary to-secondary text-white"
                        : "bg-gray-100 text-gray-600",
                    )}
                    containerClassName={"w-full"}
                    onChange={({ target: { value } }) => {
                      updateMetric(metric.label, {
                        duplicate: metric.duplicate?.map((d, i) => ({ ...d, value: i === index ? value : d.value })),
                        type: metric.type,
                      });
                    }}
                  />
                  <DeactivateMetric label={metric.label} required={metric.required} duplicateIndex={index} />
                </div>
              );
            })
        : null}
      {duplicateExists && (
        <div className={"ml-1 flex items-center space-x-1"}>
          <Icon type={"AlertTriangle"} className={"size-4 text-red"} />
          <p className="text-xs font-medium text-red">
            Duplicate metrics detected. Remove any extra entries before submitting.
          </p>
        </div>
      )}
    </MetricSkeleton>
  );
};

const MetricSkeleton = ({
  metric,
  children,
  containerClassname,
}: { metric: Metric; containerClassname?: string } & PropsWithChildren) => (
  <div className={cn("flex flex-col py-3", containerClassname)}>
    <div className={"mb-2 flex gap-x-1"}>
      <span className="text-xs font-medium text-gray-700">{metric.label}</span>
      <span className="text-xs font-medium text-gray-400">{`(${metric.source})`}</span>
    </div>
    <>{children}</>
    <FormField label={"Add an optional note..."} className={"mt-2 w-full bg-transparent"}>
      <TextAreaV2
        value={metric.note}
        onChange={({ target: { value: note } }) => {
          updateMetric(metric.label, { note });
        }}
      />
    </FormField>
  </div>
);

const DeactivateMetric = ({ duplicateIndex, label, className = "", required }) => {
  if (required) return null;
  if (duplicateIndex !== undefined)
    return (
      <IconButtonV2
        icon={"Trash"}
        variant={"secondary"}
        onClick={() => {
          if (required) return;
          metricsStore.deleteDuplicate({ label, index: duplicateIndex });
        }}
      />
    );
  return (
    <IconButtonV2
      icon={"Sub"}
      variant={"secondary"}
      onClick={() => {
        updateMetric(label, { active: false });
      }}
    />
  );
};

const QuotationPopover = ({ quotation, quotationSource }) => {
  return (
    <div className={"flex max-w-[320px] flex-col space-y-2 p-2"}>
      <div className={"flex flex-col space-y-1"}>
        <span className="text-xs font-semibold text-gray-800">Excerpt</span>
        <span className="line-clamp-2 text-xs font-semibold italic">{`"${quotation}"`}</span>
      </div>
      <div className={"flex flex-col space-y-1"}>
        <span className="text-xs font-semibold text-gray-800">Sourced From</span>
        <div className={"flex items-center"}>
          <Icon type={quotationSource == "Email Body" ? "Mail" : "PDFIcon"} className={"mr-1 size-4 text-gray-600"} />
          <span className="line-clamp-1 text-xs font-medium text-gray-800">{quotationSource}</span>
        </div>
      </div>
    </div>
  );
};

const CurrencyDropDown = ({ icon, setCurrency }) => {
  return (
    <DropdownV2 className={cn("-ml-2 ")}>
      <DropdownV2.Button
        className="flex gap-0 bg-transparent px-0 outline-none lg:w-8"
        size={"sm"}
        onClick={(e) => e.stopPropagation()}
      >
        <Icon type={"Filter Chevron Down"} className={"size-3 px-0 text-black"} />
        <Icon type={icon} className={"size-4  px-0 text-currency"} />
      </DropdownV2.Button>
      <DropdownV2.Items className={"-right-4 z-40 mt-2 w-fit min-w-[70px] outline-none 2xl:-right-3.5"}>
        {[
          { label: "£ GBP", action: () => setCurrency("GBP") },
          { label: "$ USD", action: () => setCurrency("USD") },
          { label: "€ EUR", action: () => setCurrency("EUR") },
        ].map(({ label, action }) => (
          <DropdownV2.Item onClick={action} key={label}>
            <span className={cn("flex text-nowrap text-sm font-semibold first-letter:text-currency")}>{label}</span>
          </DropdownV2.Item>
        ))}
      </DropdownV2.Items>
    </DropdownV2>
  );
};
