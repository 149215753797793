import React, { PropsWithChildren, ReactNode } from "react";

import { Field, Label } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils";

type FormFieldProps = {
  label?: ReactNode;
  badge?: ReactNode;
  error?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
};

export function FormField({
  error,
  label,
  disabled,
  className,
  children,
  required,
  badge,
}: PropsWithChildren<FormFieldProps>) {
  return (
    <Field className={className} disabled={disabled}>
      {label && (
        <Label className={cn("mb-2 flex items-center text-sm font-medium text-gray-700")}>
          {label}
          {required && <span className="text-red">&nbsp;*</span>}
          {badge}
        </Label>
      )}
      {children}
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className={cn("mt-1 overflow-hidden text-xs font-medium text-red-500")}
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </Field>
  );
}
