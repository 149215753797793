import { ReactNode } from "react";

import { format } from "date-fns";
import { enGB } from "date-fns/locale";

import { LgMember } from "@/gql/graphql.ts";

import { getFullName } from "@/lib/utils.ts";

import { AvatarV2, Tooltip } from "@/app/components";

import { getNetworkSignalSourceLabel } from "./network-signal-utils.ts";
import { NetworkSignalData } from "./network-signal.types.ts";

const SignalDataValue = ({ label, value, onClick }: { label: string; value: ReactNode; onClick?: () => void }) => {
  return (
    <p
      className={
        "flex shrink-0 flex-col gap-1 text-sm font-medium first:col-span-2 lg:flex-row lg:gap-1 lg:first:col-span-1"
      }
      onClick={onClick}
    >
      <span className={"text-gray-600"}>{label}</span>
      <span className={"text-gray-900"}>{value}</span>
    </p>
  );
};

export type NetworkSignalView = {
  author: Pick<LgMember, "firstName" | "lastName" | "avatar">;
  note: string;
  attributes: {
    canConnect: boolean;
    isActivelyFundraising: boolean;
    isHighPriority: boolean;
    isDeepTech: boolean;
  };
};

const SourceName = ({ value }) => {
  // todo enable this after we launch network
  // const validator = z.string().email();
  // const emailType = validator.safeParse(value).success;

  const isEmailType = false;

  if (!isEmailType) {
    return value;
  }

  return (
    <a target="_blank" href={`/network/contact/${value}`} rel="noreferrer">
      {value}
    </a>
  );
};

export const NetworkSignalView = ({ signal }: { signal: NetworkSignalData }) => {
  const { note, attributes } = signal;
  const { avatar, firstName, lastName } = signal.createdBy || {};

  const name = getFullName(firstName, lastName);
  const sourceType = getNetworkSignalSourceLabel(signal.sourceType);

  return (
    <div className="@container">
      <div className={"mb-2 flex items-center gap-1 lg:mb-3"}>
        <span className={"text-base font-semibold text-black"}>
          {SourceName({ value: signal.sourceName })} <span className="font-medium text-gray-600">({sourceType})</span>
        </span>
      </div>
      <div className={"mt-3  grid grid-cols-1 lg:grid-cols-4"}>
        <SignalDataValue
          label={"Is the company actively fundraising?"}
          value={attributes.isActivelyFundraising ? "Yes" : "No"}
        />
        <SignalDataValue label={"Is this a high priority?"} value={attributes.isHighPriority ? "Yes" : "No"} />
      </div>
      {note ? (
        <Tooltip containerClassName="justify-start mt-3 h-4.5" text={note}>
          <p className={"line-clamp-1 cursor-default text-sm font-normal text-gray-800"}>&quot;{note}&quot;</p>
        </Tooltip>
      ) : (
        <p className="mt-3 h-4.5" />
      )}
      <div className="mt-3 flex items-center gap-2">
        <AvatarV2 type="user" src={avatar} size="sm" initials={`${firstName[0]}${lastName[0]}`} />
        <span className="text-sm font-medium">
          <span className="text-gray-900">{name}</span>&nbsp;
          <span className="text-gray-600">
            on {format(new Date(signal.lastUpdatedUtc || signal.createdUtc), "dd MMMM yyyy", { locale: enGB })}
          </span>
        </span>
      </div>
    </div>
  );
};
