import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

import { BaseToggle, Button, Tooltip } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { AddCompanyStep } from "@/app/screens/add-company/add-company-step.tsx";
import {
  CompanySignalSchema,
  useCompanySignalForm,
} from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { FundSelectControl } from "@/app/screens/add-company/company-signals-step/fund-select-control.tsx";
import { SignalFormFields } from "@/app/screens/add-company/company-signals-step/SignalFormFields.tsx";
import { useRecommendedFund } from "@/app/screens/add-company/company-signals-step/use-recommended-fund.ts";

export type AddCompanyStepProps = {
  onSubmit: (data: CompanySignalSchema) => void;
  onCancel: () => void;
  loading?: boolean;
};

export const CompanySignals = ({
  onSubmit,
  onCancel,
  loading,
  selectedDealroomCompanyId,
}: AddCompanyStepProps & {
  selectedDealroomCompanyId?: string;
}) => {
  const form = useCompanySignalForm();
  const { control, handleSubmit, formState, watch } = form;

  const recommendedFund = useRecommendedFund(selectedDealroomCompanyId);

  useEffect(() => {
    form.setValue("fund", recommendedFund.fund);
  }, [recommendedFund]);

  return (
    <AddCompanyStep onSubmit={handleSubmit(onSubmit)}>
      <AddCompanyStep.Header
        title="Complete company details"
        subtitle="Fill in the required details to finalise your submission."
      />
      <AddCompanyStep.Body>
        <Controller
          name={"fund"}
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              label="Is this a LocalGlobe, Latitude or Solar prospect?"
              error={fieldState.error?.message}
              className="w-full"
            >
              <FundSelectControl
                onChange={(fund) => field.onChange(fund)}
                value={field.value}
                recommended={recommendedFund}
              />
            </FormField>
          )}
        ></Controller>
        <SignalFormFields
          control={control}
          watch={watch}
          extraTogglesLeft={
            <Controller
              name={"alreadyMet"}
              control={control}
              render={({ field }) => (
                <FormField label="Have you already met the company?" className="shrink-0">
                  <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
                </FormField>
              )}
            />
          }
          extraTogglesRight={
            <Controller
              name={"isDeepTech"}
              control={control}
              render={({ field }) => (
                <FormField label={"Is this deep tech?"} className="shrink-0">
                  <Tooltip
                    containerClassName="block"
                    text={"Deep tech companies are enabled by scientific or engineering breakthroughs"}
                  >
                    <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
                  </Tooltip>
                </FormField>
              )}
            />
          }
        />
      </AddCompanyStep.Body>
      <AddCompanyStep.Actions>
        <Button
          text={"Finish"}
          type="submit"
          loading={loading}
          disabled={loading || !formState.isValid}
          variant={"gradient"}
          className="w-full px-10 lg:order-last lg:w-auto"
        />
        <Button text="Back" variant="outline" type="button" onClick={onCancel} className="w-full px-10 lg:w-auto" />
      </AddCompanyStep.Actions>
    </AddCompanyStep>
  );
};
