import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { opportunitiesStore } from "@/app/screens/opportunities";

export type OpportunityAction =
  | "ActionsList"
  | "Add"
  | "AddNew"
  | "AddSignal"
  | "AddTrello"
  | "Filter"
  | "FilterComponent"
  | "InfoMobile"
  | "Sentiment"
  | "SentimentRequest"
  | "Share"
  | "ShareSelect"
  | "Snooze"
  | "SnoozeSelect";

export function opportunityModal({
  id,
  action,
  extra,
}: {
  id: string;
  platform: "mobile" | "web";
  action: OpportunityAction;
  extra?: Record<string, unknown>;
}) {
  globalModalStore.navigate({
    modalType: action === "AddNew" ? action : (`Opportunity${action}` as any),
    navState: { ...extra, useCustomLoader: action === "AddNew" },
  });
  opportunitiesStore.setState({ currentOpportunity: id, extra });
}

export type ListType = Array<{
  icon: "CardMenu" | "Burger4";
  type: "card" | "list";
}>;
