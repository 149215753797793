import React from "react";

import { cn } from "@/lib/utils.ts";

import { SentimentBoxes } from "@/app/components/charts/sentiment-boxes.tsx";
import { SentimentHistory } from "@/app/components/charts/sentiment-history.tsx";
import { GlobalModal } from "@/app/screens/modal/global-modal.tsx";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import {
  SentimentBenchmarkHeader,
  SentimentHeader,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-header.tsx";
import { SentimentRadarChart } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-radar-chart.tsx";
import { useSentimentChart } from "@/app/screens/opportunities/opportunity/components/sentiment/use-sentiment-chart.tsx";

export const SentimentChart = ({ sentimentData }) => {
  const {
    isLaptop,
    selectedCompanies,
    setSelectedCompanies,
    referenceField,
    setReferenceField,
    selectedSentiment,
    setSelectedSentiment,
    radarChartData,
    tooltipChartData,
    maximised,
    company,
    setMaximised,
    dashboardSelector,
    benchmarkAttribute,
    fundSelected,
  } = useSentimentChart({
    sentimentData,
  });

  return (
    <div className="relative flex size-full flex-col justify-between">
      <div className={cn("mb-1 flex lg:mb-5", maximised ? "min-h-[4dvh] px-2" : "")}>
        {(!maximised || dashboardSelector?.value === "Time Series") && (
          <SentimentHeader
            company={company}
            selectedCompanies={selectedCompanies}
            setSelectedCompanies={setSelectedCompanies}
            onChange={setReferenceField}
            value={referenceField}
            className={cn(maximised && "px-4 lg:px-6")}
          />
        )}
        {maximised && dashboardSelector?.value === "Benchmark" && (
          <SentimentBenchmarkHeader maximised={maximised} setMaximised={setMaximised} />
        )}
      </div>
      <div
        className={cn(
          "flex max-w-full items-center justify-center lg:min-h-[400px]",
          maximised ? "h-[70dvh] border-t px-8" : "h-[500px]",
        )}
      >
        <>
          {(!maximised || dashboardSelector?.value === "Time Series") && (
            <SentimentHistory
              maximised={maximised}
              companySentiments={selectedCompanies?.map((comp) => comp?.sentimentForms?.nodes) || []}
              referenceField={referenceField}
              setActiveTooltip={setSelectedSentiment}
              style={isLaptop && !maximised ? { width: "calc(100% - 420px)" } : { width: "100%" }}
              onKeyPointClicked={({ datum, event }) => {
                // we probably don't need it but just in case
                // to prevent click out (modal close)
                event.preventDefault();
                event.stopPropagation();
                if (!isLaptop) return;
                const item = datum?.form;
                opportunityModal({
                  id: `sentiment_details`,
                  platform: window.innerWidth <= 976 ? "mobile" : "web",
                  action: "Sentiment",
                });

                opportunitiesStore.setModalState("sentiment_details", {
                  form: item,
                });
              }}
            />
          )}
          {maximised && dashboardSelector?.value === "Benchmark" && (
            <SentimentBoxes
              sentiment={selectedCompanies?.[0].sentimentAnalytics}
              benchmarkAttribute={benchmarkAttribute?.value}
              fundSelected={fundSelected?.value}
            />
          )}

          {isLaptop && !maximised && (
            <SentimentRadarChart
              className={"lg:min-h-[400px]"}
              style={{ width: "400px" }}
              chartMargin={{ top: 25, left: 0, right: 0, bottom: 55 }}
              activeTooltip={selectedSentiment}
              tooltipChartData={tooltipChartData}
              radarChartData={radarChartData}
            />
          )}
        </>

        {/*TODO: remove this shit :)*/}
        {maximised && <GlobalModal />}
      </div>
    </div>
  );
};
