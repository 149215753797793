import { MobileFab } from "@/app/components";
import { PageNavProvider } from "@/app/components/page-nav";
import modalStore from "@/app/stores/modal.store.tsx";

import { AddNewResponsiveModal } from "../modal/index.ts";

import { AddedByMeSection } from "./sections/add-by-me-section.tsx";
import { CompaniesForwardedSection } from "./sections/companies-forwarded/companies-forwarded-section.tsx";
import { CompanyUpdatesSection } from "./sections/company-updates/company-updates-section.tsx";
import { NotProgressingSection } from "./sections/feedback-email/feedback-template-section.tsx";
import { FollowingSection } from "./sections/following-section.tsx";
import { DashboardHeader } from "./sections/header/dashboard-header.tsx";
// import { UserHighlightSection } from "./sections/highlights/user-highlights-section.tsx";
import { ReviewingSection } from "./sections/reviewing-section.tsx";
import { SentimentRequestsSection } from "./sections/sentiment-form-requests/sentiment-requests-section.tsx";

// const navLinks = Object.values(dashboardSections);

export const Dashboard = () => {
  return (
    <PageNavProvider>
      <section id="home" className="w-full space-y-3 p-5 pt-10 font-medium lg:p-10 lg:pb-32">
        <DashboardHeader />
        <div className="flex flex-wrap-reverse space-x-3 lg:flex-nowrap">
          <div className="mt-8 w-full space-y-8 md:mt-12 md:space-y-12">
            {/*disabled as per https://linear.app/localglobe/issue/ENG-141/ui-design-review*/}
            {/*<UserHighlightSection />*/}
            <SentimentRequestsSection />
            <CompaniesForwardedSection />
            <CompanyUpdatesSection />
            <NotProgressingSection />
            <ReviewingSection />
            <FollowingSection />
            <AddedByMeSection />
          </div>
          {/*<Calendar />*/}
        </div>
        {/*<PageNav data-cy="dashboard-nav" navLinks={navLinks} />*/}
        {/* <JumpToTop hideOn={[dashboardSections.addedByMe.id]} /> */}
        <MobileFab
          className={"fixed bottom-7 right-7"}
          onClick={() => {
            modalStore.open(AddNewResponsiveModal);
          }}
        />
      </section>
    </PageNavProvider>
  );
};
