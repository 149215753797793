import React, { useMemo } from "react";

import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { differenceInMinutes, format } from "date-fns";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { cn, windowOpen } from "@/lib/utils.ts";

import { IconButton, ProfileImage, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { DocumentIcon } from "@/app/components/icon";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { ConfirmModal } from "@/app/screens/modal";
import {
  CompanyAttachmentsDocument,
  useDeleteCompanyAttachmentMutation,
} from "@/app/service/company-attachments.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { AttachmentFileNazare } from "./attachments.types";

const isPendingUpload = (data: CellContext<AttachmentFileNazare, any> | AttachmentFileNazare) => {
  let file: AttachmentFileNazare;
  if ("row" in data) {
    file = data.row.original;
  } else {
    file = data;
  }

  return file.status === "pending";
};
const pendingClassName = "opacity-30";

const UploadedByCell = (info: CellContext<AttachmentFileNazare, any>) => {
  const member = info.row.original.uploadedBy;

  if (!member) {
    return null;
  }

  const { firstName, lastName, avatar: image } = member;

  const isPending = isPendingUpload(info);

  const content = (
    <div className={cn("flex items-center gap-2", isPending && pendingClassName)}>
      <ProfileImage
        firstname={firstName}
        lastname={lastName}
        image={image}
        containerClass="!size-7 shrink-0 rounded-xs"
        imgClass="bg-gray-200 border border-gray-300"
      />
      <span className="overflow-hidden text-ellipsis text-nowrap">
        {firstName} {lastName}
      </span>
    </div>
  );

  return "url" in member && !!member.url ? (
    <Link to={member.url} target="_blank" className="flex items-center gap-1 overflow-hidden">
      {content}
    </Link>
  ) : (
    <div className="flex items-center gap-1 overflow-hidden">{content}</div>
  );
};

const ActionColumn = ({ file }: { file: AttachmentFileNazare }) => {
  const [deleteNazareFile, { loading: isDeletingFile }] = useDeleteCompanyAttachmentMutation({
    // refetch attachments
    refetchQueries: [CompanyAttachmentsDocument],
  });

  const handleOpen = () => {
    const row = file;

    if (row.status === "pending") {
      return;
    }

    row.getFileUrl().then((url) => {
      if (url) {
        windowOpen(url, "_blank");
      }
    });
  };

  const handleDelete = () => {
    const attachment = file;

    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will delete ${attachment.name}`,
        handleConfirm: () => {
          deleteNazareFile({ variables: { fileId: attachment.id } }).then(() => {
            toast.success("File has been deleted");
          });
        },
      },
    });
  };

  const DeleteBtn = isDeletingFile ? (
    <ProgressSpinner className="size-5" />
  ) : (
    <IconButtonV2 icon={"Trash"} size="sm" onClick={handleDelete} />
  );

  const isPending = isPendingUpload(file);
  const displayDeleteBtn = differenceInMinutes(new Date(), new Date(file.date)) > 1;

  if (isPending) {
    return (
      <div className="flex items-center gap-2">
        <ChipV2 label="Pending" color="orange" />
        {displayDeleteBtn && DeleteBtn}
      </div>
    );
  }

  return (
    <div className="flex justify-center gap-2">
      <IconButtonV2 icon={"ExternalLink"} size="sm" onClick={handleOpen} />
      {DeleteBtn}
    </div>
  );
};

const TextColumn = (info: CellContext<AttachmentFileNazare, any>) => {
  const isPending = isPendingUpload(info);

  return (
    <span className={cn("block truncate text-sm font-semibold", isPending && pendingClassName)}>{info.getValue()}</span>
  );
};

const columnHelper = createColumnHelper<AttachmentFileNazare>();

export const useAttachmentsColumns = () => {
  const { isTablet: largerThanTablet } = useBreakpoints();

  return useMemo(
    () => [
      columnHelper.accessor((row) => (row.date ? new Date(row.date) : null), {
        id: "date",
        header: "Added on",
        cell: (info) => {
          const value = info.getValue();
          const isPending = isPendingUpload(info);

          return value ? (
            <span className={cn("text-sm", isPending && pendingClassName)}>
              {format(new Date(value), "dd LLL YYY HH:mm")}
            </span>
          ) : null;
        },
        size: 175,
        enableSorting: true,
        sortingFn: (a, b) => {
           return +new Date(a.original.date) - +new Date(b.original.date);
        },
      }),
      columnHelper.accessor((row) => `${row.uploadedBy?.firstName} ${row.uploadedBy?.lastName}`, {
        header: "Added By",
        cell: UploadedByCell,
        enableResizing: false,
        enableSorting: true,
        size: 200,
      }),
      columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => {
          const isPending = isPendingUpload(info);
          return (
            <div className={cn("flex items-center gap-x-1", isPending && pendingClassName)}>
              <DocumentIcon ext={info.getValue()} className={cn("size-7 shrink-0")} />
              <span className="truncate text-sm font-medium">{info.getValue().toUpperCase()}</span>
            </div>
          );
        },
        size: 125,
      }),
      columnHelper.accessor("name", {
        header: "Name",
        cell: TextColumn,
        enableSorting: true,
        enableResizing: false,
        size: largerThanTablet ? 0 : 300,
      }),
      columnHelper.display({
        id: "actions",
        size: 135,
        cell: (props) => {
          return <ActionColumn file={props.row.original} />;
        },
      }),
    ],
    [largerThanTablet],
  );
};
