import React from "react";

import { cn } from "@/lib/utils.ts";

import { IconButton } from "@/app/components";
import { useDeleteComment } from "@/app/screens/opportunities/opportunity/components/comments/hooks";

import { CommentDisplayData } from "../comments.types.ts";

export const CommentActions = ({
  comment,
  setMode,
}: {
  comment: CommentDisplayData;
  setMode: (mode: "edit") => void;
}) => {
  const { handleDelete, isDeleting } = useDeleteComment();

  return (
    <div className="flex gap-2">
      <IconButton
        icon={"Pencil"}
        onClick={() => setMode("edit")}
        containerClassName={
          "h-8 w-8 p-2 hover:bg-gray-100 !rounded-sm disabled:text-gray-400 disabled:pointer-events-none"
        }
        disabled={isDeleting}
      />
      <IconButton
        icon={"Trash"}
        onClick={() => handleDelete(comment)}
        containerClassName={cn(
          "size-8 !rounded-sm p-2 hover:bg-gray-100 disabled:pointer-events-none disabled:text-gray-400",
          isDeleting && "animate-pulse",
        )}
        disabled={isDeleting}
      />
    </div>
  );
};
