import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetDealroomCompanyFundingQueryVariables = Types.Exact<{
  companyId: Types.Scalars["BigInt"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type GetDealroomCompanyFundingQuery = {
  __typename?: "Query";
  dealroomFundingRounds?: {
    __typename?: "DealroomFundingRoundsConnection";
    nodes: Array<{
      __typename?: "DealroomFundingRound";
      id: number;
      round?: string | null;
      year?: number | null;
      month?: number | null;
      amountUsdMillion?: any | null;
    }>;
  } | null;
};

export type DealroomCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars["BigInt"]["input"];
  includeFunding?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  includeFounders?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  includeIndustries?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  includeLocations?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type DealroomCompanyQuery = {
  __typename?: "Query";
  dealroomCompany?: {
    __typename?: "DealroomCompany";
    id: any;
    name?: string | null;
    about?: string | null;
    image?: string | null;
    tagline?: string | null;
    websiteUrl?: string | null;
    hqLocations?: any | null;
    industries?: any | null;
    totalFunding?: any | null;
    fundingRounds?: {
      __typename?: "DealroomFundingRoundsConnection";
      nodes: Array<{
        __typename?: "DealroomFundingRound";
        id: number;
        round?: string | null;
        year?: number | null;
        month?: number | null;
        amount?: any | null;
        currency?: string | null;
        amountUsdMillion?: any | null;
        amountSource?: any | null;
      }>;
    };
    founders?: {
      __typename?: "DealroomCompanyTeamsConnection";
      nodes: Array<{
        __typename?: "DealroomCompanyTeam";
        titles?: any | null;
        member?: {
          __typename?: "DealroomFounder";
          id: any;
          name?: string | null;
          image?: string | null;
          linkedinUrl?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export const GetDealroomCompanyFundingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealroomCompanyFunding" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigInt" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomFundingRounds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "EnumValue", value: "YEAR_DESC" },
                    { kind: "EnumValue", value: "MONTH_DESC" },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "round" } },
                      { kind: "Field", name: { kind: "Name", value: "year" } },
                      { kind: "Field", name: { kind: "Name", value: "month" } },
                      { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetDealroomCompanyFundingQuery__
 *
 * To run a query within a React component, call `useGetDealroomCompanyFundingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealroomCompanyFundingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealroomCompanyFundingQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDealroomCompanyFundingQuery(
  baseOptions: Apollo.QueryHookOptions<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables> &
    ({ variables: GetDealroomCompanyFundingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export function useGetDealroomCompanyFundingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export function useGetDealroomCompanyFundingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDealroomCompanyFundingQuery,
    GetDealroomCompanyFundingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDealroomCompanyFundingQuery, GetDealroomCompanyFundingQueryVariables>(
    GetDealroomCompanyFundingDocument,
    options,
  );
}
export type GetDealroomCompanyFundingQueryHookResult = ReturnType<typeof useGetDealroomCompanyFundingQuery>;
export type GetDealroomCompanyFundingLazyQueryHookResult = ReturnType<typeof useGetDealroomCompanyFundingLazyQuery>;
export type GetDealroomCompanyFundingSuspenseQueryHookResult = ReturnType<
  typeof useGetDealroomCompanyFundingSuspenseQuery
>;
export type GetDealroomCompanyFundingQueryResult = Apollo.QueryResult<
  GetDealroomCompanyFundingQuery,
  GetDealroomCompanyFundingQueryVariables
>;
export const DealroomCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DealroomCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigInt" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "includeFunding" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "includeFounders" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "includeIndustries" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "includeLocations" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "about" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "tagline" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hqLocations" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: { kind: "Variable", name: { kind: "Name", value: "includeLocations" } },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "industries" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: { kind: "Variable", name: { kind: "Name", value: "includeIndustries" } },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalFunding" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: { kind: "Variable", name: { kind: "Name", value: "includeFunding" } },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "fundingRounds" },
                  name: { kind: "Name", value: "dealroomFundingRoundsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "YEAR_DESC" },
                          { kind: "EnumValue", value: "MONTH_DESC" },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: { kind: "Variable", name: { kind: "Name", value: "includeFunding" } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "round" } },
                            { kind: "Field", name: { kind: "Name", value: "year" } },
                            { kind: "Field", name: { kind: "Name", value: "month" } },
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "currency" } },
                            { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
                            { kind: "Field", name: { kind: "Name", value: "amountSource" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "founders" },
                  name: { kind: "Name", value: "dealroomCompanyTeamsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "isFounder" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "BooleanValue", value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: { kind: "Variable", name: { kind: "Name", value: "includeFounders" } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "titles" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "member" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                  { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useDealroomCompanyQuery__
 *
 * To run a query within a React component, call `useDealroomCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealroomCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealroomCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      includeFunding: // value for 'includeFunding'
 *      includeFounders: // value for 'includeFounders'
 *      includeIndustries: // value for 'includeIndustries'
 *      includeLocations: // value for 'includeLocations'
 *   },
 * });
 */
export function useDealroomCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<DealroomCompanyQuery, DealroomCompanyQueryVariables> &
    ({ variables: DealroomCompanyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealroomCompanyQuery, DealroomCompanyQueryVariables>(DealroomCompanyDocument, options);
}
export function useDealroomCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealroomCompanyQuery, DealroomCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealroomCompanyQuery, DealroomCompanyQueryVariables>(DealroomCompanyDocument, options);
}
export function useDealroomCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealroomCompanyQuery, DealroomCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealroomCompanyQuery, DealroomCompanyQueryVariables>(DealroomCompanyDocument, options);
}
export type DealroomCompanyQueryHookResult = ReturnType<typeof useDealroomCompanyQuery>;
export type DealroomCompanyLazyQueryHookResult = ReturnType<typeof useDealroomCompanyLazyQuery>;
export type DealroomCompanySuspenseQueryHookResult = ReturnType<typeof useDealroomCompanySuspenseQuery>;
export type DealroomCompanyQueryResult = Apollo.QueryResult<DealroomCompanyQuery, DealroomCompanyQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
