import { useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router";

import { metricsStore } from "@/app/screens/metrics-extraction/metrics-extraction.store.ts";
import { useNzrOpportunityEmailSuspenseQuery } from "@/app/service/emails.gql.ts";
import { useUpsertMetricsExtractionMutation } from "@/app/service/metrics.gql.ts";

export const useMetricsExtraction = () => {
  const { id = "new" } = useParams();
  const { state } = useLocation();
  const { emailOnly, emailId } = state || { emailOnly: false };

  const navigate = useNavigate();
  const { data } = useNzrOpportunityEmailSuspenseQuery({
    variables: { id: emailId || id },
    skip: id === "new",
  });
  const [upsertMetrics, { loading, error }] = useUpsertMetricsExtractionMutation();

  const selectedCompanyId = data?.nzrOpportunityEmail?.metadata?.process_update_email?.data?.company_id;
  const extractedMetrics = data?.nzrOpportunityEmail?.metadata?.process_metrics_extraction_update?.data;
  const bodyMetrics = extractedMetrics?.body?.metrics?.map((e) => ({ ...e, quotationSource: "Email Body" })) || [];

  const attachmentsMetrics =
    extractedMetrics?.attachments?.reduce((acc, attachment) => {
      if (!attachment.metrics) return acc;
      return [...acc, ...(attachment?.metrics?.map((e) => ({ ...e, quotationSource: attachment.filename })) || [])];
    }, []) || [];

  const metrics = [...attachmentsMetrics, ...bodyMetrics];

  useEffect(() => {
    if (selectedCompanyId) {
      metricsStore.setState({ selectedCompanyId });
    }

    metricsStore.initMetrics({
      metrics,
      category: "manual",
    });
  }, [id, data]);

  useEffect(() => {
    return () => {
      metricsStore.setState({ selectedCompanyId: null });
    };
  }, []);

  return { email: data?.nzrOpportunityEmail, navigate, loading, error, upsertMetrics, emailOnly };
};
