import React from "react";

import { Row } from "@tanstack/react-table";

import { LgCompany } from "@/gql/graphql";

import { cn, formatFundingMil } from "@/lib/utils";

import { Fund } from "@/app/hooks";

import { NotAvailable } from "../../opportunities/components/not-available";

import { Column } from "./types";
import { CellWrapper, CompanyCellWrapper, RunwayCellWrapper } from "./wrapper";

// todo refactor
export const mapInvestmentColumnToLabel = (column: Column) => {
  switch (column) {
    case "name":
    case "company":
      return "Company";
    case "latestExcitementScore":
      return "Latest excitement";
    case "investmentType":
      return "Type";
    case "initialInvestment":
      return "Initial invested";
    case "initialEv":
      return "Going in post. money";
    case "totalInvestedCapital":
      return "Total invested";
    case "currentMultiple":
      return "V/C";
    case "currentEv":
      return "Current EV";
    case "currentNav":
      return "Current NAV";
    case "evAsFundReturner":
      return "EV to be fund returner";
    case "investedCapitalFromFund":
      return "Invested from fund";
    case "fundName":
      return "Fund";
    case "totalNav":
      return "Total Nav";
    case "unrealisedValue":
      return "Unrealised NAV";
    case "mostRecentRound":
      return "Last round";
    case "goingInOwnership":
      return "Going in Ownership";
    case "currentOwned":
      return "Current Ownership";
    case "lastRound":
      return "Last Round";
    case "grossMargins":
      return "Gross Margins";
    case "cashoutGroup":
      return "Cash runway";
    case "latestExcitement":
      return "Latest Excitement";
    case "revenue":
      return "Revenue";
    default:
      return column;
  }
};

export const isFooterRow = (row: Row<any>) => {
  return row?.depth > 0 && row?.index === row?.getParentRow()?.subRows?.length - 1;
};

// todo refactor
export const mapInvestmentColumnToValue = ({
  column,
  value,
  row,
  className = "",
}: {
  column: Column;
  value: string | number | LgCompany;
  row?: any;
  className?: string;
}): React.ReactNode => {
  if (value === undefined || value === null)
    return <NotAvailable message="" containerStyle="w-full" textStyle={cn("text-center", className)} />;

  const isFooter = isFooterRow(row);

  switch (column) {
    case "company":
      return <CompanyCellWrapper row={row} company={value as LgCompany} />;
    case "cashoutGroup":
      return <RunwayCellWrapper value={value} />;
    case "initialInvestment":
    case "initialEv":
    case "currentNav":
    case "totalInvestedCapital":
    case "unrealisedValue":
    case "totalNav":
      return (
        <CellWrapper id={column} className={cn("text-center", className)} isFooter={isFooter}>
          <span className="text-currency">£</span>&nbsp;
          {isNaN(Number(value)) ? "0" : formatFundingMil(Number(value) / 1000000, 3)}
        </CellWrapper>
      );
    case "goingInOwnership":
    case "investedCapitalFromFund":
    case "initialInvestmentRatio":
    case "grossMargins":
    case "currentOwned":
      return (
        <CellWrapper
          id={column}
          isFooter={isFooter}
          className={cn("text-center", className)}
          scalarValue={`${!isNaN(Number(value)) ? (Number(value) * 100).toFixed(1) : "0"}%`}
        />
      );
    case "currentEv":
    case "evAsFundReturner":
    case "revenue":
    case "lastRound":
      return (
        <CellWrapper isFooter={isFooter} id={column} className={cn("text-center", className)}>
          <span className="text-currency">£</span>&nbsp;
          {isNaN(Number(value)) ? "0" : formatFundingMil(Number(value), 3)}
        </CellWrapper>
      );
    case "currentMultiple":
      return (
        <CellWrapper
          id={column}
          isFooter={isFooter}
          className={cn("text-center", className)}
          scalarValue={`${Number(value).toFixed(1)}x`}
        />
      );
    case "latestExcitementScore":
      return (
        <CellWrapper id={column} isFooter={isFooter} className={cn("text-center", className)}>
          {Number(value).toFixed(2)}
        </CellWrapper>
      );
    default:
      return <CellWrapper id={column} className={className} isFooter={isFooter} scalarValue={value.toString()} />;
  }
};

export const groupByCompany = ({ id, funds }: { id: string; funds: Fund[] }) => {
  return funds?.filter(({ company }) => company?.id === id);
};
