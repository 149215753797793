import { parseCompanyDetails } from "@/lib/utils.ts";

import { Signal as SignalType } from "@/app/types";

import { AttachmentsCommentsData, useAttachmentsComments } from "./attachments-comments/use-attachments-comments.ts";
import { ExcitementScoreData, useExcitementScore } from "./excitement-score/use-excitement-score.tsx";
import { CompanyDataForHighlights } from "./highlights.types.ts";
import {
  InvestmentBasecampFundsData,
  useInvestedBasecampFunds,
} from "./invested-basecamp-funds/use-invested-basecamp-funds.ts";
import { LatestReturnProfileData, useLatestReturnProfile } from "./latest-return-profile/use-latest-return-profile.ts";
import { PcgLeads, usePcgLeads } from "./pcg-leads/use-pcg-leads.ts";

type UseHighlightsResponse = {
  pcgLeads: PcgLeads | null;
  excitementScore: ExcitementScoreData | null;
  latestReturnProfile: LatestReturnProfileData | null;
  investedBasecampFunds: InvestmentBasecampFundsData | null;
  attachmentsComments: AttachmentsCommentsData | null;
};

export type HighlightsProps = {
  portCo?: boolean;
  company: CompanyDataForHighlights;
  signal: SignalType;
};

export const useHighlights = ({ company, signal }: HighlightsProps): UseHighlightsResponse => {
  const { assignees, portCo, sentiments, previouslyMet, basecamp } = parseCompanyDetails(company);

  return {
    pcgLeads: usePcgLeads(assignees, portCo),
    excitementScore: useExcitementScore(sentiments, previouslyMet, portCo),
    latestReturnProfile: useLatestReturnProfile(sentiments),
    investedBasecampFunds: useInvestedBasecampFunds(basecamp),
    attachmentsComments: useAttachmentsComments(),
  };
};
