import React, { FormEventHandler, PropsWithChildren } from "react";

const Header = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <div className="w-full max-w-[796px] px-5 pt-8 lg:px-0 lg:pt-10">
      <h1 className="text-2xl font-bold text-neutral-900 lg:text-3xl">{title}</h1>
      <h2 className="mt-4 hidden text-base font-medium text-gray-600 lg:block">{subtitle}</h2>
    </div>
  );
};

const Body = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex w-full justify-center px-5 lg:px-0">
      <div className="w-full max-w-[796px] space-y-8">{children}</div>
    </div>
  );
};

const Actions = ({ children }: PropsWithChildren) => {
  return (
    <div className="mt-auto flex w-full max-w-[796px] flex-col items-center justify-between gap-2 p-5 shadow sm:shadow-none lg:mt-8 lg:flex-row lg:p-0 lg:pb-16">
      {children}
    </div>
  );
};

const Root = ({ onSubmit, children }: PropsWithChildren<{ onSubmit: FormEventHandler }>) => {
  return (
    <form className="relative flex h-full flex-col items-center gap-8 lg:gap-10" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export const AddCompanyStep = Object.assign(Root, {
  Header,
  Body,
  Actions,
});
