import { GetAddedByMeCompaniesDocument, LgCompanyStatus } from "@/gql/graphql.ts";

import { usePaginatedQuery } from "@/app/hooks";
import { authStore } from "@/app/stores/auth.store";

export const useAddedByMeCompanies = (creatorId: string, status: LgCompanyStatus = LgCompanyStatus.Ready) => {
  const queryVariables = {
    userId: authStore.userId,
    creator: creatorId,
    status,
    pageSize: 6,
    after: null,
  };

  const {
    data,
    loading,
    loadLess,
    loadMore: refetch,
    loadingLess,
    loadingMore,
  } = usePaginatedQuery({
    query: GetAddedByMeCompaniesDocument,
    rootEntity: "lgCompanies",
    variables: queryVariables,
    options: { fetchPolicy: "cache-and-network" },
  });

  const loadMore = () => {
    const endCursor = data?.lgCompanies?.pageInfo.endCursor;

    if (refetch) {
      refetch(queryVariables, endCursor);
    }
  };

  if (!data?.lgCompanies) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingMore: false,
      loadingLess: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.lgCompanies.totalCount,
    items: data.lgCompanies.edges.map(({ node }) => node),
    pageInfo: data.lgCompanies.pageInfo,
    loading: loading && !loadingLess,
    loadingMore,
    loadingLess,
    loadLess,
    loadMore,
  };
};
