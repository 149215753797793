import { formatNumber } from "@/lib/utils.ts";

import { Metric, MetricType } from "@/app/screens/metrics-extraction/metrics-extraction.store.ts";

export type MetricLabelType = { label: string; value: { type: MetricType; format?: string; formatInfo?: any } };

export const metricTypes: Array<MetricLabelType> = [
  { label: "Text", value: { type: "string" } },
  { label: "Number", value: { type: "number" } },
  { label: "Date", value: { type: "date", format: "date" } },
  { label: "Yes/No", value: { type: "boolean", format: "boolean" } },
  { label: "GBP", value: { type: "number", format: "currency", formatInfo: { currencySymbol: "GBP" } } },
  { label: "USD", value: { type: "number", format: "currency", formatInfo: { currencySymbol: "USD" } } },
  { label: "Percentage", value: { type: "number", format: "percentage" } },
];

export const getMetricType = (item) => {
  if (item.format === "currency") return "Currency";
  if (item.format === "percentage") return "Percentage";
  if (item.format === "boolean") return "Yes/No";
  if (item.type === "date") return "Date";
  if (item.type === "number") return "Number";

  return "Text";
};

export function escapeRegExp(text) {
  return text?.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
}

export function parseMetricValue(value: boolean | number | string | Date, metric: Metric) {
  if (metric.type === "date" && typeof value !== "boolean") {
    return value ? new Date(value) : new Date();
  }
  if (metric.type === "number") {
    if (metric.format !== "percentage") {
      value = formatNumber(cleanupNumber(value.toString()).replaceAll(",", "")); //value.toString().replace(/[^,.0-9]/g, "");
    } else {
      if (metric.value && !value.toString().includes("%")) {
        value = formatNumber(cleanupNumber(value.toString().slice(0, -1)).replace(/[,%]/g, "")) + "%"; //value.toString().replace(/[^,.0-9]/g, "");
      }
      value = formatNumber(cleanupNumber(value.toString()).replace(/[,%]/g, "")) + "%"; //value.toString().replace(/[^,.0-9]/g, "");
    }
  }
  if (metric.type === "boolean") {
    return metric.value === "Yes";
  }

  return value;
}

function cleanupNumber(input: string) {
  // Use regex to remove all characters that are not numbers, commas, or dots
  let cleaned = input.replace(/[^0-9,.]/g, "");

  // Remove any duplicate commas or dots
  cleaned = cleaned.replace(/[,.]{2,}/g, ",");

  // Ensure proper formatting:
  // If more than one dot exists, keep the first one and replace subsequent dots with commas
  const dotIndex = cleaned.indexOf(".");
  if (dotIndex !== -1) {
    cleaned = cleaned.slice(0, dotIndex + 1) + cleaned.slice(dotIndex + 1).replace(/\./g, ",");
  }

  return cleaned;
}
