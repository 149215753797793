import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers";
import { text } from "@/app/styles";
import { Sentiment } from "@/app/types";

export interface ExcitementProps {
  excitement: number;
  lastSentimentResponse: Sentiment;
  className?: string;
}

export const Excitement = ({ excitement, lastSentimentResponse, className }: ExcitementProps) => {
  return (
    <div className={`mb-4 text-left ${className}`}>
      <div className={"mb-1 flex items-center justify-between gap-2"}>
        <div className={"flex items-center gap-3"}>
          <span className={cn("text-sm font-semibold", text.subTitleText)}>Excitement</span>
          <span className={cn("text-lg font-semibold text-primary", text.titleText)}>
            {String(Number(excitement.toFixed(1)))}
          </span>
          <div className={"flex items-center gap-1"}>
            <Icon type={"Increase"} className={"size-4.5 text-gray xl:size-6"} />
            <span className={cn("text-sm font-semibold text-green", text.body)}>1.0</span>
          </div>
        </div>
      </div>
      <span className={cn(`mr-2 inline-block text-left text-sm font-medium`, text.message)}>
        {lastSentimentResponse.category || ""}
      </span>
      <span className={cn(`inline-block text-left text-xs font-medium text-gray`, text.message)}>
        {normaliseDate(lastSentimentResponse.createdAt)!}
      </span>
    </div>
  );
};
