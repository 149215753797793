import { QueryReference, useReadQuery } from "@apollo/client";

import {
  SearchCompaniesDealroomQuery,
  SearchCompaniesDealroomQueryVariables,
  SearchCompaniesLiteQuery,
  SearchCompaniesLiteQueryVariables,
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables,
} from "../service/opportunities.gql";

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type LgCompanies = NonNullable<SearchCompaniesLocalGlobeQuery["results"]>;
export type LgEdges = LgCompanies["nodes"];
export type Company = ArrayElement<LgEdges>;

export type SearchRef = QueryReference<
  | SearchCompaniesLocalGlobeQuery
  | SearchCompaniesLocalGlobeQueryVariables
  | SearchCompaniesLiteQuery
  | SearchCompaniesLiteQueryVariables
  | SearchCompaniesDealroomQuery
  | SearchCompaniesDealroomQueryVariables
>;

export const useSearchRef = (searchRef: SearchRef) => {
  const { data, error } = useReadQuery(searchRef);
  return {
    result: [...(data?.results?.nodes || [])],
    error,
  };
};
