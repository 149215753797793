import { PropsWithChildren, ReactNode } from "react";

import { cn } from "@/lib/utils";

import { Button, ProgressSpinner } from "@/app/components";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay.tsx";
import { PageNavSection } from "@/app/components/page-nav";

import { EmptySection } from "./empty-section";

type DashboardSectionProps = {
  id: string; // needed for nav bar and anchor scrolling
  title?: string;
  info?: string;
  count?: number;
  headerSlot?: ReactNode;
  loading?: boolean;
  emptyPlaceholder?: {
    enabled: boolean;
    message: string;
  };
  loadMoreBtn?: {
    enabled?: boolean;
    onLoadMore?: () => void;
    loading?: boolean;
  };
  loadLessBtn?: {
    enabled?: boolean;
    onLoadLess?: () => void;
    loading?: boolean;
  };
};

export const DashboardSection = ({
  id,
  title,
  info,
  count,
  emptyPlaceholder,
  children,
  headerSlot,
  loadMoreBtn,
  loadLessBtn,
  loading,
}: PropsWithChildren<DashboardSectionProps>) => {
  const showLoadButtons = loadMoreBtn?.enabled || loadLessBtn?.enabled;

  return (
    <PageNavSection id={id} anchorClassName={"-top-5"}>
      <div className="flex items-center gap-2">
        <h2 className="text-xl font-semibold text-neutral-800">{title}</h2>
        <span className="rounded-sm bg-white px-2 text-neutral-600">
          {loading ? <ProgressSpinner className="my-1.5 size-3" /> : count}
        </span>
        {info}
        {headerSlot}
      </div>
      <div className="mt-4">
        {children}
        {emptyPlaceholder?.enabled && <EmptySection message={emptyPlaceholder.message} />}
        {loading && count === 0 && (
          <div className="relative mb-8 h-32 w-full">
            <LoadingOverLay className="!rounded-base" />
          </div>
        )}
      </div>
      {showLoadButtons && (
        <div className="flex justify-center">
          <div className="mx-auto mt-4 flex w-auto items-center justify-center rounded-sm bg-white">
            {loadMoreBtn?.enabled && (
              <Button
                variant="primary"
                size="sm"
                className={cn("min-w-16", loadLessBtn?.enabled ? "rounded-e-none" : "")}
                onClick={loadMoreBtn.onLoadMore}
                loading={loadMoreBtn.loading}
              >
                Show more
              </Button>
            )}
            {loadLessBtn?.enabled && loadMoreBtn?.enabled && !loadLessBtn.loading && !loadMoreBtn.loading && (
              <span className="h-3/5 border-l text-neutral-400" />
            )}
            {loadLessBtn?.enabled && (
              <Button
                variant="primary"
                size="sm"
                className={cn("min-w-16", loadMoreBtn?.enabled ? "rounded-s-none" : "")}
                onClick={async () => {
                  await loadLessBtn.onLoadLess?.();
                  document.getElementById(id)?.scrollIntoView();
                }}
                loading={loadLessBtn.loading}
              >
                Show less
              </Button>
            )}
          </div>
        </div>
      )}
    </PageNavSection>
  );
};
