import { useMemo } from "react";

import { Fund } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { DealroomCompanyQuery, useDealroomCompanyQuery } from "@/app/service/dealroom-company.gql.ts";

export type RecommendedFundData = {
  fund: Fund;
  details?: DealroomCompanyQuery["dealroomCompany"];
};

const funds = [
  {
    value: Fund.LocalGlobe,
    recommendedRounds: ["SEED", "ANGEL", "PRE-SEED"],
  },
  {
    value: Fund.Latitude,
    recommendedRounds: ["SERIES A", "SERIES B"],
  },
  {
    value: Fund.Solar,
    recommendedRounds: ["SERIES D", "SERIES E", "SERIES F", "SERIES G", "SERIES H", "SERIES I", "LATE GROWTH"],
  },
];

export const useRecommendedFund = (companyId?: string | null): RecommendedFundData => {
  const { data: dealroomFundingData } = useDealroomCompanyQuery({
    variables: {
      companyId,
      includeFunding: true,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    const latestRound = dealroomFundingData?.dealroomCompany?.fundingRounds?.nodes[0];

    if (!latestRound?.round) {
      return { fund: Fund.LocalGlobe };
    }

    const matchingFund = funds.find((fund) => fund.recommendedRounds.includes(latestRound.round!))?.value;

    return { fund: matchingFund ?? Fund.LocalGlobe, details: dealroomFundingData?.dealroomCompany };
  }, [dealroomFundingData]);
};
