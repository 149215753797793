import { Fragment, Suspense, useMemo } from "react";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { X } from "lucide-react";
import { observer } from "mobx-react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { LoadingOverLay } from "@/app/components";
import type { ModalRoutesType } from "@/app/screens/modal/global-modal.store.ts";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { CompanyLink } from "@/app/screens/modal/link/company-link-web.tsx";
import { RequestSentimentWeb } from "@/app/screens/modal/opportunities/request-sentiment-web.tsx";
import { SentimentDialogWeb } from "@/app/screens/modal/opportunities/sentiment-dialog-web.tsx";
import { SuccessModal } from "@/app/screens/modal/success.tsx";

const ModalRoutesDictionary: Partial<Record<ModalRoutesType, (...args) => JSX.Element>> = {
  "": () => <></>,
  Success: SuccessModal,
  CompanyLink: CompanyLink,
  OpportunitySentiment: SentimentDialogWeb,
  OpportunitySentimentRequest: RequestSentimentWeb,
};

export const GlobalModal = observer(() => {
  const { isBigTablet } = useBreakpoints();

  const {
    isOpen,
    modalType,
    navState: {
      form,
      customModal = false,
      modalDialogClassName = "bg-white",
      modalDialogContainer = "",
      useCustomLoader = false,
      company,
    },
  } = globalModalStore.state;
  const ModalContent = ModalRoutesDictionary[modalType];

  const content = useMemo(() => {
    if (!ModalContent) return null;

    if (customModal) {
      return (
        <DialogPanel className={modalDialogClassName}>
          <ModalContent form={form} onClose={globalModalStore.toggleModal} />
        </DialogPanel>
      );
    }

    if (useCustomLoader) {
      return (
        <DialogPanel
          className={cn(
            `xxl:w-[50dvw] flex max-w-[920px] rounded-sm transition-all md:h-[700px] lg:w-[1028px]`,
            modalDialogClassName,
          )}
        >
          <ModalContent company={company} />
        </DialogPanel>
      );
    }

    return (
      <DialogPanel
        className={cn(
          `xxl:w-[50dvw] relative !z-40 flex max-w-[920px] rounded-sm bg-white transition-all md:h-[700px] lg:w-[1028px]`,
          modalDialogClassName,
        )}
        style={{ pointerEvents: "all" }}
      >
        <X
          className={"absolute right z-20 mr-3 mt-5 cursor-pointer active:text-neutral-400"}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            globalModalStore.toggleModal();
          }}
        />
        <Suspense fallback={<LoadingOverLay className="size-full" />}>
          <ModalContent form={form} />
        </Suspense>
      </DialogPanel>
    );
  }, [customModal, useCustomLoader, company, ModalContent, modalDialogClassName, form]);

  if (!isBigTablet) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => {
          globalModalStore.toggleModal({ navState: { customModal } });
        }}
      >
        <TransitionChild
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-hidden">
          <div className={cn(`flex h-dvh w-dvw items-center justify-center p-4 text-center`, modalDialogContainer)}>
            <TransitionChild
              as={"div"}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {content}
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});
