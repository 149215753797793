import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { AvatarV2 } from "../avatar";

import { BoxTypeLabel } from "./box-type-label";
import { CardActions } from "./card-actions";
import { CardBody } from "./card-body";
import { CardFooter } from "./card-footer";
import { CardHeader } from "./card-header";
import { CardLabel } from "./card-label";
import { CardScore } from "./card-score";
import { CardTitle } from "./card-title";

type CardProps = {
  className?: string;
  onClick?: () => void;
  "data-cy"?: string;
};

/**
 * This should be a simple container for the cards inside grids like dashboard/details page etc.
 * Please think twice before adding any custom code here like hover effects, transitions or other props since it's going to affect whole app
 * Simply use className if you want to customize it in some place
 * Important! Do not use group in the className since it's going to affect child components which rely on group-* pseudo classes like 'group-hover:'
 */
const Root = ({ className, children, onClick, ...props }: PropsWithChildren<CardProps>) => {
  return (
    <div
      onClick={onClick}
      {...props}
      className={cn("rounded-sm border border-gray-200 bg-white p-3 lg:p-5", className)}
    >
      {children}
    </div>
  );
};

export const Card = Object.assign(Root, {
  BoxType: BoxTypeLabel,
  Label: CardLabel,
  Score: CardScore,
  Title: CardTitle,
  Image: AvatarV2,
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter,
  Actions: CardActions,
});
