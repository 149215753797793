import { Key, ReactElement, ReactNode, SyntheticEvent } from "react";

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { IconType, ResponsiveHorizontalList } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";

import { ChipV2 } from "../v2/chip-v2";

type EnsureArray<T> = T extends any[] ? T : T[];

type ExtractFromArray<TValue> = TValue extends (infer U)[] ? U : TValue;

type SelectV2Option<TValue> = {
  label: string;
  value: ExtractFromArray<TValue>;
  disabled?: boolean;
};

type SelectV2Props<TValue, TMultiple extends boolean | undefined> = {
  multiple?: TMultiple;
  value: TMultiple extends true ? EnsureArray<TValue> : TValue;
  icon?: IconType;
  onChange: (value: (TMultiple extends true ? EnsureArray<TValue> : TValue) | null) => void;
  options: SelectV2Option<TValue>[];
  buttonClassName?: string;
  placeholder?: string;
  prefix?: string;
} & (ExtractFromArray<TValue> extends object
  ? // require getDisplayValue if value is an object/array type
    {
      getDisplayValue: (value: ExtractFromArray<TValue>) => ReactElement | string | number;
    }
  : { getDisplayValue?: (value: ExtractFromArray<TValue>) => ReactElement | string | number }) &
  // if value is an object or array of objects, make getOptionKey required
  (ExtractFromArray<TValue> extends object
    ? {
        getOptionKey: (optionValue: ExtractFromArray<TValue>) => string | number;
      }
    : { getOptionKey?: (optionValue: ExtractFromArray<TValue>) => string | number });

export const SelectV2 = <TValue, TMultiple extends boolean | undefined>(props: SelectV2Props<TValue, TMultiple>) => {
  const { icon, value, options, multiple, placeholder, onChange, getDisplayValue, getOptionKey, buttonClassName } =
    props;

  const handleClear = (e: SyntheticEvent) => {
    if (multiple === true) {
      e.preventDefault();
      e.stopPropagation();
      (props as SelectV2Props<TValue, true>).onChange([] as EnsureArray<TValue>);
    } else {
      onChange(null);
    }
  };

  const handleRemoveItem = (valueToRemove: ExtractFromArray<TValue>) => {
    if (multiple === true && Array.isArray(value)) {
      const { onChange, value: currentValue } = props as SelectV2Props<TValue, true>;

      onChange(currentValue.filter((val) => val !== valueToRemove) as EnsureArray<TValue>);
    }
  };

  const getLabel = (val?: ExtractFromArray<TValue>): ReactNode => {
    if (!val) {
      return;
    }

    if (typeof getDisplayValue === "function") {
      return getDisplayValue(val);
    }

    if (typeof val === "object") {
      console.warn("getDisplayValue is required for object values");
      // todo sentry?
    }

    const option = options.find((option) => option.value === val);

    return option?.label ?? (typeof val === "string" || typeof val === "number" ? val : "-");
  };

  const displayValue =
    multiple === true && Array.isArray(value) ? (
      <ResponsiveHorizontalList
        className="w-[calc(100%-24px)]"
        popoverProps={{
          className: "rounded-xs bg-neutral-100 hover:bg-neutral-100 px-2 text-xxs px-2 py-[3px] space-y-1",
          childrenClassName: "space-y-1",
        }}
      >
        {value.map((v) => (
          <ChipV2
            key={v}
            label={v}
            color={"gray"}
            className={"transition duration-300 ease-in group-hover/select:brightness-90"}
            iconRight={
              <IconButtonV2
                icon={"X"}
                variant="secondary"
                className="ml-1 !size-4 !rounded-xxs bg-gray-100 !p-0.5"
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleRemoveItem(v);
                }}
              />
            }
          />
        ))}
      </ResponsiveHorizontalList>
    ) : (
      getLabel(value as ExtractFromArray<TValue>)
    );

  const getKey = (option: SelectV2Option<TValue>, index: number): Key => {
    if (typeof getOptionKey === "function") {
      return getOptionKey(option.value);
    }

    return typeof option.value === "string" || typeof option.value === "number" ? option.value : index;
  };

  const isEmpty = Array.isArray(value) ? value.length === 0 : !value;

  return (
    <Listbox value={value} onChange={onChange} multiple={multiple}>
      {({ open }) => (
        <>
          <ListboxButton
            as={"div"}
            className={cn(
              "flex h-10 w-full cursor-pointer items-center justify-between overflow-hidden rounded-xxs border border-gray-300 bg-white px-2.5 pr-1.5 text-sm font-medium",
              "hover:bg-gray-300",
              "transition-colors duration-300 ease-in",
              "group/select",
              buttonClassName,
            )}
          >
            {props.prefix}
            {isEmpty ? placeholder : displayValue}
            <IconButtonV2
              icon={icon || open ? "Chevron Up" : "Chevron Down"}
              size="xs"
              variant="secondary"
              onClick={handleClear}
              className="shrink-0 transition duration-300 ease-in group-hover/select:brightness-90"
            />
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--button-width)] rounded-xxs border bg-white font-medium focus:outline-none",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "!max-h-64 shadow-sm",
            )}
          >
            {options.map((option, index) => (
              <ListboxOption
                key={getKey(option, index)}
                value={option.value}
                disabled={option.disabled}
                className={cn(
                  "flex select-none items-center gap-2 p-3 text-sm",
                  "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                )}
              >
                {option.label}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </>
      )}
    </Listbox>
  );
};
