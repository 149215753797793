import { useEffect } from "react";
import { formatDate, numberFormat } from "../format";
import { Column, SortDirection, columns, sortPairValueToString } from "./columns";

export type TableHeaderCellProps = {
  column: Column;
  sortColumn: Column;
  sortDirection: SortDirection;
};

export function TableHeaderCell(props: TableHeaderCellProps) {
  const { sortColumn, sortDirection } = props;
  const columnInfo = columns.get(props.column);
  if (!columnInfo) throw new Error(`Unsupported column ${props.column}`);
  let classes = "px-4 py-4 font-medium text-neutral text-left bg-white whitespace-nowrap cursor-pointer select-none ";
  if (columnInfo.extraTheadClasses) {
    classes += columnInfo.extraTheadClasses;
  }

  let nextDirection: SortDirection;
  let imgStyle: Record<string, string>;
  if (sortColumn === props.column) {
    if (sortDirection === SortDirection.Asc) {
      nextDirection = SortDirection.Desc;
      imgStyle = { transform: "scaleY(-1)" };
    } else {
      imgStyle = {};
      nextDirection = SortDirection.Asc;
    }
  } else {
    nextDirection = SortDirection.Asc;
    imgStyle = { visibility: "hidden" };
  }
  const nextSort = sortPairValueToString(props.column, nextDirection);

  return (
    <th className={classes} data-column-next-sort={nextSort}>
      {columnInfo.title}{" "}
      <img
        className="inline transition-transform duration-200 ease-in-out"
        style={imgStyle}
        src="/assets/icons/arrow-sort-desc.svg"
      />
    </th>
  );
}

export type TableHeaderProps = {
  sortColumn: Column;
  sortDirection: SortDirection;
};

export function TableHeader(props: TableHeaderProps) {
  const { sortColumn, sortDirection } = props;
  return (
    <thead className="hidden shadow-sm @5xl:table-row-group sticky top-[127px] lg:top-[65px] z-10">
      <tr className=" leading-tight">
        {[...columns].map(([column]) => (
          <TableHeaderCell key={column} column={column} sortColumn={sortColumn} sortDirection={sortDirection} />
        ))}
      </tr>
    </thead>
  );
}

export type TableProps = {
  sortColumn: Column;
  sortDirection: SortDirection;
  contacts: Contact[];
  loading: boolean;
};

export function Table(props: TableProps) {
  const { sortColumn, sortDirection, contacts, loading } = props;
  return (
    <table className="divide-y flex w-full @5xl:table text-[14px]">
      <TableHeader sortColumn={sortColumn} sortDirection={sortDirection} />
      <tbody className="grid grid-cols-1 gap-2 @3xl:grid-cols-2 @5xl:grid-cols-3 @5xl:table-row-group @5xl:divide-y @5xl:bg-white @5xl:gap-0">
        {!contacts.length && !loading ? <NoData /> : contacts.map((contact) => (
          <Contact key={contact.email} {...contact} />
        ))}
      </tbody>
      <tfoot className="hidden @5xl:table-row-group">
        <tr>
          <th colSpan={7} className="p-2 rounded-b-md bg-white"></th>
        </tr>
      </tfoot>
    </table>
  );
}

export function NoData() {
   return <tr><td colSpan={7} className="p-4 text-center font-[16px] font-medium text-neutral">No matches found. Try adjusting your search.</td></tr>;
}

export type Contact = {
  email: string;
  name: string[];
  tags: string[];
  totalTo: number;
  totalFrom: number;
  lastTo: string;
  lastFrom: string;
  connections: string[];
  connectionStrength: number;
};

export type ContactProps = Contact & { key: string };

export function Contact(props: ContactProps) {
  const fakeHeaderClasses = "font-medium text-neutral text-[12px] @5xl:hidden";

  // NOTE: connectionStrength is a real number [0,1]
  // we need to show low/med/high baced on linear percentile value
  // i.e. [0-33] [33-66] [66-100]
  const connectivityIcons = ["low", "medium", "high"];
  const connectivityIconType = connectivityIcons[Math.min(2, Math.floor((props.connectionStrength || 0) * 3))];
  return (
    <tr
      className="flex flex-wrap p-2 rounded-md bg-white card w-full overflow-hidden  @5xl:table-row cursor-pointer relative @5xl:p-0"
      data-contact-email={props.email}
      key={props.email}
    >
      <td className="flex flex-col p-2 mr-7 @5xl:w-[200px] @7xl:w-[350px] overflow-hidden @5xl:px-2 @5xl:p-4 w-full  @5xl:table-cell @5xl:mr-0 @5xl:pl-4">
        <div className="line-clamp-1 overflow-hidden text-ellipsis break-all w-auto font-semibold leading-normal text-black">
          {props.email}
        </div>
        <div className="line-clamp-1 font-[500] overflow-hidden text-ellipsis break-all text-neutral-700 sm:text-sm">
          {props.name.join(", ")}
        </div>
      </td>
      <td className="flex absolute top-6 right-6 @3xl:top-4 @3xl:right-4 @5xl:px-2 @5xl:p-4 @5xl:text-center @5xl:w-[1px] @5xl:table-cell @5xl:static">
        <img className="inline" src={`/assets/icons/connectivity-${connectivityIconType}.svg`} />
      </td>
      <td className="flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @7xx:p-4 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Incoming</div>
        <div>{formatDate(props.lastFrom)}</div>
      </td>
      <td className="flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @5xl:p-4 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Outgoing</div>
        <div>{formatDate(props.lastTo)}</div>
      </td>
      <td className="flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-4 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell ">
        <div className={fakeHeaderClasses}># Incoming</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-incoming.svg" />
          {numberFormat.format(props.totalFrom)}
        </div>
      </td>{" "}
      <td className="flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-4 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell">
        <div className={fakeHeaderClasses}># Outgoing</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" />
          {numberFormat.format(props.totalTo)}
        </div>
      </td>
      <td className="flex flex-col p-2 w-full @5xl:pl-4 @5xl:p-4 @5xl:table-cell @5xl:w-auto">
        <div className={fakeHeaderClasses}>Connections</div>
        <div className="line-clamp-2  font-[500] overflow-hidden text-ellipsis text-neutral-700 sm:text-sm leading-normal">
          <span className="bg-neutral-100 px-2 py-1 rounded-sm text-center text-xs text-neutral w-auto">
            {props.connections.length}
          </span>{" "}
          {props.connections.join(", ")}
        </div>
      </td>
    </tr>
  );
}
