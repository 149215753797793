import React, { PropsWithChildren } from "react";

import { usePageNav } from "@/app/components/page-nav/page-nav-provider.tsx";

import { GenericPageNavSection } from "./generic-page-nav-section";

type PageNavSectionProps = {
  id: string;
  className?: string;
  anchorClassName?: string;
};
export const PageNavSection = (props: PropsWithChildren<PageNavSectionProps>) => {
  const { handleSectionEnter: onViewEnter, handleSectionLeave: onViewLeave } = usePageNav();

  return <GenericPageNavSection onViewEnter={onViewEnter} onViewLeave={onViewLeave} {...props} />;
};
