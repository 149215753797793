import React, { useEffect } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router";

import { capitalize, cn } from "@/lib/utils.ts";

import { CompanyImg, Icon, Popover } from "@/app/components";
import { Routes } from "@/app/constants";
import { parentFund } from "@/app/misc/funds.ts";
import { titleCase } from "@/app/misc/helpers.ts";
import { investmentFunds, investmentStore } from "@/app/screens/investments";
import {
  CellType,
  ColumnHeader,
  RowWithBar,
  RowWithCurrency,
  RowWithMultiplier,
  RowWithPercentage,
  TableHeader,
  TableText,
} from "@/app/screens/investments/components/mtp-table.tsx";
import { getRunwayColors } from "@/app/screens/investments/components/wrapper.tsx";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { getCashoutGroup } from "@/app/screens/opportunities/opportunity/components/highlights/cash-profile/cash-profile.tsx";
import { useMeetThePortfolioSuspenseQuery } from "@/app/service/opportunities.gql.ts";

type OverviewKey =
  | "Fund Size"
  | "Vintage"
  | "Capital Called"
  | "Capital Invested"
  | "Gross Unrealised NAV"
  | "Gross Realised NAV"
  | "Gross Multiple"
  | "Total Unrealised Value"
  | "Total Realised Value"
  | "Total Cost"
  | "V/C"
  | "Net TVPI"
  | "Net DPI";

type StatisticsKey = "Median Initial Cheque" | "Median Going in Ownership" | "Median Going in Post Money";

type PortfolioMakeupKey = "Total Fund Investments" | "Geography" | "Sectors" | "Fund Investments";

type TotalInvestmentsKey = "Core" | "Strategic";

type GeographyKey = "NPA" | "EMEA EXCL. NPA";

type SectorKey =
  | "Business Operations & Automations"
  | "Finance & Payments"
  | "Education"
  | "Real Estate & Construction"
  | "Retail & E-Commerce"
  | "Media & Entertainment"
  | "AI & Machine Learning"
  | "Insurance"
  | "Other"
  | string;

const columnTextClass = "text-center items-center justify-center";

const tags = {
  LG: { value: "LocalGlobe", color: "bg-[#E9E9E9]" },
  LT: { value: "Latitude", color: "bg-[#E9E9E9]" },
  SL: { value: "Solar", color: "bg-[#E9E9E9]" },
  UC: { value: "Unicorn", color: "bg-[#C2E5FF]" },
  C: { value: "Colt", color: "bg-[#FFB2EE]" },
  TB: { value: "Thoroughbred", color: "bg-[#FFB2EE]" },
  FC: { value: "FutureCorn", color: "bg-[#C2E5FF]" },
  CO: { value: "Core", color: "bg-[#C2E5FF]" },
  S: { value: "Strategic", color: "bg-[#C2E5FF]" },
};

export type MeetThePortfolio = {
  company: CellType;
  navToFundSizePercentage: CellType;
  evToFundReturn: CellType;
  revenue: CellType;
  yoyGrowth: CellType;
  marginPercentage: CellType;
  fcf: CellType;
  fte: CellType;
  runway: CellType;
  followOnRaised: CellType;
  capitalConcentration: CellType;
};

export const mtpHover = {
  "Fund Overview": `Info and metrics at the fund level, showing the most recent data alongside quarter on quarter ("QoQ") movements and year on year ("YoY") movements`,
  "Fund Size": `This shows the total commitments from LPs which represents the total fund size`,
  Vintage: `The year the fund started`,
  "Capital Called": `This shows as a % how much of the total commitments have been called from LPs into the fund`,
  "Capital Invested": `This shows as a % how much of the total fund size has been invested`,
  "Total Unrealised Value": `Total value of companies that remains in the fund`,
  "Total Realised Value": `Total value of companies that have been sold`,
  "Total Cost": `Total amount of cash that has been invested into companies`,
  "V/C": `Value divided by cost`,
  "Net TVPI": `Total Value to Paid In; the ratio of the net asset value of the fund plus the value of all distributions to date, relative to the total amount of called capital.`,
  "Net DPI": `Distributed to Paid In; the ratio of distributions relative to called capital.`,
  "Fund Insights": `A few stats across our core investments`,
  "Portfolio Makeup": `This shows how we've constructed our portfolio by type of investment, geography and sector`,
  Focus: `Companies that have value that is greater than 10% of the fund size`,
  Emerging: `Companies that have value between 5% and 10% of fund size`,
  Radar: `Company has an average excitement score of more than 8.5 in the previous 12 months.\n More than three distinct people have given sentiment feedback in the last 12 months`,
  FocusBC: "Company in at least 3 Basecamp funds",
  EmergingBC: "Company in at least 2 Basecamp funds and in New Palo Alto",
  RadarBC: "Company in at least 1 Basecamp fund, New Palo Alto and target funds are Latitude and Solar",
};

// not exporting this function as it is only used here - linter is shit
// eslint-disable-next-line react-refresh/only-export-components
const CompanyHeader = ({ noResults, tableType, activeFund }: any) => {
  return (
    <div className={cn("absolute left-4.5 top-3 flex items-center")}>
      <span className={"text-lg font-semibold text-neutral-800 lg:mr-2"}>{tableType}</span>
      {!noResults && (
        <Popover
          placement={"right"}
          childrenContent={mtpHover[`${tableType}${activeFund === "BC" ? "BC" : ""}`]}
          childrenClassName={"ml-2.5 z-10"}
        >
          <Icon type={"Info"} className={"mr-2.5 size-4.5 text-neutral-300"} />
        </Popover>
      )}
    </div>
  );
};

function sortingFn(rowA, rowB, columnId) {
  if (columnId === "capitalConcentration") {
    return Number(rowA.original[columnId].value.split(":")[0]) > Number(rowB.original[columnId].value.split(":")[0])
      ? 1
      : -1;
  }
  if (Number.isNaN(Number(rowA.original[columnId].value))) {
    return rowA.original[columnId].value > rowB.original[columnId].value ? 1 : -1;
  }
  return Number(rowA.original[columnId].value) > Number(rowB.original[columnId].value) ? 1 : -1;
}

const columns: (
  navigate: (id: string) => void,
  tableData: MeetThePortfolio[],
  fund: string,
) => ColumnDef<MeetThePortfolio>[] = (navigate, tableData, fund) => {
  if (fund === "BC") {
    return [
      {
        accessorKey: "company",
        header: CompanyHeader,
        cell: (info) => {
          const { value, extra } = info.renderValue<CellType>();
          return (
            <div className={"flex items-center pl-2.5"}>
              <CompanyImg
                onClick={() => navigate(`${Routes.company}/${extra.id}`)}
                className={"mr-2.5 size-10 min-w-10 cursor-pointer rounded-sm hover:opacity-60"}
                imgClassName={"rounded-none"}
                src={extra?.image}
              />
              <div className={"flex flex-col justify-between"}>
                <span
                  className={
                    "mb-0.5 line-clamp-1 cursor-pointer select-none text-sm font-semibold text-neutral-800 hover:opacity-60"
                  }
                  onClick={() => navigate(`${Routes.company}/${extra.id}`)}
                >
                  {String(value)}
                </span>
                <div className={"flex space-x-0.5"}>
                  {extra?.tags.map((tag: string) => (
                    <Popover
                      key={tag}
                      childrenContent={tags[tag]?.value || tag}
                      childrenClassName={"mt-2"}
                      containerClassName={cn("size-4 rounded-xxs", tags[tag]?.color || "bg-[#E9E9E9]")}
                    >
                      <span className={"p-1 text-xxxs font-semibold"}>{tag}</span>
                    </Popover>
                  ))}
                </div>
              </div>
            </div>
          );
        },
        enableSorting: false,
        extraProps: { w: "min-w-[180px] max-w-[180px]" },
      },
      {
        accessorKey: "dealroomScore",
        header: () => (
          <TableHeader containerClassName={"lg:w-16 text-nowrap"} label={"Dealroom Score"} drawHeader={false} />
        ),
        cell: (info) => {
          const { value } = info.renderValue<CellType>();
          return (
            <span
              className={cn("text-nowrap text-center font-medium text-neutral-800 lg:text-xs 2xl:text-sm")}
            >{`${Number(value).toFixed(1)}`}</span>
          );
        },
        enableSorting: true,
        extraProps: { w: "w-[80px]" },
        sortingFn,
      },
      {
        accessorKey: "basecampFunds",
        header: () => (
          <TableHeader containerClassName={"lg:w-16 text-nowrap"} label={"Basecamp Funds"} drawHeader={false} />
        ),
        cell: (info) => {
          const { value } = info.renderValue<CellType>();
          return (
            <div className={"align-center flex justify-center space-x-0.5"}>
              {((value || []) as Array<{ id; name; image }>)?.map(({ id, name, image }) => (
                <Popover
                  key={id}
                  childrenContent={name}
                  childrenClassName={"mt-2"}
                  containerClassName={cn("rounded-xxs")}
                >
                  <CompanyImg
                    className={"mr-1 size-6 min-w-6 cursor-pointer !rounded-xs hover:opacity-60"}
                    imgClassName={"rounded-none"}
                    src={image}
                  />
                </Popover>
              ))}
            </div>
          );
        },
        enableSorting: false,
        sortingFn,
      },
      {
        accessorKey: "lastRound",
        header: () => <TableHeader label={"Last Round"} drawHeader={false} />,
        cell: (info) => {
          const { value } = info.renderValue<CellType>();
          return (
            <TableText
              label={titleCase(String(value))}
              className="text-center"
              containerClassName={"flex justify-center items-center"}
            />
          );
        },
        enableSorting: true,
        extraProps: { w: "w-[120px]" },
        sortingFn,
      },
      {
        accessorKey: "hq",
        header: () => <TableHeader label={"City"} drawHeader={false} />,
        cell: (info) => {
          const { value } = info.renderValue<CellType>();
          return (
            <TableText label={value} className="text-center" containerClassName={"flex justify-center items-center"} />
          );
        },
        enableSorting: false,
        extraProps: { w: "w-[100px]" },
        sortingFn,
      },
      {
        accessorKey: "sectors",
        header: () => <TableHeader label={"Sectors"} containerClassName={"w-[200px]"} drawHeader={false} />,
        cell: (info) => {
          const { value } = info.renderValue<CellType>();
          return (
            <TableText
              className="text-start"
              label={(value as unknown as string[])?.join(", ")}
              containerClassName={"flex justify-start items-center "}
            />
          );
        },
        enableSorting: false,
        sortingFn,
      },
    ];
  }
  return [
    {
      accessorKey: "company",
      header: CompanyHeader,
      cell: (info) => {
        const { value, extra } = info.renderValue<CellType>();
        return (
          <div className={"flex min-w-[180px] max-w-[180px] items-center pl-2.5"}>
            <CompanyImg
              onClick={() => navigate(`${Routes.company}/${extra.id}`)}
              className={"mr-2.5 size-10 min-w-10 cursor-pointer rounded-sm hover:opacity-60"}
              imgClassName={"rounded-none"}
              src={extra?.image}
            />
            <div className={"flex flex-col justify-between"}>
              <span
                className={
                  "mb-0.5 line-clamp-1 cursor-pointer select-none text-sm font-semibold text-neutral-800 hover:opacity-60"
                }
                onClick={() => navigate(`${Routes.company}/${extra.id}`)}
              >
                {String(value)}
              </span>
              <div className={"flex space-x-0.5"}>
                {extra?.tags.map((tag: string) => (
                  <Popover
                    key={tag}
                    childrenContent={tags[tag]?.value || tag}
                    childrenClassName={"mt-2"}
                    containerClassName={cn("size-4 rounded-xxs", tags[tag]?.color || "bg-[#E9E9E9]")}
                  >
                    <span className={"p-1 text-xxxs font-semibold"}>{tag}</span>
                  </Popover>
                ))}
              </div>
            </div>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "navToFundSizePercentage",
      header: () => (
        <TableHeader
          containerClassName={"lg:w-16"}
          label={"NAV\n/ Fund Size (%)"}
          header={
            <ColumnHeader
              label={"TOTAL"}
              value={tableData.reduce(
                (acc, { navToFundSizePercentage }) => acc + Number(navToFundSizePercentage.value),
                0,
              )}
              valueType={"percentage"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return <RowWithPercentage label={value} change={change} containerClassName={columnTextClass} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "evToFundReturn",
      header: () => (
        <TableHeader
          label={"EV to Return Fund"}
          containerClassName={"lg:w-16"}
          header={
            <ColumnHeader
              label={"CORE AVERAGE"}
              value={
                tableData.reduce((acc, { evToFundReturn }) => {
                  if (evToFundReturn?.extra?.isCore && evToFundReturn.value) return acc + Number(evToFundReturn.value);
                  return acc;
                }, 0) /
                tableData.filter(({ evToFundReturn }) => {
                  return evToFundReturn.value && evToFundReturn?.extra?.isCore;
                }).length
              }
              valueType={"currency"}
              extraProps={{ unit: "billion" }}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return <RowWithCurrency label={value} change={change} containerClassName={columnTextClass} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "capitalConcentration",
      header: () => (
        <TableHeader
          label={"Capital Conc."}
          containerClassName={"w-12"}
          header={
            <ColumnHeader
              label={"AVERAGE"}
              value={`${Math.round(
                tableData.reduce(
                  (acc, { capitalConcentration }) => acc + Number(String(capitalConcentration?.value)?.split(":")?.[0]),
                  0,
                ) / tableData.filter(({ marginPercentage }) => Boolean(marginPercentage.value)).length,
              )}:1`}
              valueType={"number"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value } = info.renderValue<CellType>();
        return <TableText label={value} containerClassName={cn(columnTextClass)} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "revenue",
      header: () => (
        <TableHeader
          label={"Revenue"}
          containerClassName={"lg:w-14"}
          header={
            <ColumnHeader
              label={"TOTAL"}
              value={tableData.reduce((acc, { revenue }) => acc + Number(revenue.value), 0)}
              valueType={"currency"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return <RowWithCurrency label={value} change={change} containerClassName={columnTextClass} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "yoyGrowth",
      header: () => (
        <TableHeader
          label={"YoY Growth"}
          containerClassName={"lg:w-12 2xl:w-16"}
          header={
            <ColumnHeader
              label={"AVERAGE"}
              value={
                tableData.reduce((acc, { yoyGrowth }) => acc + Number(yoyGrowth.value), 0) /
                tableData.filter(({ yoyGrowth }) => Boolean(yoyGrowth.value)).length
              }
              valueType={"percentage"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return <RowWithPercentage label={value} change={change} containerClassName={columnTextClass} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "marginPercentage",
      header: () => (
        <TableHeader
          label={"Margin"}
          containerClassName={"lg:w-12 xl:w-16"}
          header={
            <ColumnHeader
              label={"AVERAGE"}
              value={
                tableData.reduce((acc, { marginPercentage }) => acc + Number(marginPercentage.value), 0) /
                tableData.filter(({ marginPercentage }) => Boolean(marginPercentage.value)).length
              }
              valueType={"percentage"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return <RowWithPercentage label={value} change={change} containerClassName={columnTextClass} />;
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "fcf",
      header: () => <TableHeader label={"FCF"} containerClassName={"lg:w-6 xl:w-10"} />,
      cell: (info) => {
        const { value } = info.renderValue<CellType>();
        return (
          <TableText
            label={value === true ? "Yes" : value === false ? "No" : undefined}
            containerClassName={columnTextClass}
          />
        );
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "fte",
      header: () => (
        <TableHeader
          label={"FTEs"}
          containerClassName={"lg:w-6 xl:w-10"}
          header={
            <ColumnHeader
              label={"TOTAL"}
              value={tableData.reduce((acc, { fte }) => acc + Number(fte.value), 0)}
              valueType={"number"}
            />
          }
        />
      ),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        return (
          <TableText
            label={Number.isNaN(Number(value)) ? undefined : Number(value).toFixed(0)}
            containerClassName={columnTextClass}
            change={change}
          />
        );
      },
      enableSorting: true,
      sortingFn,
    },
    {
      accessorKey: "runway",
      header: observer(() => {
        const { isOpen } = globalModalStore.navbarState;
        return <TableHeader label={"Runway"} containerClassName={cn(isOpen ? "w-16" : "w-32")} />;
      }),
      cell: (info) => {
        const { value, change } = info.renderValue<CellType>();
        const { bg, text } = getRunwayColors(String(value));
        return (
          <TableText
            label={value}
            className={cn("rounded-xs p-1 !text-xs", bg, text, "min:w-16")}
            containerClassName={cn(columnTextClass, "justify-center")}
            change={change}
          />
        );
      },
      enableSorting: true,
      sortingFn,
    },
    // {
    //   accessorKey: "followOnRaised",
    //   header: () => (
    //     <TableHeader
    //       label={"Follow on Raised"}
    //       containerClassName={"lg:w-10 2xl:w-12 "}
    //       header={
    //         <ColumnHeader
    //           label={"TOTAL"}
    //           value={tableData.reduce((acc, { followOnRaised }) => acc + Number(followOnRaised.value), 0)}
    //           valueType={"currency"}
    //         />
    //       }
    //     />
    //   ),
    //   cell: (info) => {
    //     const { value, change } = info.renderValue<CellType>();
    //     return <RowWithCurrency label={value} change={change} containerClassName={columnTextClass} />;
    //   },
    //   enableSorting: true,
    //   sortingFn,
    // },
  ];
};

function changeFn(val1: string | number, val2: string | number) {
  if (val1 === val2) return undefined;
  return val2 > val1 ? "decrease" : "increase";
}

function percentage({ total, count, fractionDigits }) {
  return ((count / total) * 100).toFixed(fractionDigits);
}

function randomColor() {
  const colors = ["red", "purple", "green", "blue", "orange", "yellow", "primary", "secondary"];
  return colors[Math.floor(Math.random() * colors.length)];
}

const extractData = (data) => {
  return (
    data.opsFunds?.nodes?.[0]?.mtpCompanyfundsByMtpFundId?.nodes?.map(
      ({
        mtpCompany: company,
        companyType,
        navFundSize,
        evReturnFund,
        revenue,
        yoyGrowth,
        margin,
        freeCashFlow,
        fte,
        runway,
        followOnCapital,
        capitalConcentration,
      }) => {
        const {
          image,
          city,
          round: lastRound,
          industries: sectors,
          rating: dealroomScore,
          badges,
          tags,
          id,
          latestMetrics,
        } = company || {};
        const valuationBasedTags: string[] = [];
        if (Number(revenue) > 100_000_000) valuationBasedTags.push("TB");
        if (Number(revenue) > 25_000_000 && Number(revenue) < 100_000_000) valuationBasedTags.push("C");
        const futureCorn = [tags?.includes("possible Futurecorn") ? "FC" : undefined].filter(Boolean);
        const unicorn = [tags?.includes("verified Unicorns and $1B exits") ? "UC" : undefined].filter(Boolean);
        const investmentType = [badges?.find(({ id }) => id === "investment_type")?.value].filter(Boolean);
        const isCore = investmentType.includes("Core");
        const isStrategic = investmentType.includes("Strategic");
        const investmentTypeTags = [isCore ? "CO" : isStrategic ? "S" : undefined].filter(Boolean);

        const parentFunds =
          badges
            ?.find(({ id }) => id === "portfolio_company")
            ?.extra?.["parent_funds"]?.map((fund) => parentFund[fund]) || [];
        parentFunds.sort((a, b) => {
          if (a === "LocalGlobe") return -1;
          return a > b ? 1 : -1;
        });
        const combinedTags = [...parentFunds, ...futureCorn, ...unicorn, ...investmentTypeTags, ...valuationBasedTags];

        const hq = city || badges?.find(({ id }) => id === "country")?.extra;
        const basecampFunds = badges?.find(({ id }) => id === "basecamp_investment")?.extra;

        return {
          hq: {
            value: hq,
          },
          lastRound: {
            value: lastRound,
          },
          sectors: {
            value: sectors,
          },
          dealroomScore: {
            value: dealroomScore,
          },
          basecampFunds: {
            value: basecampFunds,
          },
          company: {
            value: company?.name,
            extra: {
              tags: combinedTags,
              companyType,
              image,
              id,
            },
          },
          navToFundSizePercentage: {
            value: navFundSize,
            change: undefined,
          },
          evToFundReturn: {
            value: evReturnFund,
            change: undefined,
            extra: { isCore: investmentType.includes("Core") },
          },
          revenue: {
            value: revenue,
            change: undefined,
          },
          yoyGrowth: {
            value: yoyGrowth,
            change: undefined,
          },
          marginPercentage: {
            value: margin,
            change: undefined,
          },
          fcf: {
            value: freeCashFlow,
            change: undefined,
          },
          fte: {
            value: fte,
            change: undefined,
          },
          runway: {
            value: runway,
          },
          followOnRaised: {
            value: followOnCapital,
            change: undefined,
          },
          capitalConcentration: {
            value: capitalConcentration,
            change: undefined,
          },
        };
      },
    ) || []
  );
};
const extractData2 = (data) => {
  return (
    data.opsFunds?.nodes?.[0]?.opsCompanyfundsByFundId?.nodes?.map(
      ({ company: opsCompany, navFundSize, evReturnFund }) => {
        /*
             revenue,
       yoyGrowth,
       margin,
       freeCashFlow,
       fte,
       runway,
       followOnCapital,
       capitalConcentration,

       */
        const {
          name: companyName,
          image,
          city,
          round: lastRound,
          industries: sectors,
          rating: dealroomScore,
          badges,
          tags,
          id,
          last3Sentiment,
          employeesLatest,
          latestMetrics,
        } = opsCompany?.srcCompany?.company || {};

        const isRadar =
          last3Sentiment?.length >= 3 &&
          last3Sentiment.map((item) => item.excitement).reduce((acc, curr) => acc + curr, 0) / last3Sentiment.length >=
            8.5;

        const revenue = latestMetrics?.data?.ARR?.value;
        const yoyGrowth = latestMetrics?.data?.yoyGrowth?.value / 100;
        const cashflowPositive = latestMetrics?.data?.cashflowPositive?.value;
        const grossMargin = latestMetrics?.data?.grossMargin?.value / 100;
        const fte = latestMetrics?.data?.FTE?.value || employeesLatest;
        const runway = getCashoutGroup(
          latestMetrics?.data?.runwayInMonths?.value,
          latestMetrics?.data?.fullyFundedToBreakeven?.value,
        );
        const followOnCapital = "";
        const capitalConcentration =
          opsCompany.initialInvestmentRatio >= 1 ? Math.round(opsCompany.initialInvestmentRatio).toString() + ":1" : "";

        const valuationBasedTags: string[] = [];
        if (Number(revenue) > 100_000_000) valuationBasedTags.push("TB");
        if (Number(revenue) > 25_000_000 && Number(revenue) < 100_000_000) valuationBasedTags.push("C");
        const futureCorn = [tags?.includes("possible Futurecorn") ? "FC" : undefined].filter(Boolean);
        const unicorn = [tags?.includes("verified Unicorns and $1B exits") ? "UC" : undefined].filter(Boolean);
        const investmentType = [badges?.find(({ id }) => id === "investment_type")?.value].filter(Boolean);
        const isCore = investmentType.includes("Core");
        const isStrategic = investmentType.includes("Strategic");
        const investmentTypeTags = [isCore ? "CO" : isStrategic ? "S" : undefined].filter(Boolean);

        const parentFunds =
          badges
            ?.find(({ id }) => id === "portfolio_company")
            ?.extra?.["parent_funds"]?.map((fund) => parentFund[fund]) || [];
        parentFunds.sort((a, b) => {
          if (a === "LocalGlobe") return -1;
          return a > b ? 1 : -1;
        });
        const combinedTags = [...parentFunds, ...futureCorn, ...unicorn, ...investmentTypeTags, ...valuationBasedTags];

        const hq = city || badges?.find(({ id }) => id === "country")?.extra;
        const basecampFunds = badges?.find(({ id }) => id === "basecamp_investment")?.extra;

        const companyType = navFundSize >= 0.1 ? "Focus" : navFundSize >= 0.05 ? "Emerging" : isRadar ? "Radar" : "N/A";

        return {
          hq: {
            value: hq,
          },
          lastRound: {
            value: lastRound,
          },
          sectors: {
            value: sectors,
          },
          dealroomScore: {
            value: dealroomScore,
          },
          basecampFunds: {
            value: basecampFunds,
          },
          company: {
            value: companyName,
            extra: {
              tags: combinedTags,
              companyType,
              image,
              id,
            },
          },
          navToFundSizePercentage: {
            value: navFundSize,
            change: undefined,
          },
          evToFundReturn: {
            value: evReturnFund * 1e6,
            change: undefined,
            extra: { isCore: investmentType.includes("Core") },
          },
          revenue: {
            value: revenue,
            change: undefined,
          },
          yoyGrowth: {
            value: yoyGrowth,
            change: undefined,
          },
          marginPercentage: {
            value: grossMargin,
            change: undefined,
          },
          fcf: {
            value: cashflowPositive,
            change: undefined,
          },
          fte: {
            value: fte,
            change: undefined,
          },
          runway: {
            value: runway,
          },
          followOnRaised: {
            value: followOnCapital,
            change: undefined,
          },
          capitalConcentration: {
            value: capitalConcentration,
            change: undefined,
          },
        };
      },
    ) || []
  );
};

export const useMeetThePortfolio = () => {
  const activeFund = investmentStore.state.activeFund;
  const expanded = investmentStore.state.expanded[activeFund.value];
  const setExpanded = (key) => {
    investmentStore.setState({
      expanded: {
        ...investmentStore.state.expanded,
        [activeFund.value]: { ...expanded, [key]: !expanded[key] },
      },
    });
  };

  const { data, error } = useMeetThePortfolioSuspenseQuery({
    variables: {
      filter: { name: { equalTo: activeFund.value.toUpperCase() } },
    },
  });
  const navigate = useNavigate();
  const { fundId } = useParams();

  useEffect(() => {
    if (fundId) {
      const activeFund = investmentFunds.find(({ value }) => value === fundId);
      if (activeFund) investmentStore.setState({ activeFund });
    }
  }, [fundId]);

  const { overview } = data.opsFunds?.nodes?.[0]?.mtp || {};

  const overviewData: Partial<
    Record<
      OverviewKey,
      {
        border?: boolean;
        quarter: string;
        QoQ?: { value: string; change?: "increase" | "decrease" };
        YoY?: { value: string; change?: "increase" | "decrease" };
        Element: React.FC<any>;
      }
    >
  > =
    activeFund.value !== "BC"
      ? {
          "Fund Size": {
            quarter: overview["fund_size_curr"],
            QoQ: {
              value: overview["fund_size_lq"],
              change: changeFn(overview["fund_size_curr"], overview["fund_size_lq"]),
            },
            YoY: {
              value: overview["fund_size_ly"],
              change: changeFn(overview["fund_size_curr"], overview["fund_size_ly"]),
            },
            Element: RowWithCurrency,
          },
          Vintage: {
            quarter: overview["vintage_curr"],
            QoQ: undefined,
            YoY: undefined,
            Element: TableText,
          },
          "Capital Called": {
            quarter: overview["capital_called_curr"],
            QoQ: {
              value: overview["capital_called_lq"],
              change: changeFn(overview["capital_called_curr"], overview["capital_called_lq"]),
            },
            YoY: {
              value: overview["capital_called_ly"],
              change: changeFn(overview["capital_called_curr"], overview["capital_called_ly"]),
            },
            Element: RowWithPercentage,
          },
          "Capital Invested": {
            border: true,
            quarter: overview["capital_invested_curr"],
            QoQ: {
              value: overview["capital_invested_lq"],
              change: changeFn(overview["capital_invested_curr"], overview["capital_invested_lq"]),
            },
            YoY: {
              value: overview["capital_invested_ly"],
              change: changeFn(overview["capital_invested_curr"], overview["capital_invested_ly"]),
            },
            Element: RowWithPercentage,
          },
          "Total Unrealised Value": {
            quarter: overview["total_unrealised_curr"],
            QoQ: {
              value: overview["total_unrealised_lq"],
              change: changeFn(overview["total_unrealised_curr"], overview["total_unrealised_lq"]),
            },
            YoY: {
              value: overview["total_unrealised_ly"],
              change: changeFn(overview["total_unrealised_curr"], overview["total_unrealised_ly"]),
            },
            Element: RowWithCurrency,
          },
          "Total Realised Value": {
            border: true,
            quarter: overview["total_realised_curr"],
            QoQ: {
              value: overview["total_realised_lq"],
              change: changeFn(overview["total_realised_curr"], overview["total_realised_lq"]),
            },
            YoY: {
              value: overview["total_realised_ly"],
              change: changeFn(overview["total_realised_curr"], overview["total_realised_ly"]),
            },
            Element: RowWithCurrency,
          },
          "Total Cost": {
            border: true,
            quarter: overview["total_cost_curr"],
            QoQ: {
              value: overview["total_cost_lq"],
              change: changeFn(overview["total_cost_curr"], overview["total_cost_lq"]),
            },
            YoY: {
              value: overview["total_cost_ly"],
              change: changeFn(overview["total_cost_curr"], overview["total_cost_ly"]),
            },
            Element: RowWithCurrency,
          },
          "V/C": {
            border: true,
            quarter: overview["v_c_curr"],
            QoQ: {
              value: overview["v_c_lq"],
              change: changeFn(overview["v_c_curr"], overview["v_c_lq"]),
            },
            YoY: {
              value: overview["v_c_ly"],
              change: changeFn(overview["v_c_curr"], overview["v_c_ly"]),
            },
            Element: RowWithMultiplier,
          },
          "Net TVPI": {
            quarter: overview["net_tvpi_curr"],
            QoQ: {
              value: overview["net_tvpi_lq"],
              change: changeFn(overview["net_tvpi_curr"], overview["net_tvpi_lq"]),
            },
            YoY: {
              value: overview["net_tvpi_ly"],
              change: changeFn(overview["net_tvpi_curr"], overview["net_tvpi_ly"]),
            },
            Element: RowWithMultiplier,
          },
          "Net DPI": {
            quarter: overview["net_dpi_curr"],
            QoQ: {
              value: overview["net_dpi_lq"],
              change: changeFn(overview["net_dpi_curr"], overview["net_dpi_lq"]),
            },
            YoY: {
              value: overview["net_dpi_ly"],
              change: changeFn(overview["net_dpi_curr"], overview["net_dpi_ly"]),
            },
            Element: RowWithMultiplier,
          },
        }
      : {
          "Capital Invested": {
            quarter: overview["capital_invested_curr"],
            QoQ: {
              value: overview["capital_invested_lq"],
              change: changeFn(overview["capital_invested_curr"], overview["capital_invested_lq"]),
            },
            YoY: {
              value: overview["capital_invested_ly"],
              change: changeFn(overview["capital_invested_curr"], overview["capital_invested_ly"]),
            },
            Element: RowWithCurrency,
          },
          "Gross Unrealised NAV": {
            quarter: overview["gross_unrealized_curr"],
            QoQ: {
              value: overview["gross_unrealized_lq"],
              change: changeFn(overview["gross_unrealized_curr"], overview["gross_unrealized_lq"]),
            },
            YoY: {
              value: overview["gross_unrealized_ly"],
              change: changeFn(overview["gross_unrealized_curr"], overview["gross_unrealized_ly"]),
            },
            Element: RowWithCurrency,
          },
          "Gross Realised NAV": {
            quarter: overview["gross_realized_curr"],
            QoQ: {
              value: overview["gross_realized_lq"],
              change: changeFn(overview["gross_realized_curr"], overview["gross_realized_lq"]),
            },
            YoY: {
              value: overview["gross_realized_ly"],
              change: changeFn(overview["gross_realized_curr"], overview["gross_realized_ly"]),
            },
            Element: RowWithCurrency,
          },
          "Gross Multiple": {
            border: true,
            quarter: overview["gross_mult_curr"],
            QoQ: {
              value: overview["gross_mult_lq"],
              change: changeFn(overview["gross_mult_curr"], overview["gross_mult_lq"]),
            },
            YoY: {
              value: overview["gross_mult_ly"],
              change: changeFn(overview["gross_mult_curr"], overview["gross_mult_ly"]),
            },
            Element: RowWithMultiplier,
          },
          "Net TVPI": {
            quarter: overview["net_tvpi_curr"],
            QoQ: {
              value: overview["net_tvpi_lq"],
              change: changeFn(overview["net_tvpi_curr"], overview["net_tvpi_lq"]),
            },
            YoY: {
              value: overview["net_tvpi_ly"],
              change: changeFn(overview["net_tvpi_curr"], overview["net_tvpi_ly"]),
            },
            Element: RowWithMultiplier,
          },
          "Net DPI": {
            quarter: overview["net_dpi_curr"],
            QoQ: {
              value: overview["net_dpi_lq"],
              change: changeFn(overview["net_dpi_curr"], overview["net_dpi_lq"]),
            },
            YoY: {
              value: overview["net_dpi_ly"],
              change: changeFn(overview["net_dpi_curr"], overview["net_dpi_ly"]),
            },
            Element: RowWithMultiplier,
          },
        };

  const { statistics } = data.opsFunds?.nodes?.[0]?.mtp || {};

  const statisticsData: Record<StatisticsKey, { value: string; Element: React.FC<any> }> = {
    "Median Initial Cheque": {
      value: statistics["mean_initial_cheque"],
      Element: RowWithCurrency,
    },
    "Median Going in Ownership": {
      value: statistics["mean_going_in_ownership"],
      Element: RowWithPercentage,
    },
    "Median Going in Post Money": {
      value: statistics["mean_going_in_postmoney"],
      Element: RowWithCurrency,
    },
  };

  const { portfolio_makeup } = data.opsFunds?.nodes?.[0]?.mtp || {};
  const geoTotal = portfolio_makeup?.["geo"].reduce((acc, { count }) => acc + count, 0) ?? 0;
  const sectorsTotal = portfolio_makeup?.["sectors"].reduce((acc, { count }) => acc + count, 0) ?? 0;

  const portfolioMakeup: Partial<
    Record<
      PortfolioMakeupKey,
      {
        popover?: string;
        aggregate?: string;
        data: Record<
          SectorKey | GeographyKey | TotalInvestmentsKey,
          {
            value: string;
            Element: React.FC<any>;
            color?: string;
            popover?: string;
          }
        >;
      }
    >
  > =
    activeFund.value !== "BC"
      ? {
          "Total Fund Investments": {
            popover: undefined,
            aggregate: portfolio_makeup?.["investment_type"].reduce((acc, { count }) => acc + count, 0).toString(),
            data: portfolio_makeup?.["investment_type"]
              .toSorted((a, b) => b.count - a.count)
              .reduce(
                (acc, e) => ({
                  ...acc,
                  [capitalize(e.name)]: {
                    value: e.count,
                    Element: TableText,
                  },
                }),
                {},
              ),
          },
          Geography: {
            popover: "Distribution of core companies by geography",
            data: portfolio_makeup?.["geo"]
              .toSorted((a, b) => b.count - a.count)
              .reduce(
                (acc, e) => ({
                  ...acc,
                  [e.name]: {
                    value: percentage({ total: geoTotal, count: e.count, fractionDigits: 0 }),
                    Element: RowWithBar,
                    popover: `${e.count} companies`,
                    color: randomColor(),
                  },
                }),
                {},
              ),
          },
          Sectors: {
            popover:
              "Some companies are in multiple sectors, so the total count is higher than the total number of companies",
            data: portfolio_makeup?.["sectors"]
              .toSorted((a, b) => b.count - a.count)
              .reduce(
                (acc, e) => ({
                  ...acc,
                  [capitalize(e.name)]: {
                    value: percentage({ total: sectorsTotal, count: e.count, fractionDigits: 0 }),
                    color: randomColor(),
                    popover: `${e.count} companies`,
                    Element: RowWithBar,
                  },
                }),
                {},
              ),
          },
        }
      : {
          "Fund Investments": {
            popover: undefined,
            // aggregate: portfolio_makeup?.["investment_type"].reduce((acc, { count }) => acc + count, 0).toString(),
            data: portfolio_makeup?.["investment_type"].reduce(
              (acc, e) => ({
                ...acc,
                [capitalize(e.name)]: {
                  value: e.count,
                  Element: TableText,
                },
              }),
              {},
            ),
          },
          Geography: {
            popover: "Distribution of companies by geography",
            data: portfolio_makeup?.["geo"]
              .toSorted((a, b) => b.count - a.count)
              .reduce(
                (acc, e) => ({
                  ...acc,
                  [e.name]: {
                    value: percentage({ total: geoTotal, count: e.count, fractionDigits: 0 }),
                    Element: RowWithBar,
                    popover: `${e.count} companies`,
                    color: randomColor(),
                  },
                }),
                {},
              ),
          },
        };

  const tableResponse: Array<MeetThePortfolio> = extractData2(data);

  return {
    data: tableResponse,
    error,
    columns,
    navigate,
    expanded,
    activeFund,
    setExpanded,
    overviewData,
    statisticsData,
    portfolioMakeup,
  };
};
