import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { TableNew } from "@/app/components/table-new";
import { pcgOverviewColumns } from "@/app/screens/opportunities/opportunity/components/pcg-overview/pcg-overview-columns.tsx";

export const PcgTable = ({ data, totals }: { data: any; totals: any }) => {
  const columns = pcgOverviewColumns;

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    meta: { totals },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableSortingRemoval: true,
  });

  return (
    <div
      className="inline-flex mx-[-12px] w-[calc(100%+24px)] lg:mx-[-20px] lg:w-[calc(100%+40px)] size-full flex-col overflow-x-auto align-middle no-scrollbar"
    >
      <TableNew table={table} options={{ density: "dense" }} />
    </div>
  );
};
