import { cn, getFullName } from "@/lib/utils.ts";

import { DropdownV2 } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { NetworkSignalView } from "./network-signal-view.tsx";
import { NetworkSignalData } from "./network-signal.types.ts";

const EditButtons = ({
  onEdit,
  onRemove,
  className,
  signal,
}: {
  signal: NetworkSignalData;
  onEdit: () => void;
  onRemove: () => void;
  className?: string;
}) => {
  const options = [
    { label: "Edit", action: onEdit },
    { label: "Remove", action: onRemove },
  ];

  return (
    <DropdownV2 className={cn("absolute top-4 right-4", className)}>
      <DropdownV2.Button
        onClick={(e) => e.stopPropagation()}
        size="sm"
        className={"bg-gray-100"}
        buttonVariant="IconButton"
        icon="MoreVertical"
      />

      <DropdownV2.Items
        className={"max-h-40"}
        title={
          <>
            {getFullName(signal.createdBy.firstName, signal.createdBy.lastName)}
            <span className="ml-1 text-sm text-gray-600">(Source)</span>
          </>
        }
      >
        {options.map(({ label, action }) => (
          <DropdownV2.Item key={label} onClick={action}>
            <span className={cn("truncate text-black")}>{label}</span>
          </DropdownV2.Item>
        ))}
      </DropdownV2.Items>
    </DropdownV2>
  );
};

export const NetworkSignal = ({
  signal,
  onEdit,
  onRemove,
}: {
  signal: NetworkSignalData;
  onEdit: (signal: NetworkSignalData) => void;
  onRemove: (signal: NetworkSignalData) => void;
}) => {
  const currentUser = authStore.user;

  return (
    <div className="relative flex w-full justify-between gap-2 rounded-sm border border-gray-300 bg-gray-100 p-4">
      <div className="flex-1">
        <NetworkSignalView signal={signal} />
      </div>
      {!!currentUser?.id && currentUser.id === signal.createdBy.id && (
        <EditButtons signal={signal} onEdit={() => onEdit(signal)} onRemove={() => onRemove(signal)} />
      )}
    </div>
  );
};
