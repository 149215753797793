import { SyntheticEvent, useState } from "react";

import { LgMembersQuery } from "@/gql/graphql";

import { cn } from "@/lib/utils";

import { DropdownV2, MemberCard, ProgressSpinner } from "@/app/components";
import { InputV2 } from "@/app/components/forms/input-v2";
import { useLgMembersSuspenseQuery } from "@/app/service/lgMembers.gql.ts";

import { useAddAssigneeMutation } from "./use-add-assignees";

type AddAssigneeProps = {
  companyId: string;
  alreadyAssigned: string[];
};

type LgMembers = NonNullable<LgMembersQuery["lgMembers"]>["nodes"];

const AssignDropdownItem = ({ member, companyId }: { member: LgMembers[number]; companyId: string }) => {
  const { id, firstName, lastName, avatar } = member;

  const { onAddAssignees, loading } = useAddAssigneeMutation();

  const handleSubmit = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    onAddAssignees({ companyId, members: [id] });
  };

  return (
    <DropdownV2.Item className="rounded-xxs md:p-1" disabled={loading} onClick={(e) => handleSubmit(e)}>
      <MemberCard
        firstname={firstName}
        lastname={lastName}
        image={avatar}
        includeName={false}
        imgClassName={"size-7 rounded-xxs border-white mr-2"}
        className={"flex justify-start"}
      />
      <span className="mr-1 text-sm font-semibold">{`${firstName} ${lastName}`}</span>
      <ProgressSpinner className={cn("invisible ml-auto size-4", loading && "visible")} />
    </DropdownV2.Item>
  );
};

export const AddAssignees = ({ companyId, alreadyAssigned }: AddAssigneeProps) => {
  const [query, setQuery] = useState("");
  const { data } = useLgMembersSuspenseQuery({
    variables: { filter: { isInvestor: { equalTo: true } } },
    fetchPolicy: "cache-and-network",
  });

  const allMembers = data.lgMembers?.nodes
    ? data.lgMembers.nodes.reduce<LgMembers>(
        (accumulator, lgMember) => (alreadyAssigned.includes(lgMember.id) ? accumulator : [...accumulator, lgMember]),
        [],
      )
    : [];

  const filteredValues =
    query === ""
      ? allMembers
      : allMembers.filter(({ firstName, lastName }) => {
          return (
            firstName.toLowerCase().includes(query.toLowerCase()) ||
            lastName.toLowerCase().includes(query.toLowerCase())
          );
        });

  return (
    <DropdownV2 data-cy="add-assignee" className="h-6">
      <DropdownV2.Button
        buttonVariant={"IconButton"}
        icon="Add"
        size={"xs"}
        className="h-6 cursor-pointer bg-gray-300"
      />
      <DropdownV2.Items className="ml-2 mt-2" anchor={{ offset: 90 }} title="Small group">
        <InputV2
          iconLeft={"Search"}
          placeholder="Search"
          className={"text-sm font-normal"}
          containerClassName="p-1 px-4 md:px-1"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
        />
        <div className="max-h-[80dvh] overflow-y-auto md:max-h-48">
          {filteredValues.map((member) => (
            <AssignDropdownItem key={member.id} member={member} companyId={companyId} />
          ))}
          {filteredValues.length === 0 && (
            <div className="p-3 text-center">
              <span className="text-sm font-semibold text-gray-600">No results found</span>
            </div>
          )}
        </div>
      </DropdownV2.Items>
    </DropdownV2>
  );
};
